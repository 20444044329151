import { FiChevronLeft, FiDatabase } from 'react-icons/fi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useMatches } from 'react-router-dom';

import { HeaderLink } from '.';
import useUser from '../../hooks/useUser';
import { getBalance } from '../../slices/usage';
import twClassnames from '../../utils/classnames';
import AuthButton from '../AuthButton';
import HyperLink from '../common/HyperLink';
import MobileNavBar from '../MobileNavBar';
import UserDropdown from '../UserDropdown';
import HeaderBillingItem from './HeaderBillingItem';

// import { useState } from 'react';
// import Input from './common/Input';
// import { TbSearch } from 'react-icons/tb';

const Header = ({ className = '' }) => {
  // const [searchInput, setSearchInput] = useState('');
  const balance = useSelector(getBalance);
  const { user } = useUser();
  const matches = useMatches();
  const goBack = (matches[matches.length - 1]?.handle as any)?.goBack;

  return (
    <header
      className={twClassnames(
        'sticky top-0 z-10 flex items-center justify-between gap-2 bg-white px-8 py-5 md:h-[60px] md:min-h-[60px]',
        className,
      )}
    >
      {/* <Input
        value={searchInput}
        onChangeText={setSearchInput}
        startIcon={<TbSearch size={20} />}
        placeholder="Search your models"
        containerClassName="flex-1 max-w-md"
      /> */}
      <MobileNavBar className="-ml-2" />
      {goBack && (
        <HyperLink
          to={goBack}
          className="flex hidden items-center text-theme-neutral-600 md:flex"
        >
          <FiChevronLeft
            size={20}
            className="mr-1"
          />{' '}
          Go back
        </HyperLink>
      )}
      <div className="flex flex-1 items-center justify-end gap-2">
        <HeaderLink
          href="https://docs.hyperbolic.xyz/docs/getting-started"
          Icon={IoDocumentTextOutline}
          title="Docs"
          className="hidden md:flex"
        />
        <HeaderLink
          href="https://docs.hyperbolic.xyz/docs/hyperbolic-pricing"
          Icon={FiDatabase}
          title="Pricing"
          className="hidden md:flex"
        />
        {user ? (
          <>
            <HeaderBillingItem balance={balance} />
            <UserDropdown />
          </>
        ) : (
          <AuthButton className="text-sm" />
        )}
      </div>
    </header>
  );
};

export default Header;

import { useState } from 'react';
import merge from 'lodash/merge';
import ReactModal from 'react-modal';

import Button from './common/Button';
import HyperLink from './common/HyperLink';
import FrameIcon from './common/icons/FrameIcon';

import './MobileNavBar.css';

import { FiCpu } from 'react-icons/fi';
import { GrCubes } from 'react-icons/gr';
import { PiHouseFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';

import { getIsAuthenticated } from '../slices/auth';
import twClassnames from '../utils/classnames';
import Badge from './common/Badge';
import BillingIcon from './common/icons/BillingIcon';
import HyperbolicLogoFull from './common/icons/HyperbolicLogoFull';
import MessageQuestionIcon from './common/icons/MessageQuestionIcon';
import ServerIcon from './common/icons/ServerIcon';
import SettingsIcon from './common/icons/SettingsIcon';
import NavLinkItem from './NavLinkItem';

const drawerStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
    overflow: 'hidden',
  },
  content: {
    width: '66.6%',
    maxWidth: 560,
    marginLeft: 0,
    marginTop: 0,
    inset: 0,
    borderRadius: 0,
    border: 0,
    height: '100%',
    maxHeight: '100%',
  },
};

const MobileNavBar = ({ className = '' }: { className?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div className={twClassnames('md:hidden', className)}>
      <Button
        variant="outline"
        className="flex h-12 w-12 items-center justify-center rounded-full p-2"
        onClick={() => setIsOpen(true)}
      >
        <FrameIcon />
      </Button>
      <ReactModal
        id="mobile-nav-bar"
        closeTimeoutMS={300}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={merge({}, ReactModal.defaultStyles, drawerStyles)}
        className="flex flex-col"
      >
        <HyperLink
          to="/"
          className="mb-12 ml-4 flex items-center hover:no-underline"
        >
          <HyperbolicLogoFull
            width={150}
            height={25}
          />
        </HyperLink>
        <div className="mb-8 ml-4 flex">
          <Badge text="beta" />
        </div>
        <nav className="flex flex-col">
          <NavLinkItem
            to="/"
            logo={<PiHouseFill size={24} />}
            title="Home"
            onClick={handleClose}
          />
          <NavLinkItem
            to="/models"
            logo={<GrCubes size={24} />}
            title="Models"
            onClick={handleClose}
          />
          <NavLinkItem
            to="/compute"
            logo={<FiCpu size={24} />}
            title="Rent GPUs"
            onClick={handleClose}
          />
          <NavLinkItem
            to="/supply"
            logo={<ServerIcon size={24} />}
            title="Supply GPUs"
            onClick={handleClose}
          />
          {isAuthenticated && (
            <NavLinkItem
              to="/billing"
              logo={<BillingIcon size={24} />}
              title="Billing"
              onClick={handleClose}
            />
          )}
          {isAuthenticated && (
            <NavLinkItem
              to="/settings"
              logo={<SettingsIcon size={24} />}
              title="Settings"
              onClick={handleClose}
            />
          )}
          <NavLinkItem
            to="/support"
            logo={<MessageQuestionIcon size={24} />}
            title="Support"
            onClick={handleClose}
          />
        </nav>
      </ReactModal>
    </div>
  );
};

export default MobileNavBar;

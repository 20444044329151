import { useCallback, useEffect, useState } from 'react';

const useLocalStorage = (key: string) => {
  const [value, setValue] = useState<any>('');
  const handleStorage = useCallback(() => {
    const stringValue = localStorage.getItem(key);
    let newValue = stringValue;
    if (stringValue) {
      try {
        newValue = JSON.parse(stringValue);
      } catch {
        // continue
      }
    }
    setValue(newValue);
  }, [key]);

  const handleStorageChange = useCallback(
    (event: StorageEvent | Event) => {
      if ((event as StorageEvent).key === key) {
        handleStorage();
      }
    },
    [handleStorage, key],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('local-storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
  }, [key, handleStorageChange]);

  const setItem = useCallback(
    (newValue: any) => {
      localStorage.setItem(key, JSON.stringify(newValue));
      // Needed to send custom events. 'storage' event does not work within this tab.
      window.dispatchEvent(new StorageEvent('local-storage', { key }));
      setValue(newValue);
    },
    [key],
  );

  useEffect(() => {
    handleStorage();
  }, [handleStorage]);

  return [value, setItem] as [string, (value: any) => void];
};

export default useLocalStorage;

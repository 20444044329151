import { ReactNode, useContext } from 'react';
import { IconType } from 'react-icons';
import { FaChevronDown } from 'react-icons/fa6';

import { ButtonVariant } from '../../../utils/button';
import twClassnames from '../../../utils/classnames';
import Button from '../Button';
import DropdownContext from './DropdownContext';

const DropdownButton = ({
  children,
  className,
  PositionIcon = FaChevronDown,
  iconClassName,
  variant = 'dropdown',
  displayPosition = true,
  textClassName,
}: {
  children: ReactNode;
  className?: string;
  PositionIcon?: IconType;
  iconClassName?: string;
  variant?: ButtonVariant;
  displayPosition?: boolean;
  textClassName?: string;
}) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);
  return (
    <Button
      type="button"
      variant={variant}
      className={twClassnames('flex w-full items-center justify-end rounded-lg text-sm', className)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={twClassnames('flex flex-1 items-center truncate text-ellipsis text-left', textClassName)}>
        {children}
      </span>
      {displayPosition && (
        <PositionIcon
          className={twClassnames(
            'ml-1 transition',
            {
              'rotate-180': isOpen,
            },
            iconClassName,
          )}
          size={16}
        />
      )}
    </Button>
  );
};

export default DropdownButton;

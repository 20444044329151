import merge from 'lodash/merge';

import { API_ERRORS } from './constants';
import { auth } from './firebase';

const hyperFetch = async (path: string, options?: any) => {
  const accessToken = await (auth.currentUser?.getIdToken() || localStorage.getItem('authToken'));
  const response = await fetch(
    path,
    merge(
      {},
      {
        headers: {
          'Content-type': 'application/json',
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      },
      options,
    ),
  );
  const json = await response.json();
  if (!response.ok) {
    if (API_ERRORS[json.error_code]) {
      throw API_ERRORS[json.error_code];
    }
    if (json.detail) {
      throw Error(json.detail);
    }
    throw json;
  }
  return json;
};

export default hyperFetch;

import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore/lite';

const FIREBASE_DEV_CONFIG = {
  apiKey: 'AIzaSyD7KXFpXFKJlzWxl139gV3BUFL7_4WtJ3E',
  authDomain: 'auth.hyperbolic.xyz',
  projectId: 'ai-dashboard-dev-e5784',
  storageBucket: 'ai-dashboard-dev-e5784.appspot.com',
  messagingSenderId: '1073433056576',
  appId: '1:1073433056576:web:771b56fe23965a2597d6a5',
  measurementId: 'G-RWB05MW80P',
};

const FIREBASE_PROD_CONFIG = {
  apiKey: 'AIzaSyD2Ob8BZAv_mtjL-Ax3jylyYFItoHfcjdI',
  authDomain: 'auth.hyperbolic.xyz',
  projectId: 'ai-dashboard-cfd6a',
  storageBucket: 'ai-dashboard-cfd6a.appspot.com',
  messagingSenderId: '917171043350',
  appId: '1:917171043350:web:b91a525d083bc818a7c937',
  measurementId: 'G-XJ1KPGJ8DX',
};

const useFirebaseDev = import.meta.env.VITE_USE_FIREBASE_DEV === 'true';

const firebaseConfig = useFirebaseDev ? FIREBASE_DEV_CONFIG : FIREBASE_PROD_CONFIG;

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);

import { useNavigate } from 'react-router-dom';

import Layout from '../components/common/Layout';
import NewMachineForm from '../components/Compute/NewMachineForm';
import { createMachineRequestDb } from '../services/machines';

const NewMachineRequest = () => {
  const navigate = useNavigate();
  const handleSubmit = (data: any) => {
    createMachineRequestDb(data);
    navigate('/compute');
  };
  return (
    <Layout>
      <h1>Request GPU</h1>
      <NewMachineForm onSubmit={handleSubmit} />
    </Layout>
  );
};

export default NewMachineRequest;

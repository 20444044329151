import { ReactNode } from 'react';

import HyperLink from './HyperLink';

const LinkBlock = ({ href, text, icon }: { href: string; text: string; icon?: ReactNode }) => (
  <HyperLink
    href={href}
    className="flex w-64 flex-col items-center justify-center gap-3 rounded-lg border border-theme-primary-200 py-6 shadow-theme-02 transition hover:border-theme-primary-600 hover:no-underline"
    rel="noreferrer noopener"
    target="_blank"
  >
    {icon}
    <div className="text-base font-semibold text-black">{text}</div>
  </HyperLink>
);

export default LinkBlock;

import { ReactNode } from 'react';

import { formatCurrency } from '../../../../../utils/value';

const BillingItemHeader = ({ title, cost, subtitle }: { title: string; cost?: number; subtitle?: ReactNode }) => {
  return (
    <div className="mb-6 flex flex-col">
      <div className="flex items-center justify-between font-bold">
        <div className="text-lg font-semibold text-theme-neutral-700">{title}</div>
        {!!cost && (
          <div>
            {formatCurrency(cost, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        )}
      </div>
      {subtitle}
    </div>
  );
};

export default BillingItemHeader;

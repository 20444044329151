import LoadingSpinner from './common/LoadingSpinner';

const LoadingPage = () => (
  <div className="flex flex-1 items-center justify-center">
    <LoadingSpinner
      size={44}
      className="fill-theme-primary-600 text-theme-primary-300"
    />
  </div>
);

export default LoadingPage;

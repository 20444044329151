import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../components/common/Button';
import HyperLink from '../components/common/HyperLink';
import ArrowRight from '../components/common/icons/ArrowRight';
import Layout from '../components/common/Layout';
import { CheckEligibility, FinalSetup, SetupInstructions, StepsProgressBar } from '../components/Supply';
import { addNodePrice, getAddNodePriceStatus, getUnpricedSupplied } from '../slices/instances';
import { getSupply, setNodePriceError, setStep } from '../slices/supply';
import { AppDispatch } from '../store';
import { mapModelToPrice } from '../utils/instances';
import { ResponseStatus } from '../utils/types';
import { formatCurrency } from '../utils/value';

const stepComponents = [CheckEligibility, SetupInstructions, FinalSetup];

const SupplyOnboarding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const unpricedSupplied = useSelector(getUnpricedSupplied);
  const lastUnpricedSupplied = unpricedSupplied[0];
  const nodePriceStatus = useSelector(getAddNodePriceStatus);
  const nodePriceLoading = nodePriceStatus === ResponseStatus.Loading;
  const { step, gpuType, hyperInstallCompleted, nodePrice } = useSelector(getSupply);
  const hasPrevious = step > 0;
  const nextDisabled = (step === 0 && !gpuType) || (step === 1 && !hyperInstallCompleted);
  const CurrentComponent = stepComponents[step] || stepComponents[0];
  const maxModelPrice = mapModelToPrice[lastUnpricedSupplied?.gpu?.model] || mapModelToPrice.Default;

  const validate = (value: number) => {
    if (value <= 0) {
      dispatch(setNodePriceError('Node must be priced at more than $0'));
      return false;
    } else if (value > maxModelPrice / 100) {
      dispatch(setNodePriceError(`Nodes of this GPU type must be priced at ${formatCurrency(maxModelPrice / 100)}`));
      return false;
    }
    dispatch(setNodePriceError(''));
    return true;
  };
  const handleNextStep = async () => {
    if (step < 2) {
      dispatch(setStep(step + 1));
      return;
    }
    if (unpricedSupplied.length > 0) {
      const { clusterName, id: nodeName } = lastUnpricedSupplied;
      const valid = validate(nodePrice);
      if (valid) {
        await dispatch(
          addNodePrice({
            clusterName,
            nodeName,
            amount: Math.floor(nodePrice * 100),
          }),
        ).unwrap();
        navigate('/supply');
      }
    }
  };

  return (
    <Layout className="flex-1">
      <h2>Set Up a New Device</h2>
      <div className="text-sm">
        Before you start providing machines to Hyperbolic, please take a moment to review our&nbsp;
        <HyperLink href="https://hyperbolic.xyz/terms">Terms of Use</HyperLink>
        &nbsp; and&nbsp;
        <HyperLink href="https://hyperbolic.xyz/privacy">Privacy Policy</HyperLink>. By continuing to use our service,
        you accept these terms and policies.
      </div>
      <hr className="my-10 border-theme-neutral-200" />
      <div className="flex flex-1 gap-4">
        <div className="flex flex-col">
          <CurrentComponent />
        </div>
        <div className="hidden min-w-80 max-w-96 flex-1 items-center justify-center lg:flex">
          <StepsProgressBar progressIndex={step} />
        </div>
      </div>
      <div className="my-8 flex justify-between">
        {hasPrevious ? (
          <Button
            variant="outline"
            className="flex items-center gap-2 text-base font-semibold"
            onClick={() => dispatch(setStep(step - 1))}
          >
            <ArrowRight className="rotate-180" />
            Previous Step
          </Button>
        ) : (
          <div />
        )}
        <Button
          className="flex items-center gap-2 text-base font-semibold"
          variant="outline"
          onClick={handleNextStep}
          disabled={nextDisabled}
          isLoading={step === 2 && nodePriceLoading}
        >
          <ArrowRight />
          {step === 2 ? 'Start Earning 🎉' : 'Next Step'}
        </Button>
      </div>
    </Layout>
  );
};

export default SupplyOnboarding;

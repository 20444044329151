import { AiOutlineDiscord } from 'react-icons/ai';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { getFAQSections } from '../../slices/support';
import { discordLink, salesLink } from '../../utils/constants';
import HyperLink from '../common/HyperLink';
import ChartBreakoutIcon from '../common/icons/ChartBreakoutIcon';
import LinkBlock from '../common/LinkBlock';

const FAQs = () => {
  const faqSections = useSelector(getFAQSections);
  return (
    <>
      <div className="flex gap-5">
        <LinkBlock
          href={salesLink}
          text="Book a Demo"
          icon={<ChartBreakoutIcon className="text-theme-primary-600" />}
        />
        <LinkBlock
          href="https://docs.hyperbolic.xyz"
          text="Access Docs"
          icon={
            <IoDocumentTextOutline
              size={28}
              className="text-theme-primary-600"
            />
          }
        />
        <LinkBlock
          href={discordLink}
          text="Join Discord"
          icon={
            <AiOutlineDiscord
              size={28}
              className="text-theme-primary-600"
            />
          }
        />
      </div>
      <div className="h-[1px] w-full bg-theme-neutral-200" />
      <div className="text-lg font-semibold text-black">FAQs</div>
      <div className="flex flex-wrap gap-16 rounded-lg bg-theme-neutral-50 p-8 lg:gap-28">
        {faqSections.map((section) => (
          <div
            key={section.title}
            className="flex flex-col gap-8 text-sm"
          >
            <div className="text-base font-semibold text-black">{section.title}</div>
            <div className="flex flex-1 flex-col gap-2 text-black">
              {section.questions
                .filter((q) => !q.hideAlpha)
                .slice(0, 4)
                .map((q) => (
                  <HyperLink
                    key={`link-${section.slug}-${q.question}`}
                    to={`/support/${section.slug}`}
                    state={{ initialQuestion: q.index }}
                    className="text-theme-neutral-700 hover:text-theme-neutral-600"
                  >
                    {q.question}
                  </HyperLink>
                ))}
            </div>
            <HyperLink
              to={`/support/${section.slug}`}
              className="font-semibold"
            >
              View all questions
            </HyperLink>
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQs;

import { ReactElement } from 'react';
import noop from 'lodash/noop';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import twClassnames from '../utils/classnames';

const NavLinkItem = ({
  to,
  logo,
  title,
  condensed = false,
  onClick = noop,
  hasUpdates = false,
}: {
  to: string;
  logo?: ReactElement;
  title: string;
  condensed?: boolean;
  onClick?: () => void;
  hasUpdates?: boolean;
}) => {
  const id = `navbar-${to}`;
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        twClassnames(
          `my-0.5 flex flex-nowrap items-center rounded-lg px-3.5 py-3 text-theme-neutral-700 no-underline transition-all hover:bg-theme-primary-100 hover:text-theme-primary-600`,
          {
            'bg-theme-primary-600 text-white hover:bg-theme-primary-600 hover:text-white': isActive || isPending,
          },
          {
            'px-3': condensed,
          },
        )
      }
      data-tooltip-id={`${id}-tooltip`}
      onClick={onClick}
    >
      {logo && (
        <div className={twClassnames('relative', { 'mr-4': !condensed })}>
          <div>{logo}</div>
          {hasUpdates && (
            <div className="absolute right-0 top-0 -mr-1 -mt-1 h-2 w-2 rounded-full bg-theme-primary-600 ring-2 ring-theme-primary-50" />
          )}
        </div>
      )}
      {!condensed && <div className="text-nowrap text-sm font-medium">{title}</div>}
      {condensed && (
        <Tooltip
          id={`${id}-tooltip`}
          className="z-10"
          place="right"
        >
          {title}
        </Tooltip>
      )}
    </NavLink>
  );
};

export default NavLinkItem;

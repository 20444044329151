import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import inferenceImage from '../../assets/inference-product.png';
import marketplaceImage from '../../assets/marketplace-product.png';
import useUser from '../../hooks/useUser';
import { updateUserInfo } from '../../slices/auth';
import { closeModal } from '../../slices/modals';
import { AppDispatch } from '../../store';
import APIKey from '../common/APIKey';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';

const productOptions = [
  {
    id: 'inference',
    title: 'AI Inference',
    description: 'Use Hyperbolic API to integrate any model into your application.',
    image: inferenceImage,
  },
  {
    id: 'marketplace',
    title: 'GPU Cloud',
    description: 'Access enormous GPU resources easily with SSH for your projects.',
    image: marketplaceImage,
  },
];

const OnboardingModal = ({ onClose }: { onClose: () => void }) => {
  const { user, userInfo } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  return (
    <div
      id="onboarding-modal"
      className="flex flex-col items-center gap-3 py-2 md:p-4"
    >
      <ModalClose onClose={onClose} />
      <h2>Welcome{user?.displayName ? ` ${user?.displayName}` : ''}! 🎊</h2>
      <div className="text-centers text-sm text-black">
        Here's your API key. Congrats on getting&nbsp;
        <span className="font-semibold text-theme-primary-600">$1.00</span>
        &nbsp;in inference credits!
      </div>
      <div className="my-3 flex w-full flex-col items-center gap-3">
        <APIKey
          apiKey={userInfo?.api_key}
          containerClassName="rounded-lg"
        />
        <div className="text-center text-sm">You can also access this later in Settings.</div>
      </div>
      <div className="mb-3 flex w-full gap-3 md:gap-5">
        {productOptions.map((opt) => (
          <div
            key={opt.id}
            role="button"
            className="flex flex-1 flex-col items-center gap-3 rounded-lg border border-theme-primary-100 p-4 shadow-theme-02 transition hover:border-theme-primary-600"
            onClick={async () => {
              if (user?.uid) {
                await dispatch(
                  updateUserInfo({
                    userId: user?.uid,
                    update: { onboarded_for: opt.id },
                  }),
                ).unwrap();
              }
              dispatch(closeModal());
              if (opt.id === 'inference') {
                navigate('/models');
              } else {
                navigate('/compute');
              }
            }}
          >
            <div className="text-lg font-semibold text-black">{opt.title}</div>
            <div className="text-center text-sm font-light text-theme-neutral-700">{opt.description}</div>
            <img
              src={opt.image}
              style={{ width: 167 }}
            />
          </div>
        ))}
      </div>
      <Button onClick={onClose}>Get Started</Button>
    </div>
  );
};

export default OnboardingModal;

import { useState } from 'react';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import { FiCpu } from 'react-icons/fi';
import { GrCubes } from 'react-icons/gr';
import { PiHouseFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';

import useLocalStorage from '../hooks/useLocalStorage';
import { getIsAuthenticated } from '../slices/auth';
import twClassnames from '../utils/classnames';
import Badge from './common/Badge';
import HyperLink from './common/HyperLink';
import BillingIcon from './common/icons/BillingIcon';
import ChevronLeftDoubleIcon from './common/icons/ChevronLeftDoubleIcon';
import HyperbolicLogoFull from './common/icons/HyperbolicLogoFull';
import MessageQuestionIcon from './common/icons/MessageQuestionIcon';
import ServerIcon from './common/icons/ServerIcon';
import SettingsIcon from './common/icons/SettingsIcon';
import TeamIcon from './common/icons/TeamIcon';
import NavLinkItem from './NavLinkItem';

const SideNavBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const teamEnabled = useFeatureFlagEnabled('team');
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [rentLastSeen] = useLocalStorage('rent-last-seen');
  const rentLastUpdated = useFeatureFlagPayload('rent-last-updated');
  const rentLastUpdatedDate = rentLastUpdated ? new Date(rentLastUpdated as string) : false;
  const rentHasUpdated = rentLastUpdatedDate && new Date(rentLastSeen) <= rentLastUpdatedDate;
  return (
    <aside
      className={twClassnames(
        'sticky left-0 z-10 hidden w-[210px] flex-col bg-theme-primary-50 px-3 transition-all md:flex',
        { 'w-[70px]': isCollapsed },
      )}
    >
      <div
        className={twClassnames('relative mb-6 mt-7 flex h-[26px] items-center transition-all', {
          'justify-center': isCollapsed,
        })}
      >
        {!isCollapsed && (
          <HyperLink
            to="/"
            className="ml-2 flex items-center hover:no-underline"
          >
            <HyperbolicLogoFull width={130} />
          </HyperLink>
        )}
        <ChevronLeftDoubleIcon
          className={twClassnames(
            'absolute right-0 cursor-pointer text-theme-neutral-400 transition hover:text-theme-primary-600',
            { 'static rotate-180': isCollapsed },
          )}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
      <div
        className={twClassnames('mb-4 flex transition-all', {
          'ml-2': !isCollapsed,
          '-mx-1.5': isCollapsed,
        })}
      >
        <Badge
          text="beta"
          className="text-xs"
        />
      </div>

      <nav className="flex flex-col gap-3">
        <div>
          <NavLinkItem
            to="/"
            logo={<PiHouseFill size={20} />}
            title="Home"
            condensed={isCollapsed}
          />
        </div>
        <div>
          {isCollapsed ? (
            <div className="w-full px-1.5 pb-[23px] pt-[6px]">
              <div className="h-[1px] w-full rounded-full bg-theme-neutral-300" />
            </div>
          ) : (
            <div className="px-3.5 pb-3 text-xs font-semibold uppercase text-theme-neutral-600">Product</div>
          )}
          <NavLinkItem
            to="/models"
            logo={<GrCubes size={20} />}
            title="Models"
            condensed={isCollapsed}
          />
          <NavLinkItem
            to="/compute"
            logo={<FiCpu size={20} />}
            title="Rent GPU"
            condensed={isCollapsed}
            hasUpdates={rentHasUpdated}
          />
          <NavLinkItem
            to="/supply"
            logo={<ServerIcon size={20} />}
            title="Supply GPU"
            condensed={isCollapsed}
          />
        </div>
        <div>
          {isAuthenticated &&
            (isCollapsed ? (
              <div className="w-full px-1.5 pb-[23px] pt-[6px]">
                <div className="h-[1px] w-full rounded-full bg-theme-neutral-300" />
              </div>
            ) : (
              <div className="px-3.5 pb-3 text-xs font-semibold uppercase text-theme-neutral-600">Account</div>
            ))}
          {isAuthenticated && (
            <NavLinkItem
              to="/billing"
              logo={<BillingIcon size={20} />}
              title="Billing"
              condensed={isCollapsed}
            />
          )}
          {isAuthenticated && (
            <NavLinkItem
              to="/settings"
              logo={<SettingsIcon size={20} />}
              title="Settings"
              condensed={isCollapsed}
            />
          )}
          {teamEnabled && isAuthenticated && (
            <NavLinkItem
              to="/team"
              logo={<TeamIcon size={20} />}
              title="Team"
              condensed={isCollapsed}
            />
          )}
        </div>
        <div>
          {isCollapsed ? (
            <div className="w-full px-1.5 pb-[23px] pt-[6px]">
              <div className="h-[1px] w-full rounded-full bg-theme-neutral-300" />
            </div>
          ) : (
            <div className="px-3.5 pb-3 text-xs font-semibold uppercase text-theme-neutral-600">Help</div>
          )}
          <NavLinkItem
            to="/support"
            logo={<MessageQuestionIcon size={20} />}
            title="Support"
            condensed={isCollapsed}
          />
        </div>
      </nav>
    </aside>
  );
};

export default SideNavBar;

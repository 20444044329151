const FrameIcon = ({ size = 16, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <rect
      width="7"
      height="7"
      rx="2"
      fill={color}
    />
    <rect
      x="9"
      width="7"
      height="7"
      rx="2"
      fill={color}
    />
    <rect
      y="9"
      width="7"
      height="7"
      rx="2"
      fill={color}
    />
    <rect
      x="9"
      y="9"
      width="7"
      height="7"
      rx="2"
      fill={color}
    />
  </svg>
);

export default FrameIcon;

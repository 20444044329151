const UploadCloudOutlineIcon = ({ size = 16, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="upload-cloud">
      <path
        id="Vector"
        d="M10.6663 10.6667L7.99967 8L5.33301 10.6667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8 8V14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M13.5933 12.2598C14.2435 11.9053 14.7572 11.3444 15.0532 10.6656C15.3493 9.98674 15.4108 9.22865 15.2281 8.51095C15.0454 7.79326 14.629 7.15683 14.0444 6.70212C13.4599 6.24741 12.7406 6.00032 12 5.99983H11.16C10.9582 5.21932 10.5821 4.49472 10.0599 3.88049C9.5378 3.26626 8.8832 2.77839 8.14537 2.45356C7.40754 2.12874 6.60567 1.9754 5.80005 2.00508C4.99443 2.03476 4.20602 2.24669 3.49409 2.62494C2.78216 3.00318 2.16525 3.5379 1.68972 4.18888C1.2142 4.83987 0.892434 5.59018 0.748627 6.38342C0.60482 7.17666 0.64271 7.99218 0.859449 8.76867C1.07619 9.54515 1.46613 10.2624 1.99997 10.8665"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M10.6663 10.6667L7.99967 8L5.33301 10.6667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default UploadCloudOutlineIcon;

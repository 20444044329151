import { ReactNode } from 'react';
import { useController } from 'react-hook-form';

import twClassnames from '../utils/classnames';
import Input, { InputProps } from './common/Input';

interface FormInputProps extends InputProps {
  label?: string;
  labelRight?: ReactNode;
  name: string;
  defaultValue?: string | number;
  control: any;
  containerClassName?: string;
  labelClassName?: string;
  hideAsterick?: boolean;
  rules?: any;
}

const FormInput = ({
  label,
  labelClassName,
  labelRight,
  control,
  defaultValue,
  name,
  rules,
  containerClassName,
  hideAsterick = false,
  ...otherProps
}: FormInputProps) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  return (
    <div className={twClassnames('mb-4 flex flex-col text-sm', containerClassName)}>
      {label && (
        <label className={twClassnames('mb-2 flex font-semibold text-black', labelClassName)}>
          {label}
          {rules?.required && !hideAsterick && '*'}
          {labelRight && <div className="flex flex-1 justify-end">{labelRight}</div>}
        </label>
      )}
      <Input
        {...otherProps}
        {...field}
        error={!!fieldState.error}
      />
      {fieldState.error?.type === 'required' && <div className="mt-2 text-theme-danger-600">{label} is required.</div>}
      {fieldState.error?.type === 'minLength' && (
        <div className="mt-2 text-theme-danger-600">{fieldState.error?.message}</div>
      )}
    </div>
  );
};

export default FormInput;

import Layout from '../components/common/Layout';

const ErrorPage = () => (
  <Layout>
    <h1>Sorry!</h1>
    <div>This page doesn't exist!</div>
  </Layout>
);

export default ErrorPage;

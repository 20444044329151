import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { v4 as uuidV4 } from 'uuid';

import { fetchModelPriceDb, fetchModelsDb, generateAudioDb, generateImageDb } from '../services/models';
import { RootState } from '../store';
import { AudioInputParameters, ImageInputParameters, Model, ResponseStatus, TextInputParameters } from '../utils/types';

interface Image {
  watermarkedImage?: string;
  image: string;
}

export interface GeneratedImage {
  id: string;
  images: Image[];
  params: ImageInputParameters<number, boolean>;
  created: number;
  inference_time: number;
}

export interface GeneratedAudio {
  id: string;
  script: string;
  audio: string;
  inference_time: number;
  sample_rate: number;
  params: AudioInputParameters<number>;
  created: Date;
}

interface ModelsSlice {
  models: Model[];
  status: ResponseStatus;
  initialFetch: boolean;
  textInputParameters: {
    values: TextInputParameters<number>;
    errors: TextInputParameters<string>;
  };
  imageInputParameters: {
    values: ImageInputParameters<number, boolean>;
    errors: ImageInputParameters<string, string>;
  };
  audioInputParameters: {
    values: AudioInputParameters<number>;
    errors: AudioInputParameters<string>;
  };
  generatedImages: GeneratedImage[];
  generatedImagesStatus: ResponseStatus;
  generatedImagesError: string;
  audioHistory: GeneratedAudio[];
  audioStatus: ResponseStatus;
  audioError: string;
  error: string;
}

const initialState: ModelsSlice = {
  models: [],
  status: ResponseStatus.Unfetched,
  initialFetch: false,
  textInputParameters: {
    values: {
      systemPrompt: '',
      maxTokens: 2048,
      topP: 0.9,
      temperature: 0.7,
    },
    errors: {
      systemPrompt: '',
      maxTokens: '',
      topP: '',
      temperature: '',
    },
  },
  imageInputParameters: {
    values: {
      prompt: '',
      steps: 30,
      cfgScale: 5,
      enableRefiner: false,
      height: 1024,
      width: 1024,
    },
    errors: {
      prompt: '',
      negativePrompt: '',
      steps: '',
      cfgScale: '',
      enableRefiner: '',
      height: '',
      width: '',
    },
  },
  audioInputParameters: {
    values: {
      speed: 1,
    },
    errors: {
      speed: '',
    },
  },
  generatedImages: [],
  generatedImagesStatus: ResponseStatus.Unfetched,
  generatedImagesError: '',
  audioHistory: [],
  audioStatus: ResponseStatus.Unfetched,
  audioError: '',
  error: '',
};

const validateImageParams = (values: ImageInputParameters<number, boolean>) => {
  const errors: any = {};

  if (values.prompt.length > 1000) {
    errors.prompt = 'Prompt must not exceed 1000 characters.';
  }

  if (values.negativePrompt?.length && values.negativePrompt?.length > 1000) {
    errors.negativePrompt = 'Negative prompt must not exceed 1000 characters.';
  }

  if (values.steps < 1) {
    errors.steps = 'Steps must be greater than 0.';
  } else if (values.steps > 50) {
    errors.steps = 'Steps must be less than 50.';
  }

  if (values.cfgScale < 1) {
    errors.cfgScale = 'Strength must be greater than 0.';
  } else if (values.cfgScale > 50) {
    errors.cfgScale = 'Strength must be less than 50.';
  }

  if (values.strength) {
    if (values.strength < 0) {
      errors.strength = 'Strength must be greater or equal to 0.';
    } else if (values.strength > 1) {
      errors.strength = 'Strength must not be greater than 1.';
    }
  }
  return errors;
};

const validateAudioParams = (values: AudioInputParameters<number>) => {
  const errors: AudioInputParameters<string> = {
    speed: '',
  };
  if (values.speed < 0) {
    errors.speed = 'Speed must be above 0';
  } else if (values.speed > 2) {
    errors.speed = 'Speed must be below 2';
  }
  return errors;
};

const fetchModelsConstant = 'fetchModels';
const fetchModelPriceConstant = 'fetchModelPrice';
const generateImageConstant = 'generateImageConstant';
const generateAudioConstant = 'generateAudioConstant';

const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    [`${fetchModelsConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchModelsConstant}/fulfilled`]: (state, action) => {
      state.models = action.payload;
      state.status = ResponseStatus.Success;
      state.initialFetch = true;
    },
    [`${fetchModelsConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${generateImageConstant}/pending`]: (state) => {
      state.generatedImagesStatus = ResponseStatus.Loading;
    },
    [`${generateImageConstant}/fulfilled`]: (state, action) => {
      const id = uuidV4();
      state.generatedImages.push({ ...action.payload, id });
      state.generatedImagesStatus = ResponseStatus.Success;
      state.generatedImagesError = '';
    },
    [`${generateImageConstant}/rejected`]: (state, action: any) => {
      state.generatedImagesStatus = ResponseStatus.Failure;
      state.generatedImagesError = action.error.message;
    },
    [`${generateAudioConstant}/pending`]: (state) => {
      state.audioStatus = ResponseStatus.Loading;
    },
    [`${generateAudioConstant}/fulfilled`]: (state, action) => {
      const id = uuidV4();
      state.audioHistory.push({ ...action.payload, id });
      state.audioStatus = ResponseStatus.Success;
      state.audioError = '';
    },
    [`${generateAudioConstant}/rejected`]: (state, action: any) => {
      state.audioStatus = ResponseStatus.Failure;
      state.audioError = action.error.message;
    },
    [`${fetchModelPriceConstant}/pending`]: () => {},
    [`${fetchModelPriceConstant}/fulfilled`]: (state, action) => {
      const modelIndex = state.models.findIndex((model) => model.model === action.payload.modelName);
      if (modelIndex > -1) {
        const currentModel = state.models[modelIndex];
        state.models[modelIndex] = {
          ...currentModel,
          price: action.payload,
        };
      }
    },
    [`${fetchModelPriceConstant}/rejected`]: () => {},
    changeTextParameter: (state, action) => {
      const newValues = {
        ...state.textInputParameters.values,
        [action.payload.id]: action.payload.value,
      };
      // const newErrors = validate(newValues);
      state.textInputParameters.values = newValues;
    },
    updateTextErrors: (state, action) => {
      state.textInputParameters.errors = action.payload;
    },
    changeImageParameter: (state, action) => {
      const newValues = {
        ...state.imageInputParameters.values,
        [action.payload.id]: action.payload.value,
      };
      const newErrors = validateImageParams(newValues);
      return {
        ...state,
        imageInputParameters: {
          values: newValues,
          errors: newErrors,
        },
      };
    },
    changeAudioParameter: (state, action) => {
      const newValues = {
        ...state.audioInputParameters.values,
        [action.payload.id]: action.payload.value,
      };
      const newErrors = validateAudioParams(newValues);
      state.audioInputParameters = {
        values: newValues,
        errors: newErrors,
      };
    },
    resetParameters: (state) => {
      return {
        ...state,
        textInputParameters: initialState.textInputParameters,
        imageInputParameters: initialState.imageInputParameters,
        audioInputParameters: initialState.audioInputParameters,
      };
    },
    resetGeneratedImages: (state) => {
      state.generatedImages = initialState.generatedImages;
      state.generatedImagesStatus = initialState.generatedImagesStatus;
      state.generatedImagesError = initialState.generatedImagesError;
    },
    resetGeneratedAudio: (state) => {
      state.audioHistory = initialState.audioHistory;
      state.audioStatus = initialState.audioStatus;
      state.audioError = initialState.audioError;
    },
  },
});

export const {
  changeTextParameter,
  changeImageParameter,
  changeAudioParameter,
  updateTextErrors,
  resetParameters,
  resetGeneratedImages,
  resetGeneratedAudio,
} = modelsSlice.actions;

export const fetchModels = createAsyncThunk(`models/${fetchModelsConstant}`, fetchModelsDb);

export const fetchModelPrice = createAsyncThunk(`models/${fetchModelPriceConstant}`, fetchModelPriceDb);

export const generateImage = createAsyncThunk(`models/${generateImageConstant}`, generateImageDb);

export const generateAudio = createAsyncThunk(`models/${generateAudioConstant}`, generateAudioDb);

export const getModels = (state: RootState) => state.models.models;

export const getSortedModels = createSelector([getModels], (models: Model[]) =>
  sortBy(
    models.filter((model) => !model.name.includes('ControlNet')),
    'position',
  ),
);
export const getModelStatus = (state: RootState) => state.models.status;
export const getModel = (state: RootState, modelId?: string) =>
  state.models.models.find((model) => model.slug === modelId);
export const getModelsInitialLoading = (state: RootState) =>
  state.models.status === ResponseStatus.Loading && !state.models.initialFetch;
export const getTextInputParameters = (state: RootState) => state.models.textInputParameters;
export const getImageInputParameters = (state: RootState) => state.models.imageInputParameters;
export const getAudioInputParameters = (state: RootState) => state.models.audioInputParameters;
export const getGeneratedImages = (state: RootState) => state.models.generatedImages;
export const getSortedGeneratedImages = createSelector([getGeneratedImages], (images) => {
  return orderBy(images, 'created', 'desc');
});
export const getGeneratedImage = (state: RootState, imagesId: string) =>
  state.models.generatedImages.find((img) => img.id === imagesId);

export const getGeneratedImagesError = (state: RootState) => state.models.generatedImagesError;

export const getGeneratedImagesStatus = (state: RootState) => state.models.generatedImagesStatus;

export const getAudioHistory = (state: RootState) => state.models.audioHistory;
export const getSortedAudioHistory = createSelector([getAudioHistory], (audio) => orderBy(audio, 'created', 'desc'));
export const getAudioStatus = (state: RootState) => state.models.audioStatus;

export default modelsSlice.reducer;

import { IconType } from 'react-icons';

import twClassnames from '../../utils/classnames';
import HyperLink from '../common/HyperLink';

const HeaderLink = ({
  href,
  Icon,
  title,
  className = '',
}: {
  href: string;
  Icon: IconType;
  title: string;
  className?: string;
}) => (
  <HyperLink
    href={href}
    variant="ghost"
    className={twClassnames('flex items-center text-sm', className)}
    rel="noreferrer noopener"
    target="_blank"
  >
    <Icon
      className="mr-2"
      size={18}
    />
    {title}
  </HyperLink>
);

export default HeaderLink;

import { HTMLAttributes, ReactNode } from 'react';

import twClassnames from '../../utils/classnames';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  noHover?: boolean;
  variant?: 'primary' | 'secondary';
}

const Card = ({ children, variant = 'primary', className, noHover = false, ...otherProps }: CardProps) => (
  <div
    className={twClassnames(
      'inline-block rounded-xl border border-theme-primary-100 bg-theme-primary-50 px-5 py-4',
      {
        'cursor-pointer transition hover:border-theme-primary-600 hover:text-theme-primary-600': !noHover,
        'rounded-lg border border-theme-primary-200 bg-white shadow-theme-02': variant === 'secondary',
      },
      className,
    )}
    {...otherProps}
  >
    {children}
  </div>
);

export default Card;

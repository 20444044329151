const ArrowNarrowUpRight = ({ color = 'currentColor', size = 25, className = '' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.5 18.7061L18.5 6.70605M18.5 6.70605H10.5M18.5 6.70605V14.7061"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowNarrowUpRight;

import { format } from 'date-fns';
import { useController } from 'react-hook-form';
import { IoCalendarClearOutline } from 'react-icons/io5';

import twClassnames from '../../utils/classnames';
import Dropdown, { DropdownButton, DropdownOverlay } from '../common/Dropdown';
import DatePickerContent from './DatePickerOverlay';

interface FormCalendarProps {
  label?: string;
  control: any;
  defaultValue?: Date;
  name: string;
  rules?: any;
}

const findErrorMessage = (errorType?: string) => {
  if (errorType === 'required') {
    return 'This field is required.';
  } else if (errorType === 'greaterThanStartDate') {
    return 'This date must be greater than the start date.';
  } else if (errorType === 'greaterThanToday') {
    return 'This date must be today at the earliest.';
  }
  return '';
};

const FormCalendar = ({ label, control, defaultValue, name, rules }: FormCalendarProps) => {
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules,
    control,
  });
  const formattedValue = field.value && format(String(field.value), 'MM/dd/yyyy');
  return (
    <div className="mb-4 flex flex-col">
      {label && (
        <label className="mb-2 font-semibold">
          {label}
          {rules?.required && '*'}
        </label>
      )}
      <Dropdown>
        <DropdownButton
          displayPosition={false}
          className={twClassnames({
            'border-theme-danger-600 outline-theme-danger-600': !!fieldState.error,
          })}
        >
          <IoCalendarClearOutline className="mr-2" />
          {formattedValue || 'Pick a date'}
        </DropdownButton>
        <DropdownOverlay className="w-80">
          <DatePickerContent
            date={field.value}
            onChangeDate={(date) => field.onChange(date)}
          />
        </DropdownOverlay>
      </Dropdown>
      {fieldState.error && <div className="mt-2 text-theme-danger-600">{findErrorMessage(fieldState.error?.type)}</div>}
    </div>
  );
};

export default FormCalendar;

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import Layout from '../components/common/Layout';
import { Tab, TabList, TabPanel, Tabs } from '../components/common/Tabs';
import SupplyWaitlist from '../components/Compute/SupplyWaitlist';
import useUser, { UserStatus } from '../hooks/useUser';

const SupplyInstances = () => {
  const supplyEnabled = useFeatureFlagEnabled('supply');
  const { status } = useUser();
  const isLoggedIn = status === UserStatus.LoggedIn;
  const navigate = useNavigate();
  const match = useMatch('/supply/:item/*');
  const supplyTabs = ['list', 'instructions'];
  const selectedIndex = Math.max(match ? supplyTabs.findIndex((item) => item === match?.params?.item) : 0, 0);

  return (
    <Layout className="flex-1">
      {supplyEnabled && isLoggedIn ? (
        <>
          <h2>Supply GPUs</h2>
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={(index) => navigate(`${supplyTabs[index]}`)}
          >
            <TabList>
              <Tab>Nodes</Tab>
              <Tab>Instructions</Tab>
            </TabList>
            <TabPanel>
              <Outlet />
            </TabPanel>

            <TabPanel>
              <Outlet />
            </TabPanel>
          </Tabs>
        </>
      ) : (
        <SupplyWaitlist />
      )}
    </Layout>
  );
};

export default SupplyInstances;

import { ReactNode, useContext } from 'react';

import twClassnames from '../../../utils/classnames';
import DropdownContext from './DropdownContext';

const DropdownOverlay = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
  const { isOpen } = useContext(DropdownContext);
  if (!isOpen) return;
  return (
    <div
      className={twClassnames(
        'absolute right-0 z-20 mt-2 w-full origin-top-right scale-100 transform rounded-lg bg-white opacity-100 shadow-theme-01 focus:outline-none',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default DropdownOverlay;

import { useEffect, useRef, useState } from 'react';

import twClassnames from '../../../utils/classnames';
import { formatCurrency } from '../../../utils/value';
import { ButtonGroup, ButtonGroupButton } from '../ButtonGroup';
import Input from '../Input';

const AddFundsInputGroup = ({
  amount,
  disabled,
  onInputChanged,
  options = [25, 50, 100],
  error = false,
}: {
  amount: number;
  disabled: boolean;
  onInputChanged: ({ value }: { value: number | undefined }) => void;
  options: number[];
  error?: boolean;
}) => {
  const isSelectedOption = options.includes(amount);
  const defaultCustomSelected = amount > 0 && !isSelectedOption;
  const [customSelected, setCustomSelected] = useState(defaultCustomSelected);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && defaultCustomSelected) {
      inputRef.current.focus();
    }
  }, [defaultCustomSelected]);

  const handleOptionSelected = async (value: number) => {
    setCustomSelected(false);
    onInputChanged({ value });
  };

  const handleCustomSelected = () => {
    if (!customSelected) {
      setCustomSelected(true);
      onInputChanged({ value: undefined });
    }
  };

  return (
    <ButtonGroup className="divide-theme-neutral-400">
      {options.map((o, i) => {
        const optionSelected = !customSelected && amount === o;
        return (
          <ButtonGroupButton
            key={`add_funds_input_${o}`}
            active={optionSelected}
            onClick={() => handleOptionSelected(o)}
            className={twClassnames(
              // override defaults
              'border-r text-black first:border-theme-neutral-400 hover:bg-theme-neutral-300 first:hover:border-theme-neutral-400',
              // custom
              'max-w-24 font-medium',
              {
                '!border-theme-primary-600 bg-theme-primary-600 text-white hover:bg-theme-primary-600': optionSelected,
              },
            )}
          >
            {formatCurrency(o, {
              maximumFractionDigits: 0,
            })}
          </ButtonGroupButton>
        );
      })}
      <div
        key="add_funds_input_custom"
        className={twClassnames(
          'flex flex-1 items-center justify-center whitespace-nowrap rounded-lg rounded-none border border-l-0 border-theme-neutral-400 px-4 text-sm text-black outline-none transition first:rounded-l-lg last:rounded-r-lg hover:bg-theme-neutral-300',
          {
            'border-0 border-theme-primary-600 bg-theme-primary-600 text-white hover:border-theme-primary-600 hover:bg-theme-primary-600':
              customSelected,
            'bg-theme-danger-700 hover:bg-theme-danger-700': customSelected && error,
          },
        )}
      >
        <span>$</span>
        <Input
          ref={inputRef}
          className={twClassnames(
            // override defaults
            'border-none bg-transparent px-0 py-0 !outline-none placeholder:text-theme-neutral-500 disabled:bg-transparent',
            // custom
            'min-w-12 text-center text-black',
            {
              'text-white': customSelected,
            },
          )}
          type="text"
          pattern="[0-9.]+"
          disabled={disabled}
          value={customSelected ? amount || '' : ''}
          placeholder={customSelected ? '' : 'other'}
          onChangeText={(t) => {
            const re = /[0-9.]+/;
            const match = re.exec(t);
            if (t === '' || match) {
              const value = Number(match?.[0]) || undefined;
              onInputChanged({ value });
            }
          }}
          onFocus={handleCustomSelected}
        />
      </div>
    </ButtonGroup>
  );
};

export default AddFundsInputGroup;

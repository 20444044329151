import Card from '../common/Card';

const UsageSumCard = ({ title, amount, timePeriod }: { title: string; amount: number; timePeriod: string }) => (
  <Card
    noHover
    className="flex flex-col gap-3 border-theme-neutral-200 bg-white px-8 py-6 shadow-theme-02"
  >
    <div className="text-sm">{title}</div>
    <div className="text-xl font-semibold text-black">{amount}</div>
    <hr className="bg-theme-neutral-300" />
    <div className="text-xs">{timePeriod}</div>
  </Card>
);

export default UsageSumCard;

import flatMap from 'lodash/flatMap';
import kebabCase from 'lodash/kebabCase';

import {
  Instance,
  InstanceHardware,
  InstanceHardwareCPU,
  InstanceHardwareGPU,
  InstanceHardwareRAM,
  InstanceHardwareStorage,
  InstancePricing,
  InstanceResponse,
  InstanceStatus,
  Option,
} from './types';

export const dockerImages = [
  { title: 'pytorch/pytorch:latest', packageId: '', icon: 'pytorch' },
  // { title: 'ubuntu:latest', packageId: '' }, TODO: enable when available
  {
    title: 'nvidia/cuda:12.3.1-devel-ubuntu22.04',
    packageId: 'nvida_cuda_12.3.1-devel-ubuntu22.04',
    icon: 'nvidia',
  },
  {
    title: 'nvidia/cuda:12.4.1-cudnn-devel-ubuntu22.04',
    packageId: 'nvida_cuda_12.4.1-cudnn-devel-ubuntu22.04',
    icon: 'nvidia',
  },
  {
    title: 'tensorflow/tensorflow:latest-gpu',
    packageId: 'tensorflow_tensorflow_latest-gpu',
    icon: 'tensorflow',
  },
];

// TODO: unused update or delete
export const gpuWithMemory = {
  'NVIDIA H100 SXM': 80,
  'NVIDIA H100 PCIe': 80,
  'NVIDIA A100-80GB SXM': 80,
  'NVIDIA A100-80GB PCIe': 80,
  'NVIDIA A100-40GB PCIe': 40,
  'NVIDIA A40': 48,
  'NVIDIA L40S': 48,
  'NVIDIA GeForce RTX 4090': 24,
  'NVIDIA GeForce RTX 3090': 24,
  'NVIDIA GeForce RTX 3060': 12,
  'NVIDIA RTX A6000': 48,
  'NVIDIA RTX A5000': 24,
  'NVIDIA RTX A4000': 16,
  'AMD MI250': 128,
  'AMD MI300': 192,
  'AMD RX 6900 XT': 128,
  'AMD RX 6800 XT': 128,
  'AMD RX 6800': 128,
  'AMD RX 7900 XTX': 24,
  'AMD RX 7900 XT': 20,
  'AMD Pro W7900': 48,
  'AMD Pro W7800': 32,
  'NVIDIA Tesla T4': 50,
};

// TODO: update or delete
export const gpuMetadata = Object.entries(gpuWithMemory).map(([gpu, memory]) => ({
  label: gpu.replace(/(nvidia|amd) (GeForce)?/i, ''),
  value: gpu.replace(/\s/g, '-'),
  memory,
}));

export const gpuTypes: Option[] = gpuMetadata;

export const interconnectNetworks = [
  'EFA 1.6 (AWS)',
  'Ethernet',
  'Infiniband 1.6 Tb',
  'Infiniband 3.2 Tb',
  'Infiniband 6.4 Tb',
  'RoCE',
  'TCPX (Google)',
].map((net) => ({ label: net, value: kebabCase(net) }));

const mapModelNames: { [key: string]: string } = {
  'Tesla-T4': 'Tesla T4',
  'NVIDIA-H100-80GB-SXM': 'H100 SXM',
  'NVIDIA-H100-80GB-HBM3': 'H100 SXM',
  'NVIDIA-A100-SXM4-40GB': 'A100 SXM4',
  'NVIDIA-H100-80GB-NVL': 'H100 NVL',
  'NVIDIA-A100-80GB-PCIe': 'A100 PCIe',
  'NVIDIA-L40': 'L40',
  'NVIDIA-L40S': 'L40S',
  'NVIDIA-GeForce-RTX-6000-Ada': 'RTX 6000 Ada',
  'NVIDIA-GeForce-RTX-A6000': 'RTX A6000',
  'NVIDIA-GeForce-RTX-4090': 'RTX 4090',
  'NVIDIA-L4': 'L4',
  'NVIDIA-GeForce-RTX-3090': 'RTX 3090',
  'NVIDIA-GeForce-RTX-A5000': 'RTX A5000',
  'NVIDIA-GeForce-RTX-A4000-Ada': 'RTX A4000 Ada',
  'NVIDIA-GeForce-RTX-A4500': 'RTX A4500',
  'NVIDIA-GeForce-RTX-A4000': 'RTX A4000',
  'NVIDIA-GeForce-RTX-3080': 'RTX 3080',
  'NVIDIA-GeForce-RTX-3070': 'RTX 3070',
  'NVIDIA-A40': 'A40',
  'NVIDIA-A30': 'A30',
};

export const mapModelToPrice: { [key: string]: number } = {
  'NVIDIA-H100-80GB-SXM': 250,
  'NVIDIA-H100-80GB-PCIe': 220,
  'NVIDIA-H100-80GB-HBM3': 230,
  'NVIDIA-H100-80GB-NVL': 230,
  'NVIDIA-A100-SXM4-40GB': 120,
  'NVIDIA-A100-80GB-SXM': 164,
  'NVIDIA-A100-80GB-PCIe': 120,
  'NVIDIA-L40': 100,
  'NVIDIA-L40S': 100,
  'NVIDIA-GeForce-RTX-6000-Ada': 90,
  'NVIDIA-GeForce-RTX-A6000': 75,
  'NVIDIA-A40': 50,
  'NVIDIA-GeForce-RTX-4090': 50,
  'NVIDIA-L4': 43,
  'NVIDIA-GeForce-RTX-3090': 30,
  'NVIDIA-GeForce-RTX-A5000': 30,
  'NVIDIA-GeForce-RTX-A4000-Ada': 30,
  'NVIDIA-GeForce-RTX-A4500': 30,
  'NVIDIA-GeForce-RTX-A4000': 30,
  'NVIDIA-GeForce-RTX-3080': 20,
  'NVIDIA-GeForce-RTX-3070': 20,
  'NVIDIA-A30': 20,
  'Tesla-T4': 20,
  Default: 300,
};

export const findGpuPrice = (model?: string, pricing?: InstancePricing) => {
  const amount = pricing?.price?.amount;
  const price = amount ? amount : 0;
  const modelPrice = price || (model && mapModelToPrice[model]) || 0;
  return modelPrice / 100;
};

export const findGPUPriceInstance = (instance?: Instance) => {
  if (!instance) return 0;
  const model = instance.gpu?.model;
  return findGpuPrice(model, instance.pricing);
};

const mapPricingPeriodToString: { [key: string]: string } = {
  hourly: 'hr',
};

export const displayPricePeriod = (pricing: string | undefined) =>
  mapPricingPeriodToString[pricing || 'hourly'] || 'hr';

export const displayModelName = (model?: string) => {
  return model ? mapModelNames[model] || model.split('-').join(' ') : 'Tesla T4';
};

export const computePrice = (gpuModel?: string, gpuCount = 0, pricing?: InstancePricing) => {
  if (!gpuModel) return 0;
  const gpuPrice = findGpuPrice(gpuModel, pricing);
  return gpuCount * gpuPrice;
};

export const calculateGPURam = (gpuRam?: number) => Math.round((gpuRam || 1024 * 16) / 1024);

export const calculateStorageCapacity = (storage: number) => {
  let storageString = 'N/A';
  if (storage) {
    let storageCapacity: string | number = storage || 0;
    let storageLabel = 'GB';
    storageCapacity = Math.floor(storageCapacity);
    if (storageCapacity >= 1024) {
      storageCapacity = storageCapacity / 1024;
      storageCapacity = storageCapacity % 1 === 0 ? storageCapacity : storageCapacity.toFixed(1);
      storageLabel = 'TB';
    }
    storageString = `${storageCapacity} ${storageLabel}`;
  }
  return storageString;
};

export const calculateRamCapacity = (ram?: any) => {
  let ramString = 'N/A';
  if (ram?.capacity) {
    let ramCapacity = ram?.capacity || 1024 * 64;
    let ramLabel = 'GB';
    if (ramCapacity >= 1024) {
      ramCapacity = ramCapacity / 1024;
      ramLabel = 'TB';
    }
    ramCapacity = Math.floor(ramCapacity);
    ramString = `${ramCapacity} ${ramLabel}`;
  }
  return ramString;
};

export const formatInstance = (instance: InstanceResponse): Instance => {
  const hardwareArr: InstanceHardware[] = flatMap(instance?.hardware || {}, (item) => Object.values(item));

  const gpu: InstanceHardwareGPU | undefined = hardwareArr.find(
    (item) => item.hardware_type === 'gpu',
  ) as InstanceHardwareGPU;
  const cpu: InstanceHardwareCPU | undefined = hardwareArr.find(
    (item) => item.hardware_type === 'cpu',
  ) as InstanceHardwareCPU;
  const ram: InstanceHardwareRAM | undefined = hardwareArr.find(
    (item) => item.hardware_type === 'ram',
  ) as InstanceHardwareRAM;
  const storage: InstanceHardwareStorage | undefined = hardwareArr.find(
    (item) => item.hardware_type === 'storage',
  ) as InstanceHardwareStorage;
  const totalGpus = Math.max(instance.gpus_total, 0);
  const reservedGpus = Math.max(instance.gpus_reserved, 0);
  const defaultCount = instance.gpu_count;
  const availableGpus = totalGpus - reservedGpus;
  const gpuCount = Math.max(defaultCount || (instance.reserved ? reservedGpus : availableGpus), 0);
  const gpuRamGB = calculateGPURam(gpu?.ram);
  const totalPrice = computePrice(gpu?.model, gpuCount, instance.pricing);
  const storageCapacity = calculateStorageCapacity(storage?.capacity || 0);
  const ramCapacity = calculateRamCapacity(ram);
  const clusterName = instance.cluster_name;
  const hasPersistentStorage = instance.has_persistent_storage;
  const status = instance.status as InstanceStatus;
  return {
    ...instance,
    hardwareArr,
    gpu,
    cpu,
    ram,
    storage,
    gpuCount,
    gpuRamGB,
    totalPrice,
    storageCapacity,
    ramCapacity,
    clusterName,
    hasPersistentStorage,
    status,
  };
};

export const displayDuration = (duration: any) => {
  const durArr = [];
  if (duration.years) {
    durArr.push(`${duration.years} y`);
  }
  if (duration.months) {
    durArr.push(`${duration.months} m`);
  }
  if (duration.days) {
    durArr.push(`${duration.days} d`);
  }
  if (duration.hours) {
    durArr.push(`${duration.hours} h`);
  }
  if (duration.minutes) {
    durArr.push(`${duration.minutes} mins`);
  }
  if (duration.seconds) {
    durArr.push(`${duration.seconds} s`);
  }
  return durArr.length ? durArr.slice(0, 2).join(', ') : '--';
};

import { ReactNode } from 'react';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';

import useInterval from '../../hooks/useInterval';
import useUser from '../../hooks/useUser';
import {
  fetchSuppliedInstances,
  getSuppliedInstancesInitialLoading,
  getUnpricedSupplied,
} from '../../slices/instances';
import { getSupply, setHyperInstallCompleted, setMicroInstallCompleted, setMicroInstalled } from '../../slices/supply';
import { AppDispatch } from '../../store';
import { CodeBlock, CodeBlockActions, CodeBlockContent, CodeBlockCopyButton } from '../common/CodeBlock';
import LoadingSpinner from '../common/LoadingSpinner';
import RadioButton from './RadioButton';

const SetupCode = ({ children }: { children: ReactNode }) => (
  <code className="rounded border border-theme-neutral-300 bg-theme-neutral-50 px-2 py-0.5 font-medium text-theme-primary-700">
    {children}
  </code>
);

const SetupInstructions = () => {
  const { microInstalled, microInstallCompleted, hyperInstallCompleted } = useSelector(getSupply);
  const dispatch = useDispatch<AppDispatch>();
  const unpricedSupplied = useSelector(getUnpricedSupplied);
  const suppliedInitialLoading = useSelector(getSuppliedInstancesInitialLoading);
  const { userInfo } = useUser();
  const installMicroString = `sudo snap install microk8s --classic --channel=1.30

sudo usermod -a -G microk8s $USER # allow non-sudo use of microk8s command
newgrp microk8s # reload shell

microk8s start # boot the cluster
microk8s enable rbac # improve security
sudo microk8s enable community # add the community repos
microk8s enable argocd # install argocd
microk8s enable nvidia # install the nvidia GPU operator

microk8s kubectl create namespace hyperdos # create the necessary namespace`;

  const installHyperString = `sudo microk8s helm repo add hyperdos https://hyperboliclabs.github.io/Hyper-dOS
sudo microk8s helm install hyperdos hyperdos/hyperdos --version 0.0.1-alpha.4 --set
token=${userInfo?.api_key}`;

  useInterval(() => {
    if (hyperInstallCompleted === 'done') {
      dispatch(fetchSuppliedInstances());
    }
  }, 5000);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">
          Do you have&nbsp;
          <SetupCode>MicroK8s</SetupCode>
          &nbsp;installed yet?
        </div>
        <div className="text-sm">
          MicroK8s is a lightweight Kubernetes distribution that is designed to run on local systems. We use it to set
          up your cloud. Don't worry if you are not familiar with it - it's hyper simple to install with less than 5
          command lines!
        </div>
        <div className="flex flex-wrap gap-3">
          <RadioButton
            key="yes-microk8s"
            text="Yes, I have"
            selected={microInstalled === 'already'}
            onClick={() => dispatch(setMicroInstalled('already'))}
            otherSelected={!!microInstalled && microInstalled !== 'already'}
          />
          <RadioButton
            key="no-microk8s"
            text="Not yet"
            selected={microInstalled === 'not-yet'}
            onClick={() => dispatch(setMicroInstalled('not-yet'))}
            otherSelected={!!microInstalled && microInstalled !== 'not-yet'}
          />
        </div>
      </div>
      {microInstalled === 'not-yet' && (
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Let's install&nbsp;
            <SetupCode>MicroK8s</SetupCode>
            &nbsp;in just one minute!
          </div>
          <div className="text-sm">Open the terminal and copy the following command lines to run.</div>
          <CodeBlock
            value={installMicroString}
            className="mt-4"
          >
            <CodeBlockActions>
              <CodeBlockCopyButton />
            </CodeBlockActions>
            <CodeBlockContent
              language="shell"
              customStyle={{ padding: '30px', overflow: 'unset' }}
            />
          </CodeBlock>

          <div className="flex flex-wrap gap-3">
            <RadioButton
              key="done-install"
              text="Done"
              selected={microInstallCompleted === 'done'}
              onClick={() => dispatch(setMicroInstallCompleted('done'))}
              otherSelected={!!microInstallCompleted && microInstallCompleted !== 'done'}
            />
            <RadioButton
              key="error-install"
              text="Something went wrong"
              selected={microInstallCompleted === 'error'}
              onClick={() => dispatch(setMicroInstallCompleted('error'))}
              otherSelected={!!microInstallCompleted && microInstallCompleted !== 'error'}
              className="border-theme-danger-500 text-theme-danger-600 hover:border-theme-danger-600"
            />
          </div>
        </div>
      )}
      {(microInstallCompleted === 'done' || microInstalled === 'already') && (
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Now, one last step to install the Hyperbolic decentralized OS&nbsp;
            <SetupCode>HyperdOS</SetupCode>!
          </div>
          <div className="text-sm">
            Hyper-dOS is a decentralized operating system developed by Hyperbolic to manage and monitor your supplied
            machines.
          </div>
          <CodeBlock value={installHyperString}>
            <CodeBlockActions>
              <CodeBlockCopyButton />
            </CodeBlockActions>
            <CodeBlockContent
              language="shell"
              className="break-all"
              customStyle={{ padding: '30px', overflow: 'unset' }}
              codeTagProps={{
                className: 'break-all',
              }}
            />
          </CodeBlock>
          <div className="flex flex-wrap gap-3">
            <RadioButton
              key="done-hyper"
              text="Done"
              selected={hyperInstallCompleted === 'done'}
              onClick={() => {
                dispatch(setHyperInstallCompleted('done'));
                dispatch(fetchSuppliedInstances());
              }}
              otherSelected={!!hyperInstallCompleted && hyperInstallCompleted !== 'done'}
            />
            <RadioButton
              key="error-hyper"
              text="Something went wrong"
              selected={hyperInstallCompleted === 'error'}
              onClick={() => dispatch(setHyperInstallCompleted('error'))}
              otherSelected={!!hyperInstallCompleted && hyperInstallCompleted !== 'error'}
              className="border-theme-danger-500 text-theme-danger-600 hover:border-theme-danger-600"
            />
          </div>
        </div>
      )}
      {hyperInstallCompleted === 'done' && (
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Perfect! Let's see if your device is securely connected to our network...
          </div>
          <div className="text-sm">
            We are actively checking for any new nodes that just joined with your supplier ID. This should only take a
            moment.
          </div>
          {suppliedInitialLoading && (
            <div className="flex items-center gap-4">
              <LoadingSpinner size={24} />
              <div>Checking in just a moment...</div>
            </div>
          )}
          {unpricedSupplied.length > 0 && (
            <div className="flex gap-2 text-sm">
              <FaCircleCheck
                className="text-theme-primary-600"
                size={20}
              />
              Congrats! Your device (ID: {unpricedSupplied[0]?.id}) has successfully joined our network! Please proceed
              to the next step to set up your company name, pricing, and so on.
            </div>
          )}
          {unpricedSupplied.length === 0 && !suppliedInitialLoading && (
            <div className="flex gap-2 text-sm">
              <FaCircleXmark
                className="text-theme-danger-600"
                size={20}
              />
              Sorry! We cannot find a recent cluster / node from your account. Please try again!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SetupInstructions;

import { ReactNode, useState } from 'react';
import { LuEye, LuEyeOff } from 'react-icons/lu';

import twClassnames from '../../utils/classnames';
import Button from './Button';
import CopyButton from './CopyButton';
import DotsHorizontalIcon from './icons/DotsHorizontalIcon';

const APIKey = ({
  apiKey,
  text = 'API Key',
  className,
  containerClassName,
  additionalButtons,
  innerClassName,
  textClassName,
  expandPosition = 'inner',
}: {
  apiKey?: string;
  text?: string;
  containerClassName?: string;
  className?: string;
  additionalButtons?: ReactNode;
  innerClassName?: string;
  textClassName?: string;
  expandPosition?: 'inner' | 'below';
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  if (!apiKey) return null;
  return (
    <div
      className={twClassnames(
        'flex w-full flex-col gap-4 rounded-lg bg-theme-neutral-100 px-5 py-3',
        containerClassName,
      )}
    >
      <div className={twClassnames('flex w-full items-center justify-between gap-4', className)}>
        <div className={twClassnames('h-6 text-nowrap text-sm font-medium text-black', textClassName)}>{text}</div>
        {showApiKey && expandPosition === 'inner' && (
          <div className={twClassnames('flex-1 grow truncate px-4 text-sm', innerClassName)}>{apiKey}</div>
        )}
        {!showApiKey && (
          <div className={twClassnames('flex flex-1 items-center justify-center gap-6', innerClassName)}>
            <div className="flex items-center gap-3">
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
            </div>
            <div className="hidden items-center gap-3 sm:flex">
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
            </div>
          </div>
        )}
        <div className="flex items-center gap-4">
          <Button
            variant="link"
            className="text-theme-neutral-600 hover:text-theme-primary-600"
          >
            {showApiKey ? (
              <LuEyeOff
                className="cursor-pointer transition"
                size={20}
                onClick={() => setShowApiKey(false)}
              />
            ) : (
              <LuEye
                className="cursor-pointer transition"
                size={20}
                onClick={() => setShowApiKey(true)}
              />
            )}
          </Button>
          {/* copy */}
          <CopyButton
            id="api-key"
            size={20}
            copyText={apiKey}
            className="text-theme-neutral-600 hover:text-theme-primary-600"
          />
          {additionalButtons}
        </div>
      </div>
      {showApiKey && expandPosition === 'below' && (
        <div className={twClassnames('h-6 w-full flex-1 grow break-all text-sm')}>{apiKey}</div>
      )}
    </div>
  );
};

export default APIKey;

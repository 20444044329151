import { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import useModelUsage from '../../hooks/useModelUsage';
import { getChartColor } from '../../utils/chart';
import ChartPill from './ChartPill';
import UsageChart from './UsageChart';
import UsageChartCard from './UsageChartCard';
import UsageTable from './UsageTable';

const columnHelper = createColumnHelper<any>();

const ImageCharts = () => {
  const type = 'sd';
  const [hiddenModels, setHiddenModels] = useState<string[]>([]);
  const { mapModelToName, chartData, tableData, modelsWithUsage } = useModelUsage(type, (item) => item.image_steps, [
    'image_steps',
  ]);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Model',
      cell: (info) => mapModelToName(info.getValue()),
    }),
    columnHelper.accessor('image_steps', {
      header: 'Image Steps',
    }),
  ];

  return (
    <UsageChartCard>
      <div className="flex flex-col gap-2">
        <div className="text-lg font-semibold text-black">Image Generation</div>
        <div className="text-sm">Keep track of the number of image steps generated by each model overtime.</div>
      </div>
      <div className="flex gap-3">
        {modelsWithUsage.map((model, i) => (
          <ChartPill
            key={`${model}-pill`}
            color={getChartColor(type, i)}
            text={mapModelToName(model)}
            onClick={() => {
              const hiddenIndex = hiddenModels.indexOf(model);
              if (hiddenIndex > -1) {
                return setHiddenModels((hidden) => hidden.slice(0, hiddenIndex).concat(hidden.slice(hiddenIndex + 1)));
              }
              return setHiddenModels((hidden) => hidden.concat(model));
            }}
            selected={hiddenModels.includes(model)}
          />
        ))}
      </div>
      <div className="text-sm font-semibold text-black">Top Image Steps</div>
      <UsageChart
        type={type}
        chartData={chartData}
        modelsWithUsage={modelsWithUsage}
        hiddenModels={hiddenModels}
        mapModelToName={mapModelToName}
      />
      <UsageTable
        tableData={tableData}
        columns={columns}
      />
    </UsageChartCard>
  );
};

export default ImageCharts;

import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const UsageTable = ({ tableData, columns }: { tableData: any; columns: any }) => {
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <table className="mb-8 mt-4 border-collapse text-sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="border-b border-theme-neutral-300"
          >
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="py-5 text-left font-semibold text-theme-neutral-700"
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className="border-b border-theme-neutral-300"
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="py-5 text-theme-neutral-700"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsageTable;

const ChartBreakoutIcon = ({ width = 29, height = 28, color = 'currentColor', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="chart-breakout-square">
      <path
        id="Icon"
        d="M13.3333 3.49992H9.6C7.63982 3.49992 6.65972 3.49992 5.91103 3.8814C5.25247 4.21695 4.71703 4.75238 4.38148 5.41095C4 6.15964 4 7.13973 4 9.09992V18.8999C4 20.8601 4 21.8402 4.38148 22.5889C4.71703 23.2475 5.25247 23.7829 5.91103 24.1184C6.65972 24.4999 7.63982 24.4999 9.6 24.4999H19.4C21.3602 24.4999 22.3403 24.4999 23.089 24.1184C23.7475 23.7829 24.283 23.2475 24.6185 22.5889C25 21.8402 25 20.8601 25 18.8999V15.1666M14.5 9.33325H19.1667V13.9999M18.5833 4.08325V2.33325M23.1792 5.32069L24.4167 4.08325M24.4286 9.91659H26.1786M4 15.5715C4.7606 15.689 5.53985 15.7499 6.33333 15.7499C11.4508 15.7499 15.9762 13.2154 18.723 9.33325"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ChartBreakoutIcon;

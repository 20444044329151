import { forwardRef, TextareaHTMLAttributes } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';

import twClassnames from '../../utils/classnames';
import TextArea from './TextArea';

interface TextAreaContainerProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
  error?: string;
  infoTooltip?: string;
}

const TextAreaContainer = forwardRef<HTMLTextAreaElement, TextAreaContainerProps>(
  ({ id, label, labelClassName, required = false, error, infoTooltip, ...otherProps }, ref) => {
    return (
      <div className="mb-4 flex flex-col">
        {label && (
          <label className={twClassnames('mb-2 mr-3 flex items-center text-sm text-black', labelClassName)}>
            <div>
              {label}
              {required && '*'}
            </div>
            {infoTooltip && (
              <AiOutlineInfoCircle
                data-tooltip-id={`info-${id}`}
                className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
              />
            )}
            <Tooltip
              id={`info-${id}`}
              className="z-10"
              place="top"
            >
              {infoTooltip}
            </Tooltip>
          </label>
        )}
        <TextArea
          ref={ref}
          containerClassName={error ? '!border-theme-danger-600' : ''}
          {...otherProps}
        />
        {error && <div className="mt-2 text-theme-danger-600">{error}</div>}
      </div>
    );
  },
);

export default TextAreaContainer;

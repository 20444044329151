import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Banner from '../components/common/Banner';
import HyperLink from '../components/common/HyperLink';
import Layout from '../components/common/Layout';
import useLocalStorage from '../hooks/useLocalStorage';
import useUser from '../hooks/useUser';
import { AppDispatch } from '../store';

// todo: don't allow access to tabs if not logged in even if marketplace flag enabled

const ComputeWrapper = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useUser();
  const [, setRentLastSeen] = useLocalStorage('rent-last-seen');

  const navigate = useNavigate();
  const match = useMatch('/compute/:item/*');
  const computeItems = ['rent', 'instances'];
  const selectedIndex = Math.max(match ? computeItems.findIndex((item) => item === match?.params?.item) : 0, 0);

  useEffect(() => {
    setRentLastSeen(new Date().toISOString());
  }, [dispatch, setRentLastSeen]);

  return (
    <Layout>
      <Banner
        storageKey="rentGPUsLink"
        className="justify-between gap-2 px-6 md:justify-center md:gap-48 md:px-10"
      >
        <div>
          Follow&nbsp;
          <HyperLink
            href="https://docs.hyperbolic.xyz/docs/rent-gpus"
            rel="noreferrer noopener"
            target="_blank"
          >
            this step-by-step guide
          </HyperLink>
          &nbsp;to learn how to run pytorch on a GPU.
        </div>
      </Banner>
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={(index) => navigate(`${computeItems[index]}`)}
      >
        <div className="sticky top-[88px] z-[5] bg-white pb-1 md:top-[60px]">
          <h2>Rent GPUs</h2>
          <TabList>
            <Tab>GPU List</Tab>
            {!!user && <Tab>Instances</Tab>}
          </TabList>
        </div>
        <div className="my-6">
          <TabPanel>
            <Outlet />
            {/* <MarketplaceInstances /> */}
          </TabPanel>
          <TabPanel>
            <Outlet />
            {/* <MyInstances /> */}
          </TabPanel>
        </div>
      </Tabs>
    </Layout>
  );
};

export default ComputeWrapper;

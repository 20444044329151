import { LinkHTMLAttributes } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

import { buttonClasses, ButtonVariant } from '../../utils/button';
import twClassnames from '../../utils/classnames';

type HyperLinkProps = {
  href?: string;
  variant?: ButtonVariant;
  to?: To;
} & (LinkHTMLAttributes<HTMLAnchorElement> | LinkProps);

const HyperLink = (props: HyperLinkProps) => {
  if (props.href) {
    const { href, variant = 'link', className, ...otherProps } = props;
    return (
      <a
        {...otherProps}
        href={href}
        className={twClassnames(
          'text-sm transition',
          {
            'inline-block rounded-lg border px-4 py-2': variant !== 'link',
          },
          buttonClasses[variant],
          className,
        )}
      />
    );
  } else if (props.to) {
    const { variant = 'link', to, className, ...otherProps } = props;
    return (
      <Link
        {...otherProps}
        to={to}
        className={twClassnames(
          'text-sm transition',
          {
            'inline-block rounded-lg border px-4 py-2': variant !== 'link',
          },
          buttonClasses[variant],
          className,
        )}
      />
    );
  } else {
    throw Error('Either href or to must be passed into the props.');
  }
};

export default HyperLink;

import { ReactNode, useEffect, useRef } from 'react';

import twClassnames from '../../../utils/classnames';

const MessageList = ({ children, className }: { children: ReactNode; className?: string }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const previousScrollHeight = useRef<number>(0);
  useEffect(() => {
    if (listRef.current) {
      const scrollHeight = listRef.current.scrollHeight;
      if (scrollHeight !== previousScrollHeight.current) {
        previousScrollHeight.current = scrollHeight;
        listRef.current.scrollTo({
          top: scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [children]);
  return (
    <div
      className={twClassnames('relative mb-4 flex flex-1 flex-col lg:overflow-y-auto', className)}
      style={{ overflowAnchor: 'auto' }}
      ref={listRef}
      onResize={() => {
        console.log('resize');
      }}
    >
      {children}
    </div>
  );
};

export default MessageList;

import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../../slices/auth';
import { createMachine } from '../../slices/machines';
import { AppDispatch } from '../../store';
import NewGPUForm, { NewMachineInputs } from '../Compute/NewMachineForm';

const SupplyGPUModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const onSubmit: SubmitHandler<NewMachineInputs> = (data) => {
    toast.promise(
      dispatch(
        createMachine({
          ...data,
          owner: user?.uid,
        }),
      ).unwrap(),
      {
        loading: 'Loading...',
        success: 'GPU supply was created!',
        error: 'GPU supply failed to create.',
      },
    );
    onClose();
  };
  return (
    <div>
      <h2 className="mb-6">Supply</h2>
      <NewGPUForm onSubmit={onSubmit} />
    </div>
  );
};

export default SupplyGPUModal;

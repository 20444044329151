export const isProdBuild = process.env.NODE_ENV === 'production';
export const isProdEnv = isProdBuild && window?.location.href.includes('app.hyperbolic.xyz');
export const isTestEnv = isProdBuild && window?.location.href.includes('test.hyperbolic.xyz');

export const backendHost = isTestEnv ? 'https://api.dev-hyperbolic.xyz' : import.meta.env.VITE_BACKEND_HOST;

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const API_ERROR_GENERIC = 'Something went wrong, please try again later';
export const API_ERROR_UNAUTHORIZED = 'Not authorized to perform this action';
export const API_ERRORS: { [key: number]: { message: string } } = {
  100: { message: "You don't have enough credits!" },
  101: { message: 'Node already rented' },
  102: { message: 'Instance already terminated' },
  103: { message: API_ERROR_UNAUTHORIZED },
  104: { message: API_ERROR_GENERIC },
  105: { message: API_ERROR_GENERIC },
  106: { message: API_ERROR_UNAUTHORIZED },
  107: { message: API_ERROR_GENERIC },
  108: { message: API_ERROR_GENERIC },
  112: { message: 'You must verify your email to continue' },
  114: { message: 'You must buy credits to rent GPUs' },
};

export const ERROR_CODES: { [key: number]: { message: string } } = {
  40302: {
    message: 'Please reduce the length of the prompt and/or requested tokens',
  },
  40303: {
    message: 'Please reduce the length of the prompt and/or requested tokens',
  },
};

export const discordLink = 'https://discord.gg/hyperbolic';
export const twitterLink = 'https://twitter.com/hyperbolic_labs';
export const supportEmail = 'support@hyperbolic.xyz';
export const salesLink = 'https://calendly.com/jeremhazan/hyperbolic-sales-demo';

// Posthog config
export const POSTHOG_HOST = 'https://ph.hyperbolic.xyz';
export const POSTHOG_KEY = isProdEnv
  ? 'phc_Vd2H9ikzOYqQID0gnI4yvTlhLBzKcagOcZ2uuSbQfgS'
  : 'phc_aTeJ3t31v8gxMOZshhci6OA73c9apCwCQzWjwpBknlT';
export const POSTHOG_OPTIONS = {
  api_host: POSTHOG_HOST,
  ui_host: 'https://us.posthog.com',
  bootstrap: {
    featureFlags: {
      'expandable-instance-card': false,
      team: false,
      supply: false,
    },
  },
};

export const HTTP_STATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

// todo: get rid of this once better logic is in place to handle code previews for VLM images
export const vlmDefaultImageURL =
  'https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_square.jpg';

export const defaultTextPrompt = 'What can I do in SF?';
export const defaultVisionPrompt = 'What is this image?';
export const defaultImagePrompt = 'A man riding a horse in SF';

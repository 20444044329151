import { usePostHog } from 'posthog-js/react';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { openModal } from '../../slices/modals';
import { AppDispatch } from '../../store';
import twClassnames from '../../utils/classnames';
import Button from '../common/Button';
import { ModalName } from '../modals';

const HeaderBillingItem = ({ balance }: { balance: number | undefined }) => {
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();

  const currentBalanceLow = balance && balance < 0;

  return (
    <div className="mx-3 hidden items-center rounded-[15px] border border-theme-neutral-400 p-1 text-sm text-theme-primary-600 md:flex">
      <AiOutlineDollarCircle
        size={22}
        className="mr-1"
      />
      <div
        className={twClassnames(
          'font-semibold transition-opacity',
          balance == null ? 'animate-pulse opacity-10' : 'opacity-100',
          { 'text-theme-danger-600': currentBalanceLow },
        )}
      >
        {balance != null ? (balance / 100).toFixed(2) : '...'}
      </div>
      <Button
        className="ml-3 rounded-xl px-3 py-1 text-sm"
        onClick={() => {
          posthog?.capture('Deposit Button Clicked');
          // X Ads event
          (window as any)?.twq?.('event', 'tw-on13s-oo36t');
          dispatch(
            openModal({
              name: ModalName.AddFundsModal,
              props: {},
              styles: {
                content: {
                  maxWidth: '650px',
                },
              },
            }),
          );
        }}
      >
        Deposit
      </Button>
    </div>
  );
};

export default HeaderBillingItem;

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getModel } from '../../slices/models';
import { RootState } from '../../store';
import { badgeClassName, displayMaxTokens, displayModelPrice, mapNumberFormatToTooltip } from '../../utils/models';
import Badge from '../common/Badge';
import ModalClose from '../common/ModalClose';
import AudioInputParametersForm from '../Models/AudioInputParametersForm';
import GenerateImageButton from '../Models/GenerateImageButton';
import ImageInputParametersForm from '../Models/ImageInputParametersForm';
import ModelDropdown from '../Models/ModelDropdown';
import TextInputParametersForm from '../Models/TextInputParametersForm';

const mapModelTypeToParamsForm: { [key: string]: any } = {
  llm: TextInputParametersForm,
  image: ImageInputParametersForm,
  audio: AudioInputParametersForm,
};

const InputParametersModal = ({ onClose }: { onClose: () => void }) => {
  const { modelId } = useParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const ParamsComponent = mapModelTypeToParamsForm[model?.type || 'llm'] || mapModelTypeToParamsForm['llm'];
  const contextLength = model?.contextLength || model?.maxTokens;
  return (
    <div
      id="input-params-modal"
      className="flex flex-col"
    >
      {model?.type === 'image' && <ModalClose onClose={onClose} />}
      <div className="mb-6 text-center text-lg font-semibold">Model Input Parameters</div>
      <div className="flex flex-col gap-4">
        <div>
          <div className="mb-4 text-black">Models</div>
          <ModelDropdown />
        </div>
        {(model?.numberFormat || contextLength) && (
          <div className="flex gap-2">
            {model?.numberFormat && (
              <Badge
                id={`${model?.slug}-number-format`}
                text={model?.numberFormat.toUpperCase()}
                className={badgeClassName}
                tooltipText={mapNumberFormatToTooltip[model?.numberFormat.toLowerCase()]}
              />
            )}
            {contextLength && (
              <Badge
                id={`${model?.slug}-context-length`}
                text={`Context length: ${displayMaxTokens(contextLength)} tokens`}
                className={badgeClassName}
              />
            )}
          </div>
        )}
        {model?.price?.amount && (
          <div className="text-sm">
            Price:&nbsp;
            <span className="text-theme-primary-600">{displayModelPrice(model?.price, model?.type)}</span>
          </div>
        )}
        <div className="-mx-5 flex flex-col px-5">
          <ParamsComponent />
        </div>
      </div>

      {model?.type === 'image' && (
        <div>
          <GenerateImageButton onClick={onClose} />
        </div>
      )}
    </div>
  );
};

export default InputParametersModal;

import { useEffect } from 'react';
import { FiCodesandbox } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import HyperLink from '../components/common/HyperLink';
import Layout from '../components/common/Layout';
import { fetchFAQ } from '../slices/support';
import { AppDispatch } from '../store';
import { discordLink, supportEmail } from '../utils/constants';

const Support = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchFAQ());
  }, [dispatch]);
  return (
    <Layout className="mb-10 gap-8">
      <h2>Help Center</h2>
      <Outlet />
      <div className="h-[1px] w-full bg-theme-neutral-200" />
      <div className="flex items-center justify-center gap-6 rounded-lg border border-theme-primary-200 px-6 py-12 shadow-theme-02 lg:px-16">
        <FiCodesandbox
          size={24}
          className="hidden min-w-8 xs:block"
        />
        <div className="flex flex-col items-center text-sm text-black">
          <div>If you didn't find what you're looking for, don't worry - we're still here for you!</div>
          <div className="flex flex-wrap">
            <HyperLink
              href={discordLink}
              rel="noreferrer noopener"
              target="_blank"
            >
              Chat with our friendly team on Discord
            </HyperLink>
            &nbsp; or send us an email at&nbsp;
            <HyperLink
              href={`mailto:${supportEmail}`}
              rel="noreferrer noopener"
              target="_blank"
            >
              {supportEmail}
            </HyperLink>
            .
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;

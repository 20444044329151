import { useEffect, useState } from 'react';
import { sub } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../../../../slices/auth';
import { fetchInstanceHistory, getInstanceHistory } from '../../../../slices/usage';
import { AppDispatch } from '../../../../store';
import Button from '../../../common/Button';
import BillingInstanceCard from './cards/BillingInstanceCard';
import BillingItemHeader from './headers/BillingItemHeader';
import BillingItemHeaderPeriodSubtitle from './headers/BillingItemHeaderPeriodSubtitle';

const MIN_SHOWN_AMOUNT = 6;
const STEP_SIZE = 6;

const BillingItemsGPUMarketplace = () => {
  const dispatch = useDispatch<AppDispatch>();
  const instanceHistory = useSelector(getInstanceHistory);
  const user = useSelector(getUser);
  const [maxDays, setMaxDays] = useState(MIN_SHOWN_AMOUNT);

  useEffect(() => {
    if (user) {
      dispatch(fetchInstanceHistory());
    }
  }, [user, dispatch]);

  const showMore = () => {
    setMaxDays(Math.min(maxDays + STEP_SIZE, instanceHistory.length));
  };

  const collapse = () => {
    setMaxDays(MIN_SHOWN_AMOUNT);
  };

  const showButton = MIN_SHOWN_AMOUNT < instanceHistory.length;
  const canCollapse = maxDays >= instanceHistory.length;

  return (
    <div className="flex grow flex-col">
      <BillingItemHeader
        title="Billing Explorer"
        subtitle={<BillingItemHeaderPeriodSubtitle prefix="Here you can see a history of your charges." />}
      />
      <div className="mt-2 flex flex-col gap-3 lg:grid lg:grid-cols-2">
        {!instanceHistory.length && <div className="text-sm">You don't have any activity yet.</div>}
        {instanceHistory.slice(0, maxDays).map((ih) => (
          <BillingInstanceCard
            {...ih}
            key={`${ih.instance_name}-${ih.started_at}`} // todo: use a key that's not garbage
          />
        ))}
      </div>
      {showButton && (
        <Button
          variant="link"
          className="mt-4 self-center"
          onClick={canCollapse ? collapse : showMore}
        >
          {canCollapse ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </div>
  );
};

export default BillingItemsGPUMarketplace;

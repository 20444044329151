import { BsPaperclip } from 'react-icons/bs';

import twClassnames from '../../utils/classnames';
import Dropdown, { DropdownButton, DropdownMenu, DropdownOverlay } from '../common/Dropdown';
import UploadDropdownItem from './UploadDropdownItem';

const UploadDropdown = ({ onDrop, position = 'top' }: { onDrop: any; position?: 'top' | 'bottom' }) => {
  return (
    <Dropdown>
      <DropdownButton
        className="text-sm text-theme-neutral-700 hover:text-theme-primary-600"
        variant="link"
        displayPosition={false}
      >
        <BsPaperclip size={20} />
      </DropdownButton>
      <DropdownOverlay
        className={twClassnames('bottom-0 left-0 mb-7 w-48', {
          'bottom-auto top-0': position === 'bottom',
        })}
      >
        <DropdownMenu>
          <UploadDropdownItem onDrop={onDrop} />
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default UploadDropdown;

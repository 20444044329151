import sortBy from 'lodash/sortBy';

import { isProdEnv } from '../utils/constants';
import Storyblok from '../utils/storyblok';

export const fetchFAQDb = async () => {
  const response = await Storyblok.get('cdn/stories', {
    starts_with: 'faqs',
    version: isProdEnv ? 'published' : 'draft',
  });
  return sortBy(response.data.stories, 'created_at').map((s: any) => ({
    ...s.content,
    questions: sortBy(s.content.questions, 'index'),
    slug: s.slug,
  }));
};

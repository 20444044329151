const AudioWaveIcon = ({ size = 14, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="recording-01">
      <g id="Solid">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.74984 5.24984C2.072 5.24984 2.33317 5.511 2.33317 5.83317V8.1665C2.33317 8.48867 2.072 8.74984 1.74984 8.74984C1.42767 8.74984 1.1665 8.48867 1.1665 8.1665L1.1665 5.83317C1.1665 5.511 1.42767 5.24984 1.74984 5.24984Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.37484 2.9165C4.697 2.9165 4.95817 3.17767 4.95817 3.49984L4.95817 10.4998C4.95817 10.822 4.697 11.0832 4.37484 11.0832C4.05267 11.0832 3.7915 10.822 3.7915 10.4998L3.7915 3.49984C3.7915 3.17767 4.05267 2.9165 4.37484 2.9165Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99984 1.1665C7.322 1.1665 7.58317 1.42767 7.58317 1.74984V12.2498C7.58317 12.572 7.322 12.8332 6.99984 12.8332C6.67767 12.8332 6.4165 12.572 6.4165 12.2498V1.74984C6.4165 1.42767 6.67767 1.1665 6.99984 1.1665Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.62484 2.9165C9.947 2.9165 10.2082 3.17767 10.2082 3.49984V10.4998C10.2082 10.822 9.947 11.0832 9.62484 11.0832C9.30267 11.0832 9.0415 10.822 9.0415 10.4998V3.49984C9.0415 3.17767 9.30267 2.9165 9.62484 2.9165Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2498 5.24984C12.572 5.24984 12.8332 5.511 12.8332 5.83317V8.1665C12.8332 8.48867 12.572 8.74984 12.2498 8.74984C11.9277 8.74984 11.6665 8.48867 11.6665 8.1665V5.83317C11.6665 5.511 11.9277 5.24984 12.2498 5.24984Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default AudioWaveIcon;

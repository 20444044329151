import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { getModel, getSortedModels } from '../../slices/models';
import { RootState } from '../../store';
import { createModelPath } from '../../utils/models';
import Dropdown, { DropdownButton, DropdownOverlay } from '../common/Dropdown';
import DropdownMenu from '../common/Dropdown/DropdownMenu';
import DropdownMenuItem from '../common/Dropdown/DropdownMenuItem';

const ModelDropdown = () => {
  const { modelId, mode, lang } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const models = useSelector(getSortedModels);
  const modelVariants = model?.variants;
  const currentVariant = searchParams.get('variant') || modelVariants?.[0];
  return (
    <Dropdown>
      <DropdownButton>
        {modelVariants ? (
          currentVariant
        ) : (
          <>
            {model?.name}
            {model?.badgeText && (
              <span className="ml-1 text-theme-primary-600">({model?.badgeText.toLowerCase()})</span>
            )}
          </>
        )}
      </DropdownButton>
      <DropdownOverlay>
        <DropdownMenu className="max-h-40 overflow-y-auto">
          {modelVariants
            ? modelVariants.map((v) => (
                <DropdownMenuItem
                  key={`dropdown-${v}`}
                  active={currentVariant === v}
                  onClick={() => {
                    setSearchParams({ ...searchParams, variant: v });
                  }}
                >
                  {v}
                </DropdownMenuItem>
              ))
            : models
                .filter((m) => m.type === model?.type)
                .map((m) => (
                  <DropdownMenuItem
                    key={`dropdown-${m.model}`}
                    active={m.model === model?.model}
                    to={createModelPath({ slug: m?.slug, mode, lang })}
                  >
                    {m.name}
                    {m.badgeText && <span className="ml-1 text-theme-primary-600">({m.badgeText.toLowerCase()})</span>}
                  </DropdownMenuItem>
                ))}
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default ModelDropdown;

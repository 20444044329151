import { ButtonHTMLAttributes, ReactNode } from 'react';

import twClassnames from '../../utils/classnames';

interface GPUBadgeProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  logo: ReactNode;
  text: string;
  selected?: boolean;
}

const GPUBadge = ({ logo, text, selected, onClick }: GPUBadgeProps) => (
  <button
    className={twClassnames(
      'flex items-center justify-center gap-2 rounded border border-theme-neutral-300 px-2 py-1 text-sm transition hover:border-theme-primary-600',
      { 'border-theme-primary-600': selected },
    )}
    onClick={onClick}
  >
    {logo}
    <div className="text-theme-neutral-700">{text}</div>
  </button>
);

export default GPUBadge;

import useLocalStorage from '../../hooks/useLocalStorage';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';

const NewGPUsModal = ({ onClose }: { onClose: () => void }) => {
  const [, setItem] = useLocalStorage('hideNewGpus');
  return (
    <div className="flex flex-col gap-8 p-4">
      <ModalClose onClose={onClose} />
      <div className="mb-2 text-center text-lg font-semibold text-black">🎊 New GPUs! 🎊</div>
      <ul className="ml-6 list-outside list-decimal space-y-6">
        <li>
          <div className="font-semibold text-black">Understand How to Store your Data</div>
          <div className="text-sm">
            Store important files ONLY in persistent storage&nbsp;
            <code className="rounded border border-theme-neutral-300 bg-theme-neutral-100 px-1 py-0.5 text-theme-primary-700">
              /home/ubuntu
            </code>
            <br />
            Note that ephemeral disk usage above 20GB will trigger a pod reset.
          </div>
        </li>
        <li>
          <div className="font-semibold text-black">Connect to Rented Instance(s)</div>
          <div className="text-sm">Click on the instance card and follow prompts to access your instance(s).</div>
        </li>
      </ul>
      <div className="flex items-center gap-6">
        <Button
          variant="outline"
          className="flex-1"
          onClick={() => {
            setItem(true);
            onClose();
          }}
        >
          Never show this again
        </Button>
        <Button
          className="flex-1"
          onClick={onClose}
        >
          Got it!
        </Button>
      </div>
    </div>
  );
};

export default NewGPUsModal;

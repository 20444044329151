import { useDispatch, useSelector } from 'react-redux';

import { changeImageParameter, getImageInputParameters } from '../../slices/models';
import Dropdown, { DropdownButton, DropdownOverlay } from '../common/Dropdown';
import DropdownMenu from '../common/Dropdown/DropdownMenu';
import DropdownMenuItem from '../common/Dropdown/DropdownMenuItem';

const resolutions = [
  [1024, 1024],
  [1152, 896],
  [1216, 832],
  [1344, 768],
  [1536, 640],
  [1664, 2432],
  [2048, 2048],
  [2432, 1664],
  [640, 1536],
  [768, 1344],
  [832, 1216],
  [896, 1152],
];

const ResolutionDropdown = () => {
  const inputParameters = useSelector(getImageInputParameters);
  const currentResy = `${inputParameters.values.width} x ${inputParameters.values.height}`;
  const dispatch = useDispatch();
  return (
    <Dropdown>
      <DropdownButton>{currentResy}</DropdownButton>
      <DropdownOverlay>
        <DropdownMenu className="max-h-40 overflow-y-auto">
          {resolutions.map((resy) => (
            <DropdownMenuItem
              key={`${resy[0]}-${resy[1]}`}
              active={`${resy[0]} x ${resy[1]}` === currentResy}
              onClick={() => {
                dispatch(changeImageParameter({ id: 'height', value: resy[1] }));
                dispatch(changeImageParameter({ id: 'width', value: resy[0] }));
              }}
            >{`${resy[0]} x ${resy[1]}`}</DropdownMenuItem>
          ))}
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default ResolutionDropdown;

import { ReactNode } from 'react';

import twClassnames from '../../../utils/classnames';
import Button, { ButtonProps } from '../Button';

interface ButtonGroupButtonProps extends ButtonProps {
  active?: boolean;
  children: ReactNode;
  className?: string;
}

const ButtonGroupButton = ({ active = false, children, className, ...otherProps }: ButtonGroupButtonProps) => (
  <Button
    variant={active ? 'primary' : 'outline'}
    className={twClassnames(
      'flex-1 rounded-none border-x-0 outline-none first:rounded-l-lg first:border-l last:rounded-r-lg last:border-r hover:border-theme-primary-600',
      { 'hover:bg-theme-primary-600': active, 'bg-transparent': !active },
      className,
    )}
    {...otherProps}
  >
    {children}
  </Button>
);

export default ButtonGroupButton;

import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { isMobile } from 'react-device-detect';
import { FiRefreshCw } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import Button from '../components/common/Button';
import { ButtonGroup, ButtonGroupButton } from '../components/common/ButtonGroup';
import HyperLink from '../components/common/HyperLink';
import BorderDashedLine from '../components/common/icons/BorderDashedLine';
import StarLinkIcon from '../components/common/icons/StarLinkIcon';
import ModelChat from '../components/ModelChat';
import ModelApi from '../components/Models/ModelApi';
import ModelAudio from '../components/Models/ModelAudio';
import ModelImagesList from '../components/Models/ModelImagesList';
import ModelInputParameters from '../components/Models/ModelInputParameters';
import { cancelAIResponse, getAIResponse, resetChat } from '../slices/chat';
import {
  changeTextParameter,
  fetchModelPrice,
  fetchModels,
  getModel,
  getModelStatus,
  resetParameters,
} from '../slices/models';
import { AppDispatch, RootState } from '../store';
import { ResponseStatus } from '../utils/types';

const mapModelTypeToComponent: { [key: string]: any } = {
  llm: ModelChat,
  image: ModelImagesList,
  audio: ModelAudio,
};

const ModelDetail = () => {
  const { modelId, mode } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const modelStatus = useSelector(getModelStatus);
  const isLoading = modelStatus === ResponseStatus.Loading;
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const aiResponse = useSelector(getAIResponse);

  const DemoComponent = mapModelTypeToComponent[model?.type || 'llm'] || mapModelTypeToComponent.llm;

  useEffect(() => {
    if (modelId && modelStatus === ResponseStatus.Unfetched) {
      dispatch(fetchModels());
    }
    return () => {
      dispatch(resetParameters());
      dispatch(cancelAIResponse());
      dispatch(resetChat());
    };
  }, [modelId, modelStatus, dispatch]);

  useEffect(() => {
    if (model?.defaultMaxTokens) {
      dispatch(
        changeTextParameter({
          id: 'maxTokens',
          value: model?.defaultMaxTokens,
        }),
      );
    }
    if (model?.defaultSystemPrompt) {
      dispatch(
        changeTextParameter({
          id: 'systemPrompt',
          value: model?.defaultSystemPrompt,
        }),
      );
    }
    if (model?.model && !model?.price) {
      dispatch(fetchModelPrice(model?.model.replace('/', '_')));
    }
    if (model?.defaultTemperature) {
      dispatch(
        changeTextParameter({
          id: 'temperature',
          value: model?.defaultTemperature,
        }),
      );
    }
    if (model?.defaultTopP) {
      dispatch(
        changeTextParameter({
          id: 'topP',
          value: model?.defaultTopP,
        }),
      );
    }
  }, [model, dispatch]);

  return (
    <div
      className="flex flex-1 flex-col"
      style={isMobile ? {} : { height: 'calc(100% - 60px)' }}
    >
      <div className="flex h-full flex-col-reverse overflow-y-auto px-7 lg:flex-row lg:gap-7">
        <div className="mb-6 flex flex-1 flex-col rounded-xl border border-theme-primary-100 bg-theme-primary-50 px-6 pb-4 pt-5 lg:overflow-hidden lg:px-6 lg:pt-6">
          <div className="mb-4 flex items-center justify-between gap-4">
            <HyperLink
              variant="outline"
              href={model?.hfLink || `https://huggingface.co/${model?.model}`}
              className="flex cursor-pointer items-center border-theme-primary-300 text-theme-neutral-700 hover:border-theme-primary-600 hover:text-theme-primary-600"
              rel="noreferrer noopener"
              target="_blank"
            >
              <StarLinkIcon
                size={18}
                className="text-theme-primary-600"
              />
              <div className="ml-2 text-sm">{model?.name}</div>
            </HyperLink>
            <div className="flex grow justify-end gap-4">
              <ButtonGroup className="hidden max-w-80 flex-1 md:flex">
                <ButtonGroupButton
                  active={mode !== 'api'}
                  onClick={() => {
                    posthog?.capture('Model Switch Clicked', {
                      type: 'demo',
                    });
                    navigate(`/models/${model?.slug}?${searchParams.toString()}`);
                  }}
                >
                  Demo
                </ButtonGroupButton>
                <ButtonGroupButton
                  active={mode === 'api'}
                  onClick={() => {
                    posthog?.capture('Model Switch Clicked', {
                      type: 'api',
                    });
                    navigate(`/models/${model?.slug}/api?${searchParams.toString()}`);
                  }}
                >
                  API
                </ButtonGroupButton>
              </ButtonGroup>
              <Button
                variant="ghost"
                data-tooltip-id="clear-chat"
                className="flex items-center gap-1 text-base"
                onClick={() => dispatch(resetChat())}
                disabled={aiResponse.length > 0}
              >
                <FiRefreshCw size={20} />
              </Button>
              <Tooltip
                id="clear-chat"
                className="z-10"
                place="top"
              >
                Clear chat
              </Tooltip>
            </div>
          </div>
          {/* <div className="text-theme-neutral-600 mb-4 text-sm [&_strong]:font-semibold">
            <Markdown>{model?.description}</Markdown>
          </div> */}
          <div className="flex text-left">
            <BorderDashedLine className="mb-6 min-h-[1px] text-theme-neutral-400" />
          </div>
          {isLoading ? (
            <div />
          ) : mode === 'api' && model?.model ? (
            <ModelApi />
          ) : (
            <DemoComponent isVisionLanguage={model?.type === 'vlm'} />
          )}
        </div>
        <ModelInputParameters />
      </div>
    </div>
  );
};

export default ModelDetail;

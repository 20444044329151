import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  addWalletAddressDb,
  disconnectWalletAddressDb,
  fetchWalletAddressesDb,
  redeemCouponCodeDb,
} from '../services/settings';
import { RootState } from '../store';
import { ResponseStatus } from '../utils/types';

interface SettingsSlice {
  couponCodeStatus: ResponseStatus;
  couponCodeError: string;
  walletAddresses: string[];
  walletAddressesStatus: ResponseStatus;
  walletAddressesError: string;
}

const initialState: SettingsSlice = {
  couponCodeStatus: ResponseStatus.Unfetched,
  couponCodeError: '',
  walletAddresses: [],
  walletAddressesStatus: ResponseStatus.Unfetched,
  walletAddressesError: '',
};

const couponCodeConstant = 'couponCode';
const fetchWalletAddressesConstant = 'fetchWalletAddresses';
const disconnectWalletAddressConstant = 'disconnectWalletAddress';
const addWalletAddressConstant = 'addWalletAddress';

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    [`${couponCodeConstant}/pending`]: (state) => {
      state.couponCodeStatus = ResponseStatus.Loading;
      state.couponCodeError = '';
    },
    [`${couponCodeConstant}/fulfilled`]: (state) => {
      state.couponCodeStatus = ResponseStatus.Success;
      state.couponCodeError = '';
    },
    [`${couponCodeConstant}/rejected`]: (state, action: any) => {
      state.couponCodeStatus = ResponseStatus.Failure;
      state.couponCodeError = action.error.message;
    },
    [`${fetchWalletAddressesConstant}/pending`]: (state) => {
      state.walletAddressesStatus = ResponseStatus.Loading;
      state.walletAddressesError = '';
    },
    [`${fetchWalletAddressesConstant}/fulfilled`]: (state, action) => {
      state.walletAddresses = action.payload;
      state.walletAddressesStatus = ResponseStatus.Success;
      state.walletAddressesError = '';
    },
    [`${fetchWalletAddressesConstant}/rejected`]: (state, action: any) => {
      state.walletAddressesError = action.error.message;
      state.walletAddressesStatus = ResponseStatus.Failure;
    },
    [`${disconnectWalletAddressConstant}/pending`]: (state) => {
      state.walletAddressesStatus = ResponseStatus.Loading;
      state.walletAddressesError = '';
    },
    [`${disconnectWalletAddressConstant}/fulfilled`]: (state, action) => {
      const addressIndex = state.walletAddresses.findIndex((address) => address === action.payload);
      state.walletAddresses = state.walletAddresses
        .slice(0, addressIndex)
        .concat(state.walletAddresses.slice(addressIndex + 1));
      state.walletAddressesStatus = ResponseStatus.Success;
      state.walletAddressesError = '';
    },
    [`${disconnectWalletAddressConstant}/rejected`]: (state, action: any) => {
      state.walletAddressesError = action.error.message;
      state.walletAddressesStatus = ResponseStatus.Failure;
    },
    [`${addWalletAddressConstant}/pending`]: () => {},
    [`${addWalletAddressConstant}/fulfilled`]: (state, action) => {
      state.walletAddresses = state.walletAddresses.concat(action.payload);
    },
    [`${addWalletAddressConstant}/rejected`]: () => {},
    resetCouponCode: () => {
      return initialState;
    },
  },
});

export const { resetCouponCode } = settingsSlice.actions;

export const redeemCouponCode = createAsyncThunk(`settings/${couponCodeConstant}`, redeemCouponCodeDb);

export const fetchWalletAddresses = createAsyncThunk(
  `settings/${fetchWalletAddressesConstant}`,
  fetchWalletAddressesDb,
);

export const disconnectWalletAddress = createAsyncThunk(
  `settings/${disconnectWalletAddressConstant}`,
  disconnectWalletAddressDb,
);

export const addWalletAddress = createAsyncThunk(`settings/${addWalletAddressConstant}`, addWalletAddressDb);

export const getCouponCodeStatus = (state: RootState) => state.settings.couponCodeStatus;
export const getCouponCodeError = (state: RootState) => state.settings.couponCodeError;
export const getWalletAddresses = (state: RootState) => state.settings.walletAddresses;
export const getWalletAddressesError = (state: RootState) => state.settings.walletAddressesError;
export const getWalletAddressesStatus = (state: RootState) => state.settings.walletAddressesStatus;

export default settingsSlice.reducer;

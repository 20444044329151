import { ReactNode, useEffect } from 'react';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';

import useUser from '../../hooks/useUser';
import { fetchMarketplaceSupplierInstructions, getSupplierInstructions } from '../../slices/instances';
import { AppDispatch } from '../../store';
import twClassnames from '../../utils/classnames';
import { CodeBlock, CodeBlockActions, CodeBlockContent, CodeBlockCopyButton } from '../common/CodeBlock';
import HyperLink from '../common/HyperLink';
import MaxWidth from '../common/MaxWidth';

const InstructionContainer = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={twClassnames('border-b-2 py-4', className)}>{children}</div>
);

const SupplyInstructions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { userInfo } = useUser();
  const supplierInstructions = useSelector(getSupplierInstructions);

  useEffect(() => {
    dispatch(fetchMarketplaceSupplierInstructions());
  }, [dispatch]);

  return (
    <MaxWidth className="my-8">
      <h2>Supply Compute</h2>
      <div className="text-md mt-4 flex flex-col gap-4 text-theme-neutral-700">
        <p>Follow the instructions below to supply compute power through Hyperbolic.</p>
        <p>
          For additional details, check out our{' '}
          <HyperLink
            to="https://github.com/HyperbolicLabs/Hyper-dOS/tree/main"
            className="text-md"
          >
            GitHub page
          </HyperLink>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <InstructionContainer>
          <div>
            <Markdown
              components={{
                code(props) {
                  const { children, className, ...rest } = props;
                  const newLines = children?.toString().split('\n')?.length || 0;
                  const inline = newLines <= 1;
                  const match = /language-(\w+)/.exec(className || '');
                  const childrenWithPopulatedVariables = String(children)
                    .replace(/\n$/, '')
                    .replace('<YOUR_API_KEY>', userInfo?.api_key ?? '<YOUR_API_KEY>');
                  return !inline ? (
                    <CodeBlock
                      value={childrenWithPopulatedVariables}
                      className="mt-4"
                    >
                      <CodeBlockActions>
                        <CodeBlockCopyButton />
                      </CodeBlockActions>
                      <CodeBlockContent language={match?.[1] || 'shell'} />
                    </CodeBlock>
                  ) : (
                    <code
                      className={twClassnames(
                        'inline-code rounded border border-theme-neutral-300 bg-theme-neutral-100 px-1 py-0.5 text-sm text-theme-neutral-600',
                        className,
                      )}
                      {...rest}
                    >
                      {childrenWithPopulatedVariables}
                    </code>
                  );
                },
                a(props) {
                  const { href, children } = props;
                  return (
                    <HyperLink
                      to={href || ''}
                      className="text-md"
                    >
                      {children}
                    </HyperLink>
                  );
                },
                h1(props) {
                  const { children } = props;
                  return <h1 className="mt-3">{children}</h1>;
                },
                h2(props) {
                  const { children } = props;
                  return <h2 className="mt-3">{children}</h2>;
                },
                h3(props) {
                  const { children } = props;
                  return <h3 className="mt-3">{children}</h3>;
                },
                li(props) {
                  const { children } = props;

                  let content = children;
                  // todo: make less hacky
                  // Remove this line from the displayed readme.
                  // Dangerous - will include all list
                  // elements with this string!
                  if (Array.isArray(children) && children?.[1]?.props?.children?.includes('<YOUR_API_KEY>')) {
                    content = 'Run the installation command below:';
                  }
                  return <li className="ml-3 mt-3 list-disc">{content}</li>;
                },
              }}
            >
              {supplierInstructions}
            </Markdown>
          </div>
        </InstructionContainer>
      </div>
    </MaxWidth>
  );
};

export default SupplyInstructions;

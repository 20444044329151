import { useDispatch, useSelector } from 'react-redux';

import useUser from '../../hooks/useUser';
import { getUserInfoStatus, resetApiKey } from '../../slices/auth';
import { AppDispatch } from '../../store';
import { ResponseStatus } from '../../utils/types';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';

const ResetApiKeyModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { userInfo } = useUser();
  const userInfoStatus = useSelector(getUserInfoStatus);
  const userInfoLoading = userInfoStatus === ResponseStatus.Loading;
  return (
    <div className="flex flex-col gap-3 p-3">
      <ModalClose onClose={onClose} />
      <div className="text-lg font-semibold text-theme-neutral-700">Are you sure you want to reset your API key?</div>
      <div className="mb-2 text-sm font-light">
        This will cause any API calls using the old API key to <span className="text-theme-danger-600">fail</span>.
        <br />
        Consider terminating any applications using the old API key before resetting your API key.
      </div>
      <div className="flex items-center gap-3">
        <Button
          variant="neutral-outline"
          onClick={onClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={async () => {
            if (userInfo?.id) {
              await dispatch(resetApiKey({ userId: userInfo?.id }));
            }
            onClose();
          }}
          className="flex-1"
          isLoading={userInfoLoading}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default ResetApiKeyModal;

import { usePostHog } from 'posthog-js/react';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import useAIResponse from '../../hooks/useAIResponse';
import useUser, { UserStatus } from '../../hooks/useUser';
import {
  addMessage,
  cancelAIResponse,
  getAIResponse,
  getChatValue,
  getMessages,
  setChatValue,
} from '../../slices/chat';
import { getModel } from '../../slices/models';
import { AppDispatch, RootState } from '../../store';
import twClassnames from '../../utils/classnames';
import CompletionInputBar from './CompletionInputBar';

import '../common/Chat/Message.css';

import { isString } from 'lodash';

import Button from '../common/Button';
import { CodeBlock, CodeBlockContent } from '../common/CodeBlock';

const ModelCompletion = ({
  isVisionLanguage,
  imageURL,
  onImageChange,
  defaultImage,
}: {
  isVisionLanguage: boolean;
  imageURL: string;
  onImageChange: any;
  defaultImage: string;
}) => {
  const value = useSelector(getChatValue);
  const { status } = useUser();
  const posthog = usePostHog();
  const { modelId } = useParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const isBase = model?.subType === 'base';
  const isAuthenticated = status !== UserStatus.LoggedOut;
  const aiResponse = useSelector(getAIResponse);
  const chatMessages = useSelector(getMessages);
  const modelName = model?.model;
  const { handleAIResponse, isLoading } = useAIResponse({
    model,
    chatMessages,
    modelName,
  });
  const handleChatValue = (value: string) => dispatch(setChatValue(value));
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="-mx-10 flex-1 lg:overflow-y-scroll">
      {chatMessages.length > 0 && (
        <div className="mt-[14px] flex flex-col gap-3 px-10">
          {chatMessages.map((message) => (
            <div
              key={message.id}
              className={twClassnames('flex gap-4 text-sm text-black', {
                'font-medium text-theme-neutral-600': message.from === 'ai',
              })}
            >
              <Markdown
                className="message-markdown flex flex-1 flex-col gap-3 break-words leading-6"
                components={{
                  code(props) {
                    const { children, className, node, ...rest } = props;
                    const newLines = children?.toString().split('\n')?.length || 0;
                    const inline = newLines <= 1;
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline ? (
                      <CodeBlock value={String(children).replace(/\n$/, '')}>
                        <CodeBlockContent language={match?.[1] || 'python'} />
                      </CodeBlock>
                    ) : (
                      <code
                        className={twClassnames('inline-code', className)}
                        {...rest}
                      >
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {message.text}
              </Markdown>
              <div className="w-9" />
            </div>
          ))}
          {aiResponse.length > 0 && (
            <div
              key="ai-response"
              className="flex items-start gap-4 text-sm font-medium text-black text-theme-neutral-600"
            >
              <Markdown
                className="message-markdown flex flex-1 flex-col gap-3 break-words leading-6"
                components={{
                  code(props) {
                    const { children, className, ...rest } = props;
                    const newLines = children?.toString().split('\n')?.length || 0;
                    const inline = newLines <= 1;
                    const match = /language-(\w+)/.exec(className || '');
                    if (!isString(children)) {
                      throw new Error('CodeBlock value must be a string.');
                    }
                    return !inline ? (
                      <CodeBlock value={children}>
                        <CodeBlockContent language={match?.[1] || 'python'} />
                      </CodeBlock>
                    ) : (
                      <code
                        className={twClassnames('inline-code', className)}
                        {...rest}
                      >
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {aiResponse.join('')}
              </Markdown>
              <Button
                variant="link"
                type="button"
                className="duration-400 group flex items-center justify-center rounded-full bg-theme-neutral-200 p-3 hover:bg-theme-primary-600 hover:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(cancelAIResponse());
                }}
              >
                <div className="h-3 w-3 rounded-sm bg-theme-primary-600 group-hover:bg-white" />
              </Button>
            </div>
          )}
        </div>
      )}
      {!isLoading && (
        <CompletionInputBar
          value={value}
          onChange={handleChatValue}
          disabled={isLoading || aiResponse.length > 0}
          imageURL={imageURL}
          allowImageUpload={isVisionLanguage}
          onImageChange={onImageChange}
          defaultImage={defaultImage}
          onSubmit={(defaultText?: string) => {
            if (!isAuthenticated) return;
            const text = value || defaultText;
            const message = {
              text,
              created: Date.now(),
              id: uuidV4(),
              ...(isBase && { reset: true }),
            };
            const image = imageURL || defaultImage;
            posthog?.capture('Generate Text Clicked', {
              model: modelId,
            });
            dispatch(addMessage(message));
            handleAIResponse(text || '', isVisionLanguage ? image : undefined);
            handleChatValue('');
          }}
        />
      )}
    </div>
  );
};

export default ModelCompletion;

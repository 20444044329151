import { forwardRef, ReactNode, TextareaHTMLAttributes } from 'react';

import twClassnames from '../../utils/classnames';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  additionalContainer?: ReactNode;
  innerContainerClassName?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      containerClassName,
      startIcon,
      additionalContainer,
      endIcon,
      innerContainerClassName,
      disabled,
      ...otherProps
    },
    ref,
  ) => (
    <div
      className={twClassnames(
        'relative flex rounded-xl border border-theme-primary-200 bg-white py-2 text-sm text-theme-neutral-700 transition placeholder:font-light placeholder:text-theme-neutral-400 hover:border-theme-primary-600 disabled:border-theme-neutral-400 disabled:text-theme-neutral-400 has-[:focus]:border-theme-primary-600',
        { '!border-theme-neutral-400 bg-theme-neutral-200': disabled },
        containerClassName,
      )}
    >
      {startIcon && <div className="ml-3 flex flex-col justify-end py-2">{startIcon}</div>}
      <div className={twClassnames('flex flex-1 flex-col px-4', innerContainerClassName)}>
        {additionalContainer}
        <textarea
          ref={ref}
          className={twClassnames('w-full py-2 outline-none', className)}
          disabled={disabled}
          {...otherProps}
        />
      </div>
      {endIcon && <div className="flex flex-col justify-end">{endIcon}</div>}
    </div>
  ),
);

export default TextArea;

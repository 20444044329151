import { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useDispatch } from 'react-redux';

import { disconnectWalletAddress } from '../../slices/settings';
import { AppDispatch } from '../../store';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';

const ConfirmDisconnectWalletAddressModal = ({ onClose, address }: { onClose: () => void; address: string }) => {
  const posthog = usePostHog();
  const [error, setError] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const handleDisconnect = async () => {
    try {
      posthog?.capture('Disconnect Wallet Address Attempted');
      await dispatch(disconnectWalletAddress(address)).unwrap();
      onClose();
      posthog?.capture('Disconnect Wallet Address Succeeded');
    } catch (e: any) {
      setError(e.message);
      posthog?.capture('Disconnect Wallet Address Failed');
    }
  };
  return (
    <div className="flex flex-col gap-5 px-2 py-2 lg:px-4">
      <ModalClose onClose={onClose} />
      <div className="flex flex-wrap items-center text-lg font-semibold text-theme-neutral-700">
        Are you sure you want to disconnect your wallet address?
      </div>
      <div className="text-sm">
        Any transfer from this wallet will not be tracked by our services and added to your credits.
      </div>
      {!!error && <div className="text-theme-danger-600">{error}</div>}
      <div className="flex gap-5">
        <Button
          className="flex-1"
          variant="neutral-outline"
          onClick={onClose}
        >
          No, cancel
        </Button>
        <Button
          className="flex-1"
          variant="danger"
          onClick={handleDisconnect}
        >
          Yes, disconnect
        </Button>
      </div>
    </div>
  );
};

export default ConfirmDisconnectWalletAddressModal;

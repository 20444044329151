import { useCallback } from 'react';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { changeTextParameter, getModel, getTextInputParameters, updateTextErrors } from '../../slices/models';
import { AppDispatch, RootState } from '../../store';
import { TextInputParameters } from '../../utils/types';
import InputSlider from '../common/InputSlider';
import TextAreaContainer from '../common/TextAreaContainer';

const TextInputParametersForm = () => {
  const { modelId } = useParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const dispatch = useDispatch<AppDispatch>();
  const inputParameters = useSelector(getTextInputParameters);
  const maxTokens = model?.maxTokens || 2048;

  const validate = useCallback(
    (values: TextInputParameters<number>) => {
      const errors: any = {};
      if (isNil(values.maxTokens)) {
        errors.maxTokens = 'Max tokens is required.';
      } else if (values.maxTokens > maxTokens) {
        errors.maxTokens = `This model only supports a max number of ${maxTokens} tokens.`;
      } else if (values.maxTokens < 1) {
        errors.maxTokens = 'Max tokens must be greater than 0.';
      }

      if (isNil(values.temperature)) {
        errors.temperature = 'Temperature is required.';
      } else if (values.temperature > 2) {
        errors.temperature = 'Temperature must not exceed 2.';
      } else if (values.temperature < 0) {
        errors.temperature = 'Temperature must be less than 0.';
      }

      if (isNil(values.topP)) {
        errors.topP = 'Top P is required.';
      } else if (values.topP > 1) {
        errors.topP = 'Top P must not exceed 1';
      } else if (values.topP <= 0) {
        errors.topP = 'Top P must be greater than 0.';
      }

      return errors;
    },
    [maxTokens],
  );
  const handleChange = useCallback(
    (name: string) => (value: number | string) => {
      dispatch(changeTextParameter({ id: name, value }));
      const newErrors = validate({ ...inputParameters.values, [name]: value });
      dispatch(updateTextErrors(newErrors));
    },
    [inputParameters.values, validate, dispatch],
  );
  return (
    <div>
      <InputSlider
        id="maxTokens-slider"
        label="Max Tokens"
        name="maxTokens"
        min={1}
        max={maxTokens}
        step={1}
        defaultValue={2048}
        value={inputParameters.values.maxTokens}
        error={inputParameters.errors.maxTokens}
        onChange={handleChange('maxTokens')}
        infoTooltip={
          <span>
            The maximum number of tokens
            <br />
            that will be generated
            <br />
            for each user prompt.
          </span>
        }
      />
      <InputSlider
        id="temperature-slider"
        label="Temperature"
        name="temperature"
        min={0}
        max={2}
        step={0.01}
        defaultValue={0.1}
        value={inputParameters.values.temperature}
        error={inputParameters.errors.temperature}
        onChange={handleChange('temperature')}
        infoTooltip={
          <span>
            Temperature controls randomness,
            <br />
            higher values increase diversity.
          </span>
        }
      />
      <InputSlider
        id="topP-slider"
        label="Top P"
        name="topP"
        min={0.01}
        max={1}
        step={0.01}
        defaultValue={0.9}
        value={inputParameters.values.topP}
        error={inputParameters.errors.topP}
        onChange={handleChange('topP')}
        infoTooltip={
          <span>
            A higher value will result in more
            <br />
            diverse outputs, while a lower
            <br />
            value will result in more repetitive outputs.
          </span>
        }
      />
      {!model?.hiddenInputs?.includes('systemPrompt') && (
        <TextAreaContainer
          label="System Prompt"
          name="systemPrompt"
          placeholder="Describe your system prompt here"
          className="min-h-28"
          maxLength={1000}
          value={inputParameters.values.systemPrompt}
          error={inputParameters.errors.systemPrompt}
          onChange={(e) => handleChange('systemPrompt')(e.target.value)}
          infoTooltip="Set of instructions, guidelines, and contextual informations, which tell the AI how to respond to the queries"
        />
      )}
    </div>
  );
};

export default TextInputParametersForm;

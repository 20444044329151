import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from '@firebase/firestore/lite';
import { format } from 'date-fns';

import { backendHost } from '../utils/constants';
import hyperFetch from '../utils/fetch';
import { db } from '../utils/firebase';
import { gpuTypes, interconnectNetworks } from '../utils/instances';
import { convertKeysToSnake } from '../utils/models';
import { Machine } from '../utils/types';

const portalId = '44996104';
const formGuid = '18c78cd1-6e2a-412b-a968-6aba31ab83a5';

const formatMachineFromResponse = (machineResponse: any) => {
  return {
    ...machineResponse,
    ...(machineResponse.startDate && {
      startDate: machineResponse.startDate.toDate(),
    }),
    ...(machineResponse.endDate && {
      endDate: machineResponse.endDate.toDate(),
    }),
  };
};

export const createMachineDb = async (machine: any) => {
  const collRef = collection(db, 'machines');
  const newMachineRef = doc(collRef);
  const newMachine = {
    ...machine,
    id: newMachineRef.id,
    created: Date.now(),
  };
  await setDoc(newMachineRef, newMachine);
  return newMachine;
};

export const fetchMachinesDb = async () => {
  const collRef = collection(db, 'machines');
  const collSnap = await getDocs(collRef);

  const arr: Machine[] = [];
  collSnap.forEach((doc) => {
    arr.push(formatMachineFromResponse(doc.data()) as Machine);
  });
  return arr;
};

export const fetchSingleMachineDb = async (machineId: string) => {
  const docRef = doc(db, 'machines', machineId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return formatMachineFromResponse(docSnap.data());
  } else {
    throw Error('machine does not exist');
  }
};

export const deleteMachineDb = async (machineId: string) => {
  const docRef = doc(db, 'machines', machineId);
  await deleteDoc(docRef);
  return machineId;
};

export const createMachineRequestDb = async (machineReq: any) => {
  const collRef = collection(db, 'machineRequests');
  const newMachineReqRef = doc(collRef);
  const newMachineReq = {
    ...machineReq,
    id: newMachineReqRef.id,
    created: Date.now(),
  };
  await setDoc(newMachineReqRef, newMachineReq);
  return newMachineReq;
};

export const fetchMachineRequestsDb = async () => {
  const collRef = collection(db, 'machineRequests');
  const collSnap = await getDocs(collRef);

  const arr: any[] = [];
  collSnap.forEach((doc) => {
    arr.push(doc.data());
  });
  return arr;
};

export const deleteMachineRequestDb = async (machineReqId: string) => {
  const docRef = doc(db, 'machineRequests', machineReqId);
  await deleteDoc(docRef);
  return machineReqId;
};

export const createContactDb = async (contactReq: any) => {
  const collRef = collection(db, 'contactRequests');
  const newContactReqRef = doc(collRef);
  const newContactReq = {
    ...contactReq,
    id: newContactReqRef.id,
    created: Date.now(),
  };
  await setDoc(newContactReqRef, newContactReq);
  return newContactReq;
};

export const contactSupplier = async (reqBody: {
  message: string;
  supplierId: string;
  email?: string;
  gpuDetails: string;
}) => {
  const snakeReq = convertKeysToSnake(reqBody);
  await hyperFetch(`${backendHost}/v1/marketplace/contact`, {
    method: 'POST',
    body: JSON.stringify(snakeReq),
  });
};

const mapKeyToHubspotKey: { [key: string]: string } = {
  email: 'email',
  name: 'company',
  phone: 'phone',
  gpuModel: 'gpu_model_',
  gpuCount: 'number_of_gpus',
  interconnectNetwork: 'interconnect_network',
  ram: 'node_ram__gb_',
  cpuCores: 'cpu_model',
  storageCapacity: 'node_storage_capacity__gb_',
  clusterInterface: 'cluster_interface__k8s__slurm__vm_etc_',
  networkBandwidth: 'network_bandwidth',
  operatingSystem: 'operating_system__os_',
  price: 'price___gpu___hour',
  location: 'location_of_the_machine__country_level_is_sufficient_',
  startDate: 'start_date_',
  endDate: 'end_date_',
  additionalDetails: 'anything_else_you_d_like_to_let_us_know_',
  minDuration: 'min_duration',
};

const convertKeysToHubspot = (data: any) => {
  const fields = data.fields.map((field: { key: string; value: string | number }) => {
    const newKey = mapKeyToHubspotKey[field.key] || '';
    let newValue = field.value;
    if (field.key === 'gpuModel') {
      newValue = gpuTypes.find((type) => type.value === field.value)?.label || newValue;
    } else if (field.key === 'interconnectNetwork') {
      newValue = interconnectNetworks.find((net) => net.value === field.value)?.label || newValue;
    } else if (field.key === 'startDate') {
      newValue = format(field.value, 'MM/dd/yyyy');
    } else if (field.key === 'endDate') {
      newValue = format(field.value, 'MM/dd/yyyy');
    }
    return {
      name: newKey,
      value: newValue,
    };
  });
  return {
    ...data,
    fields,
  };
};

export const submitHubspotForm = async (data: any) => {
  const adjustedData = convertKeysToHubspot(data);
  await hyperFetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, {
    method: 'POST',
    body: JSON.stringify(adjustedData),
  });
};

const mapKeyToWaitlistKey: { [key: string]: string } = {
  email: 'email',
  name: 'full_name',
  company: 'company',
  phone: 'phone',
  gpuModel: 'gpu_model_type',
  gpuCount: 'gpu_count',
  mode: 'form_type',
};

const waitlistFormGuid = 'ea244fc1-1f95-4078-965b-0ce587fe521b';

const convertKeysToWaitlist = (data: any) => {
  const fields = data.fields.map((field: { key: string; value: string | number }) => {
    const newKey = mapKeyToWaitlistKey[field.key] || '';
    return {
      name: newKey,
      value: field.value,
    };
  });
  return {
    ...data,
    fields,
  };
};

export const submitHubspotWaitlist = async (data: any) => {
  const adjustedData = convertKeysToWaitlist(data);
  await hyperFetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${waitlistFormGuid}`, {
    method: 'POST',
    body: JSON.stringify(adjustedData),
  });
};

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  getAudioInputParameters,
  getImageInputParameters,
  getModel,
  getTextInputParameters,
} from '../../slices/models';
import { RootState } from '../../store';
import { defaultImagePrompt } from '../../utils/constants';
import { findModelName } from '../../utils/models';
import { AudioInputParameters, ImageInputParameters, TextInputParameters } from '../../utils/types';
import Switch from '../common/Switch';
import { Tab, TabList, TabPanel, Tabs } from '../common/Tabs';
import ModelCodeSnippet from './ModelCodeSnippet';

const languages = ['python', 'ts', 'curl', 'gradio'];

const defaultTextParams: TextInputParameters<number> = {
  systemPrompt: '',
  maxTokens: 2048,
  temperature: 0.7,
  topP: 0.9,
};

const defaultImageParams: ImageInputParameters<number, boolean> = {
  // prompt: 'Kanye west riding a horse on Mars',
  prompt: defaultImagePrompt,
  negativePrompt: '',
  steps: 30,
  cfgScale: 5,
  enableRefiner: false,
  height: 1024,
  width: 1024,
};

const defaultAudioParams: AudioInputParameters<number> = {
  // text: 'Los Angeles! The City of Angels is a treasure trove of exciting experiences, iconic landmarks, and endless entertainment options.',
  speed: 1,
};

const ModelApi = () => {
  const navigate = useNavigate();
  const { modelId, lang } = useParams();
  const [searchParams] = useSearchParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const isVLM = model?.type === 'vlm';
  const isLLM = model?.type === 'llm' && !model?.subType;
  const [liveUpdates, setLiveUpdates] = useState<boolean>(!isVLM);
  const currentVariant = searchParams.get('variant');
  const modelName = findModelName(model, currentVariant);
  const languageIndex = Math.max(
    languages.findIndex((l) => l === lang),
    0,
  );
  const textInputParameters = useSelector(getTextInputParameters);
  const imageInputParameters = useSelector(getImageInputParameters);
  const audioInputParameters = useSelector(getAudioInputParameters);
  const textInputParams = liveUpdates ? textInputParameters.values : defaultTextParams;
  const imageInputParams = liveUpdates ? imageInputParameters.values : defaultImageParams;
  const audioInputParams = liveUpdates ? audioInputParameters.values : defaultAudioParams;

  return (
    <Tabs
      className="react-tabs lg:overflow-y-hidden"
      onSelect={(index: number) =>
        navigate(`/models/${model?.slug}/api/${languages[index]}?${searchParams.toString()}`)
      }
      selectedIndex={languageIndex}
    >
      <TabList>
        <Tab>Python</Tab>
        <Tab>TypeScript</Tab>
        {!isVLM && <Tab>cURL</Tab>}
        {isLLM && <Tab>Gradio</Tab>}
        <div className="hidden flex-1 items-center justify-end md:flex">
          {model?.type !== 'vlm' && (
            <>
              <div className="mr-2">Live adjustments</div>
              <Switch
                selected={liveUpdates}
                onSelect={() => setLiveUpdates(!liveUpdates)}
              />
            </>
          )}
        </div>
      </TabList>

      <div className="mt-3 flex flex-1 items-center md:hidden">
        <div className="mr-2">Live adjustments</div>
        <Switch
          selected={liveUpdates}
          onSelect={() => setLiveUpdates(!liveUpdates)}
        />
      </div>

      <TabPanel className="react-tabs__tab-panel lg:overflow-y-auto">
        <ModelCodeSnippet
          id="python-code"
          model={model}
          language="python"
          textInputParameters={textInputParams}
          imageInputParameters={imageInputParams}
          audioInputParameters={audioInputParams}
          type={model?.type}
          liveUpdates={liveUpdates}
          modelName={modelName}
        />
      </TabPanel>
      <TabPanel className="react-tabs__tab-panel lg:overflow-y-auto">
        <ModelCodeSnippet
          id="ts-code"
          model={model}
          language="ts"
          textInputParameters={textInputParams}
          imageInputParameters={imageInputParams}
          audioInputParameters={audioInputParams}
          type={model?.type}
          liveUpdates={liveUpdates}
          modelName={modelName}
        />
      </TabPanel>
      {!isVLM && (
        <TabPanel className="react-tabs__tab-panel lg:overflow-y-auto">
          <ModelCodeSnippet
            id="curl-code"
            model={model}
            language="curl"
            textInputParameters={textInputParams}
            imageInputParameters={imageInputParams}
            audioInputParameters={audioInputParams}
            type={model?.type}
            liveUpdates={liveUpdates}
            modelName={modelName}
          />
        </TabPanel>
      )}
      {isLLM && (
        <TabPanel className="react-tabs__tab-panel lg:overflow-y-auto">
          <ModelCodeSnippet
            id="gradio-code"
            model={model}
            language="python"
            textInputParameters={textInputParams}
            imageInputParameters={imageInputParams}
            audioInputParameters={audioInputParams}
            type={model?.type}
            liveUpdates={liveUpdates}
            modelName={modelName}
            isGradio
          />
        </TabPanel>
      )}
    </Tabs>
  );
};

export default ModelApi;

import { useState } from 'react';
import { sendPasswordResetEmail } from '@firebase/auth';
import { useForm } from 'react-hook-form';

import { auth } from '../../utils/firebase';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import FormInput from '../FormInput';

type ForgotPasswordInputs = {
  email: string;
};

const ForgotPasswordModal = ({ onClose }: { onClose: () => void }) => {
  const { control, handleSubmit } = useForm<ForgotPasswordInputs>();
  const [status, setStatus] = useState('unsent');
  const onSubmit = async (data: ForgotPasswordInputs) => {
    setStatus('loading');
    await sendPasswordResetEmail(auth, data.email);
    setStatus('sent');
  };
  return (
    <div>
      <ModalClose onClose={onClose} />
      <h2>Find your account</h2>
      {status === 'sent' ? (
        <div>
          An email has been sent to your account. Please take a look at your email account and use the link provided to
          reset your password. If you don't see the email after a couple minutes, please check your spam folder to make
          sure the email was not directed there.
        </div>
      ) : (
        <>
          <div>
            Please enter your email associated with your account and we'll send you a link to reset your password.
          </div>
          <form
            className="my-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              type="email"
              name="email"
              label="Email"
              labelClassName="text-theme-neutral-700 font-medium"
              defaultValue=""
              placeholder="E.g. johndoe@gmail.com"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              control={control}
              rules={{ required: true }}
              hideAsterick
            />
            <Button
              className="flex w-full items-center justify-center"
              type="submit"
              isLoading={status === 'loading'}
            >
              Recover Password
            </Button>
          </form>
        </>
      )}
    </div>
  );
};

export default ForgotPasswordModal;

import { differenceInMinutes, intervalToDuration } from 'date-fns';

import { HOUR } from './constants';
import { displayDuration } from './instances';

const getEstimatedEndDate = ({
  start,
  ratePerHour,
  credits,
}: {
  start?: Date;
  ratePerHour: number;
  credits: number;
}) => {
  const hoursRemaining = credits / ratePerHour;
  const secondsRemaining = hoursRemaining * HOUR;
  const startDate = start || new Date();
  const endDate = new Date(startDate.getTime() + secondsRemaining);
  return endDate;
};

export const displayRuntime = (ratePerHour: number, credits: number) => {
  const start = new Date();
  const end = getEstimatedEndDate({ start, ratePerHour, credits });
  return displayDuration(intervalToDuration({ start, end }));
};

export const getMinutesRemaining = (ratePerHour: number, credits: number) => {
  const start = new Date();
  const end = getEstimatedEndDate({ start, ratePerHour, credits });
  return differenceInMinutes(end, start);
};

import { useSelector } from 'react-redux';

import { getAudioStatus, getSortedAudioHistory } from '../../../slices/models';
import twClassnames from '../../../utils/classnames';
import { ResponseStatus } from '../../../utils/types';
import WeirdCircleIcon from '../../common/icons/WeirdCircleIcon';
import GeneratedAudioSet from './GeneratedAudioSet';

const AudioHistory = () => {
  const audioHistory = useSelector(getSortedAudioHistory);
  const audioStatus = useSelector(getAudioStatus);
  const isLoading = audioStatus === ResponseStatus.Loading;
  const noResults = audioHistory.length === 0 && !isLoading;
  return (
    <div
      className={twClassnames('flex flex-1 flex-col px-10', {
        'flex-col divide-y divide-theme-neutral-300 lg:overflow-y-auto': !noResults,
        'items-center justify-center': noResults,
      })}
    >
      {noResults ? (
        <>
          <WeirdCircleIcon
            width={160}
            height={145.6}
            className="mb-6"
          />
          <div className="mb-4 text-lg font-medium text-theme-neutral-600">No audio generated yet</div>
          <div className="w-[350px] text-center text-sm text-theme-neutral-500">
            Start by typing some narrative in the text field below
          </div>
        </>
      ) : (
        <>
          {isLoading && (
            <div className="pb-4 pt-4 first:pt-0">
              <div className="animate-pulse">
                <div className="flex flex-col gap-3.5">
                  <div className="h-7 w-1/2 rounded bg-theme-neutral-200 lg:w-1/4" />
                  <div className="h-[52px] w-full rounded bg-theme-neutral-200 lg:w-1/2" />
                  <div className="h-7 w-1/3 rounded bg-theme-neutral-200 lg:w-1/6" />
                  <div className="h-28 w-2/3 rounded bg-theme-neutral-200 lg:w-1/3" />
                  <div className="h-4 w-1/2 rounded bg-theme-neutral-200 lg:w-1/4" />
                </div>
              </div>
            </div>
          )}
          {audioHistory.map((audio, i) => (
            <GeneratedAudioSet
              key={`audio-${audio.created.toString()}`}
              audio={audio}
              position={audioHistory.length - i}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default AudioHistory;

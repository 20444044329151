import { HTMLAttributes } from 'react';

import twClassnames from '../../utils/classnames';

const Layout = ({ className, ...otherProps }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twClassnames('flex w-full flex-col px-6 lg:px-16', className)}
    {...otherProps}
  />
);

export default Layout;

import { createContext, forwardRef, HTMLAttributes, useContext, useId, useMemo } from 'react';
import { Prism as SyntaxHighlighterBase, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import { oneLight as oneLightBase } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Except } from 'type-fest';

import twClassnames from '../../utils/classnames';
import CopyButton, { CopyButtonProps } from './CopyButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- remove weird margin from oneLight styles
const oneLight = {
  ...oneLightBase,
  'pre[class*="language-"]': {
    ...oneLightBase['pre[class*="language-"]'],
    margin: undefined,
  },
};

type CodeBlockContextValue = {
  value: string;
};

const CodeBlockContext = createContext<CodeBlockContextValue | null>(null);

const useCodeBlockContext = () => {
  const context = useContext(CodeBlockContext);
  if (!context) {
    throw new Error('useCodeBlockContext must be used within a CodeBlock');
  }
  return context;
};

export const CodeBlock = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & CodeBlockContextValue>(
  ({ value, className, ...props }, ref) => {
    return (
      <CodeBlockContext.Provider value={{ value }}>
        <div
          ref={ref}
          className={twClassnames('group/code-block relative', className)}
          {...props}
        />
      </CodeBlockContext.Provider>
    );
  },
);

export const CodeBlockActions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={twClassnames(
          'absolute right-1 top-1 flex items-center gap-2 opacity-0 transition-opacity group-hover/code-block:opacity-100',
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export const CodeBlockCopyButton = (props: Except<CopyButtonProps, 'id' | 'copyText'>) => {
  const { value } = useCodeBlockContext();
  const id = useId();

  return (
    <CopyButton
      variant="neutral"
      copyText={value}
      id={id}
      {...props}
    />
  );
};

export const CodeBlockContent = ({
  className,
  ...props
}: Except<SyntaxHighlighterProps, 'children'> & { className?: string }) => {
  const { value } = useCodeBlockContext();
  const formattedContent = useMemo(() => value.replace(/\n$/, ''), [value]);

  return (
    <SyntaxHighlighterBase
      PreTag="div"
      style={oneLight}
      className={twClassnames('rounded-lg border text-sm', className)}
      wrapLines
      wrapLongLines
      {...props}
    >
      {formattedContent}
    </SyntaxHighlighterBase>
  );
};

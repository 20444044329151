import { ReactNode } from 'react';

import twClassnames from '../../utils/classnames';
import HyperLink from '../common/HyperLink';

const PillLink = ({
  href,
  text,
  icon,
  iconContainerClassName = '',
}: {
  href: string;
  text: string;
  icon: ReactNode;
  iconContainerClassName?: string;
}) => (
  <div className="flex">
    <HyperLink
      href={href}
      className="flex items-center rounded-full pl-2 pr-4 text-theme-neutral-700 hover:text-theme-neutral-700"
      variant="ghost"
      rel="noreferrer noopener"
      target="_blank"
    >
      <div className={twClassnames('px-1', iconContainerClassName)}>{icon}</div>
      <div className="ml-4">{text}</div>
    </HyperLink>
  </div>
);

export default PillLink;

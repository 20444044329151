import { ReactNode, useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import twClassnames from '../../utils/classnames';
import { displayPricePeriod } from '../../utils/instances';
import { InstanceStatus } from '../../utils/types';
import { formatCurrency } from '../../utils/value';
import Badge from '../common/Badge';
import Card from '../common/Card';
import LoadingLine from '../common/LoadingLine';
import LoadingSpinner from '../common/LoadingSpinner';

const defaultStatusConfig = {
  pillLabel: '',
  pillStyle: '',
  bubbleStyle: '',
  stripeStyle: '',
  backgroundStyle: '',
};

const instanceStatusConfig = {
  [InstanceStatus.unknown]: {
    pillLabel: 'Offline',
    pillStyle: 'bg-white border border-theme-neutral-200 text-theme-neutral-600',
    bubbleStyle: 'bg-theme-neutral-100 text-theme-neutral-600',
    stripeStyle: 'bg-theme-neutral-500',
    backgroundStyle: 'bg-theme-neutral-50',
  },
  [InstanceStatus.online]: {
    pillLabel: 'Ready to Connect',
    pillStyle: 'bg-white border border-theme-success-700 text-theme-success-700',
    bubbleStyle: 'bg-theme-success-100 text-theme-success-700',
    stripeStyle: 'bg-theme-success-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.offline]: {
    pillLabel: 'Ended',
    pillStyle: 'bg-white border border-theme-neutral-200 text-theme-neutral-600',
    bubbleStyle: 'bg-theme-neutral-100 text-theme-neutral-600',
    stripeStyle: 'bg-theme-neutral-300',
    backgroundStyle: 'bg-theme-neutral-50',
  },
  [InstanceStatus.starting]: {
    pillLabel: 'Starting...',
    pillStyle: 'bg-theme-primary-100 text-theme-primary-600',
    bubbleStyle: '',
    stripeStyle: 'bg-theme-primary-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.node_ready]: {
    pillLabel: 'Starting...',
    pillStyle: 'bg-theme-primary-100 text-theme-primary-600',
    bubbleStyle: '',
    stripeStyle: 'bg-theme-primary-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.stopping]: {
    pillLabel: 'Stopping...',
    pillStyle: '',
    bubbleStyle: 'bg-theme-danger-100 text-theme-danger-600',
    stripeStyle: 'bg-theme-danger-500',
    backgroundStyle: 'bg-white',
  },
  [InstanceStatus.busy]: {
    pillLabel: 'Busy...',
    pillStyle: '',
    bubbleStyle: '',
    stripeStyle: '',
    backgroundStyle: '',
  },
};

const InstanceCard = ({
  id,
  gpuCount,
  gpuModelName,
  gpuRamGB,
  storageCapacity,
  cpuCores,
  ramGB,
  location,
  upload,
  download,
  // maxDays,
  // endOfYear,
  gpuPrice,
  pricePeriod,
  expansion,
  actionButton,
  status,
  onClick,
  instanceImageURL,
  handleQuantityClicked,
  loading,
}: {
  id: string;
  gpuCount: number;
  gpuModelName: string;
  gpuRamGB: number;
  storageCapacity: string;
  cpuCores?: number;
  ramGB?: string;
  location?: string;
  upload?: number;
  download?: number;
  maxDays?: number;
  endOfYear: Date;
  gpuPrice: number;
  pricePeriod?: string;
  expansion?: ReactNode;
  actionButton?: ReactNode;
  status?: InstanceStatus;
  onClick?: any;
  instanceImageURL?: string;
  handleQuantityClicked?: any;
  loading?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const statusConfig = status ? instanceStatusConfig[status] : defaultStatusConfig;

  return (
    <Card
      key={id}
      className={twClassnames(
        'relative flex flex-col overflow-clip pb-5 text-theme-neutral-700 transition-all',
        {
          'h-[100px]': expansion,
          'h-[168px] shadow-theme-01': expanded,
        },
        statusConfig.backgroundStyle,
      )}
      noHover={!(expansion || onClick)}
      onClick={onClick ? onClick : () => expansion && setExpanded(!expanded)}
    >
      {status && (
        <div className="absolute left-0 top-0 h-full">
          <div className={twClassnames('h-full w-1 rounded-full', statusConfig.stripeStyle)} />
        </div>
      )}
      <div
        className={twClassnames('flex gap-5', {
          'border-b border-theme-primary-100 pb-4': status && expansion,
        })}
      >
        {/* quantity */}
        <div
          className={twClassnames(
            'ml-2 mr-11 hidden items-center sm:flex',
            { 'ml-0 mr-2': instanceImageURL },
            {
              'cursor-pointer': handleQuantityClicked && !instanceImageURL && !loading,
            },
          )}
          onClick={handleQuantityClicked && !instanceImageURL && !loading ? handleQuantityClicked : () => {}}
        >
          <div className="relative">
            {instanceImageURL ? (
              <img
                src={`data:image/png;base64,${instanceImageURL}`}
                className="max-w-24 rounded-lg"
              />
            ) : (
              <div
                className={twClassnames(
                  'flex h-14 w-14 items-center justify-center rounded-full bg-theme-primary-100 text-lg font-bold text-theme-primary-600 transition',
                  { [statusConfig.bubbleStyle]: status },
                  {
                    'hover:bg-theme-neutral-500 hover:text-white': handleQuantityClicked && !loading,
                  },
                )}
              >
                {loading ? (
                  <LoadingSpinner
                    size={24}
                    className="fill-theme-primary-600 text-theme-primary-300"
                  />
                ) : (
                  `${gpuCount} X`
                )}
              </div>
            )}
          </div>
        </div>

        {/* gpu type */}
        <div className="flex flex-1 flex-col items-start gap-1">
          <div className="font-semibold text-theme-primary-600">
            {!!instanceImageURL && `${gpuCount} x `}
            {gpuModelName}
          </div>
          <div className="text-xs text-theme-neutral-600">GPU RAM: {gpuRamGB}GB</div>
          {status && (
            <Badge
              text={statusConfig.pillLabel}
              className={twClassnames('rounded-[4px] px-1.5 py-0 text-xs font-light', {
                'border border-theme-success-700 bg-white text-theme-success-700': status === InstanceStatus.online,
                'border border-theme-neutral-200 bg-white text-theme-neutral-600': status === InstanceStatus.offline,
                'bg-theme-primary-100 text-theme-primary-600': status === InstanceStatus.starting,
                'border-theme-danger-600 bg-white text-theme-danger-600': status === InstanceStatus.stopping,
              })}
            />
          )}
        </div>

        {/* storage */}
        <div className="hidden flex-1 flex-col gap-1 text-theme-neutral-700 sm:flex">
          <div>{storageCapacity}</div>
          <div className="flex flex-col gap-1 whitespace-nowrap text-xs text-theme-neutral-600">
            {cpuCores && (
              <div className="flex max-w-32 gap-1">
                <div>CPU Cores:</div>
                <div>{cpuCores}</div>
              </div>
            )}
            {ramGB && (
              <div className="flex max-w-32 gap-1">
                <div>RAM:</div>
                <div>{ramGB}</div>
              </div>
            )}
          </div>
        </div>

        {/* location */}
        <div className="hidden flex-1 flex-col gap-1 text-theme-neutral-700 lg:flex">
          <div>{location}</div>
          <div className="flex flex-col whitespace-nowrap text-xs text-theme-neutral-600">
            {upload && (
              <div className="flex items-center">
                <FiArrowUp className="mr-2 inline text-theme-primary-600" />
                {upload} Mbps
              </div>
            )}
            {download && (
              <div className="flex items-center">
                <FiArrowDown className="mr-2 inline text-theme-primary-600" />
                {download} Mbps
              </div>
            )}
          </div>
        </div>

        {/* duration */}
        {/* <div className="flex-1 hidden lg:flex flex-col gap-1 text-theme-neutral-700">
          <div>Max: {maxDays}d</div>
          <div className="flex flex-col text-theme-neutral-600 text-xs">
            <div>Until ~ {format(endOfYear, 'MM/dd')}</div>
            <div>Min: none</div>
          </div>
        </div> */}

        {/* price */}
        <div className="flex flex-1 flex-col gap-1">
          <div className="font-medium text-theme-primary-600">
            {formatCurrency(gpuPrice)} / GPU / {displayPricePeriod(pricePeriod)}
          </div>
          {actionButton}
        </div>
      </div>

      {status === InstanceStatus.starting && <LoadingLine />}

      {expansion && (
        <div
          className={twClassnames(
            '-mx-5 -mb-4 mt-4 flex rounded-bl-xl rounded-br-xl bg-white px-5 py-4 pl-6 text-xs text-theme-neutral-600',
            { 'pb-4 pt-0': status },
          )}
        >
          {expansion}
        </div>
      )}
    </Card>
  );
};

export default InstanceCard;

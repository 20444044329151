import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FaCircleCheck } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getUserInfo, updateUserInfo, UserRole } from '../../slices/auth';
import { openModal } from '../../slices/modals';
import { getCouponCodeError, getCouponCodeStatus, redeemCouponCode, resetCouponCode } from '../../slices/settings';
import { AppDispatch } from '../../store';
import { salesLink, SECOND } from '../../utils/constants';
import { mobileStyles } from '../../utils/modals';
import { ResponseStatus } from '../../utils/types';
import APIKey from '../common/APIKey';
import Button from '../common/Button';
import Card from '../common/Card';
import HyperLink from '../common/HyperLink';
import LockIcon from '../common/icons/LockIcon';
import ResetIcon from '../common/icons/ResetIcon';
import SaveIcon from '../common/icons/SaveIcon';
import Input from '../common/Input';
import LoadingSpinner from '../common/LoadingSpinner';
import PublicKeyDropzone from '../common/PublicKeyDropzone';
import { ModalName } from '../modals';

const CouponCode = () => {
  const dispatch = useDispatch<AppDispatch>();
  const status = useSelector(getCouponCodeStatus);
  const loading = status === ResponseStatus.Loading;
  const successful = status === ResponseStatus.Success;
  const error = useSelector(getCouponCodeError);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamValue = searchParams.get('couponcode');
  const [value, setValue] = useState(searchParamValue?.toUpperCase() || '');

  useEffect(() => {
    if (searchParamValue) {
      searchParams.delete('couponcode');
      setSearchParams(searchParams);
    }
  }, [searchParamValue]);

  const submit = useCallback(async () => {
    if (!loading) {
      await dispatch(redeemCouponCode(value));
    }
  }, [dispatch, loading, value]);

  const buttonDisabled = !value || loading;

  const handleOnChange = (value: string) => {
    // do not allow spaces or lowercase characters
    setValue(value.trim().toUpperCase());
  };

  useEffect(() => {
    return () => {
      dispatch(resetCouponCode());
    };
  }, [dispatch]);

  return (
    <div
      id="coupon-code"
      className="mb-8 flex max-w-lg flex-col pt-6"
    >
      <div className="mb-2 text-lg font-semibold text-theme-neutral-700">Coupon Code</div>
      <div className="mb-6 text-sm font-light">Have a coupon code or an event code? Redeem it here!</div>
      <div className="flex flex-col gap-5">
        <Input
          id="display-name-input"
          disabled={loading}
          value={value}
          containerClassName="flex-1 w-full max-w-lg"
          onChangeText={handleOnChange}
          endIcon={
            loading ? (
              <LoadingSpinner
                size={20}
                className="fill-theme-primary-600 text-theme-primary-300"
              />
            ) : undefined
          }
          endIconClassName="mt-2 right-3 flex items-center"
          error={!!error}
        />
        {error && <div className="-mt-3 text-theme-danger-600">{error}</div>}
        {successful && <div className="-mt-3 text-theme-primary-600">Coupon code was successfully redeemed</div>}
        <Button
          onClick={submit}
          disabled={buttonDisabled}
          className="max-w-xs"
        >
          {loading ? 'Submitting' : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

const General = () => {
  const userInfo = useSelector(getUserInfo);
  const [displayName, setDisplayName] = useState('');
  const [displayNameStatus, setDisplayNameStatus] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const [showCheckmark, setShowCheckmark] = useState(false);
  const displayNameLoading = displayNameStatus === 'loading';

  const inputValue = displayNameStatus ? displayName : userInfo?.name;

  const saveDisplayName = async () => {
    if (!displayNameError) {
      setDisplayNameStatus('loading');
      await dispatch(
        updateUserInfo({
          userId: userInfo?.id || '',
          update: { name: displayName },
        }),
      );
      setDisplayNameStatus('');
    }
  };

  const accountPlans = {
    [UserRole.User]: {
      tier: 'user',
      title: 'Basic Tier',
      description: (
        <>
          <div className="mb-1">
            Deposit funds to unlock&nbsp;
            <span className="font-semibold text-theme-primary-600">Pro Tier</span>
          </div>
          <ul className="list-inside list-disc">
            <li>
              Higher rate limit:&nbsp;
              <span className="font-semibold text-theme-primary-600">600 requests / min</span>
            </li>
            <li>Unlock GPU rentals</li>
          </ul>
        </>
      ),
      rateLimit: 60,
      actionButton: (
        <Button
          onClick={() =>
            dispatch(
              openModal({
                name: ModalName.AddFundsModal,
                props: { defaultAmount: 5 },
              }),
            )
          }
        >
          Upgrade Now
        </Button>
      ),
    },
    [UserRole.Pro]: {
      tier: 'pro',
      title: 'Pro Tier',
      description: (
        <>
          Want to scale and enjoy unlimited rate limits? Talk to us and get a&nbsp;
          <span className="font-semibold text-theme-primary-600">custom pricing</span>!
        </>
      ),
      rateLimit: 600,
      actionButton: (
        <HyperLink
          variant="primary"
          href={salesLink}
          target="_blank"
          rel="noreferrer"
        >
          Contact Us to Upgrade
        </HyperLink>
      ),
    },
    [UserRole.Elite]: {
      tier: 'elite',
      title: 'Enterprise Tier',
      description: 'Congrats! You’re on the highest tier plan - enjoy all the premium features! 🎊',
      rateLimit: 'Unlimited',
      actionButton: null,
    },
  };

  const currentPlan = userInfo?.role ? accountPlans[userInfo?.role] : accountPlans[UserRole.User];

  return (
    <div className="mb-6 flex flex-col gap-6 divide-y">
      <div className="mb-2 flex flex-col gap-4">
        <div className="text-lg font-semibold text-theme-neutral-700">Profile</div>
        <div className="flex flex-col">
          <div className="mb-3 w-28 text-sm font-medium text-theme-neutral-700">Display Name</div>
          <Input
            id="display-name-input"
            value={inputValue}
            containerClassName="flex-1 w-full max-w-lg"
            onChangeText={(text: string) => {
              setDisplayNameStatus('edited');
              if (!text) {
                setDisplayName('');
                setDisplayNameError('Display name cannot be empty');
              } else {
                setDisplayNameError('');
                setDisplayName(text);
              }
            }}
            endIcon={
              displayNameLoading ? (
                <LoadingSpinner
                  size={20}
                  className="fill-theme-primary-600 text-theme-primary-300"
                />
              ) : inputValue !== userInfo?.name ? (
                <SaveIcon
                  size={24}
                  className="cursor-pointer rounded-full bg-theme-primary-600 p-1 text-white"
                  onClick={saveDisplayName}
                />
              ) : undefined
            }
            endIconClassName="mt-2 right-3 flex items-center"
            error={!!displayNameError}
          />
          {displayNameError && <div className="mb-3 text-theme-danger-600">{displayNameError}</div>}
        </div>
        <div className="flex flex-col">
          <div className="mb-3 w-28 text-sm font-medium text-theme-neutral-700">Email</div>
          <Input
            id="email-input"
            value={userInfo?.email}
            containerClassName="flex-1 w-full max-w-lg"
            disabled
            endIcon={<LockIcon size={20} />}
            endIconClassName="mt-2 right-4 text-theme-neutral-400"
            tooltipText="You are not allowed to update your email"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 pt-6">
        <div className="text-lg font-semibold text-theme-neutral-700">Current Plan</div>
        <div className="flex max-w-96 flex-wrap gap-5">
          {currentPlan && (
            <Card
              noHover
              className="flex flex-col gap-3 border-theme-neutral-50 bg-theme-neutral-50"
            >
              <div className="text-lg font-semibold text-theme-neutral-700">{currentPlan.title}</div>
              <div className="font-semibold text-theme-primary-600">{currentPlan.rateLimit} requests / min</div>
              <div className="text-sm">{currentPlan.description}</div>
              <div>{currentPlan.actionButton}</div>
            </Card>
          )}
        </div>
      </div>
      {userInfo?.api_key && (
        <div
          id="api-key"
          className="flex flex-col pt-6"
        >
          <div className="mb-2 text-lg font-semibold text-theme-neutral-700">API Key</div>
          <div className="mb-6 text-sm font-light">
            API keys allow you to securely access Hyperbolic's services. Please protect your key because anyone who has
            the key has full access to your account.
          </div>
          <div className="w-full border-y border-theme-neutral-200">
            <APIKey
              // text="default"
              apiKey={userInfo?.api_key}
              containerClassName="bg-transparent border-0 px-0 py-5 max-w-lg"
              className="bg-transparent"
              innerClassName="w-96"
              textClassName="w-20"
              additionalButtons={
                <ResetIcon
                  className="cursor-pointer text-theme-neutral-600 transition hover:text-theme-primary-600"
                  size={19}
                  onClick={() =>
                    dispatch(
                      openModal({
                        name: ModalName.ResetApiKey,
                        styles: isMobile ? mobileStyles : { content: { maxWidth: 500 } },
                      }),
                    )
                  }
                />
              }
            />
          </div>
        </div>
      )}
      {userInfo && (
        <div
          id="public-key"
          className="mb-8 flex flex-col pt-6"
        >
          <div className="mb-2 text-lg font-semibold text-theme-neutral-700">SSH Public Key</div>
          <div className="mb-6 text-sm font-light">
            Your public key is required to SSH into instances, and only needs to be configured once. Wondering how to
            get your public key?&nbsp;
            <HyperLink
              to="/support/gpu-rental"
              className="text-theme-neutral-700 underline hover:text-theme-neutral-600"
              state={{ initialQuestion: 3 }}
            >
              Check support
            </HyperLink>
            .
          </div>
          {userInfo?.public_key ? (
            <div className="w-full border-y border-theme-neutral-200">
              <APIKey
                text="Public Key"
                apiKey={userInfo.public_key}
                expandPosition="below"
                containerClassName="bg-transparent border-0 px-0 py-5 max-w-lg"
                className="bg-transparent"
                innerClassName="w-96"
                textClassName="w-20"
                additionalButtons={
                  showCheckmark ? (
                    <FaCircleCheck
                      className="text-theme-primary-600"
                      size={20}
                    />
                  ) : (
                    <ResetIcon
                      className="cursor-pointer text-theme-neutral-600 transition hover:text-theme-primary-600"
                      size={19}
                      onClick={() =>
                        dispatch(
                          openModal({
                            name: ModalName.ResetPublicKey,
                            styles: isMobile ? mobileStyles : { content: { maxWidth: 500 } },
                          }),
                        )
                      }
                    />
                  )
                }
              />
            </div>
          ) : (
            <PublicKeyDropzone
              onUpload={() => {
                setShowCheckmark(true);
                setTimeout(() => setShowCheckmark(false), 5 * SECOND);
              }}
            />
          )}
        </div>
      )}
      <CouponCode />
      {/* <div className="flex flex-col gap-4 pt-6">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Delete User
        </div>
        <div className="text-sm">
          Once you delete your user, there is no way to retrieve your user data.
        </div>
        <div>
          <Button variant="danger-outline">Delete User</Button>
        </div>
      </div> */}
    </div>
  );
};

export default General;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeAudioParameter, getAudioInputParameters } from '../../slices/models';
import InputSlider from '../common/InputSlider';

const AudioInputParametersForm = () => {
  const dispatch = useDispatch();
  const audioInputParameters = useSelector(getAudioInputParameters);
  const handleChange = useCallback(
    (name: string) => (value: number | string) => {
      dispatch(changeAudioParameter({ id: name, value }));
    },
    [dispatch],
  );
  return (
    <div>
      <InputSlider
        id="speed-slider"
        label="Speed"
        name="speed"
        min={0}
        max={2}
        step={0.25}
        defaultValue={1}
        value={audioInputParameters.values.speed}
        error={audioInputParameters.errors.speed}
        onChange={handleChange('speed')}
        infoTooltip={<span>The speed of the audio file.</span>}
      />
    </div>
  );
};

export default AudioInputParametersForm;

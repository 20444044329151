import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { LuFileKey } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';

import { getPublicKeyError, getPublicKeyStatus, updatePublicKey } from '../../slices/auth';
import { AppDispatch } from '../../store';
import twClassnames from '../../utils/classnames';
import { SECOND } from '../../utils/constants';
import { ResponseStatus } from '../../utils/types';
import Button from './Button';
import FilePlusIcon from './icons/FilePlusIcon';
import SaveIcon from './icons/SaveIcon';
import UploadCloudOutlineIcon from './icons/UploadCloudOutlineIcon';
import LoadingSpinner from './LoadingSpinner';
import TextArea from './TextArea';

const PublicKeyDropzone = ({
  className,
  existingPublicKey,
  containerClassName,
  onUpload,
}: {
  className?: string;
  existingPublicKey?: string;
  containerClassName?: string;
  onUpload?: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const publicKeyStatus = useSelector(getPublicKeyStatus);
  const publicKeyLoading = publicKeyStatus === ResponseStatus.Loading;
  const publicKeyError = useSelector(getPublicKeyError);
  const [inputValue, setInputValue] = useState('');
  const [showTextOption, setShowTextOption] = useState(true);
  const [inputValidationError, setInputValidationError] = useState('');
  const [dropValidationError, setDropValidationError] = useState('');

  const [fileError, setFileError] = useState('');

  const updateUserPublicKey = useCallback(
    async (key: string) => {
      if (!publicKeyLoading) {
        await dispatch(updatePublicKey({ publicKey: key, delay: 2 * SECOND })).unwrap();
        onUpload?.();
      }
    },
    [dispatch, publicKeyLoading, onUpload],
  );
  const validateInput = (value: string) => {
    const setValidationError = showTextOption ? setInputValidationError : setDropValidationError;
    if (!value) {
      setValidationError('Please enter a valid public key');
      return false;
    }
    const validatedValue = value.trim();
    const isRsa = validatedValue.indexOf('ssh-rsa') === 0;
    const isEd25519 = validatedValue.indexOf('ssh-ed25519') === 0;
    const isEcdsa = validatedValue.indexOf('ecdsa-sha2-nistp') === 0;
    // must contain "ssh-rsa" or "ssh-ed25519" or "ecdsa-sha2-nistp"
    if (!isRsa && !isEd25519 && !isEcdsa) {
      setValidationError('A valid key must start with "ssh-rsa" or "ssh-ed25519" or "ecdsa-sha2-nistp"');
      return false;
    }
    // rsa keys must be at least 216 characters long (1024 bits)
    if (isRsa && validatedValue.length < 216) {
      setValidationError('RSA public key must be at least 216 characters (1024 bits) long.');
      return false;
    }
    // ed25519 keys must be at least 128 characters long (512 bits)
    if (isEd25519 && validatedValue.length < 68) {
      setValidationError('EdDSA public key must be at least 68 characters (512 bits) long.');
      return false;
    }
    // ecdsa keys must be at least 140 characters long (256 bits)
    if (isEcdsa && validatedValue.length < 140) {
      setValidationError('EcDSA public key must be at least 140 characters (256 bits) long.');
      return false;
    }

    setValidationError('');
    return true;
  };

  const validateAndSubmit = (value: string) => {
    const validate = validateInput(value);
    if (validate) {
      updateUserPublicKey(value.trim());
    }
  };

  const handleUserPublicKeyChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
  };

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onabort = () => setFileError('File reading was aborted');
    reader.onerror = () => setFileError('File reading has failed');
    reader.onload = () => {
      const data = String(reader.result);
      validateAndSubmit(data);
    };
    reader.readAsText(file);
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'text/plain': ['.pub'] },
  });
  const allFileErrors = fileError || fileRejections?.[0]?.errors?.[0]?.message || publicKeyError;
  const allErrors = showTextOption ? allFileErrors || inputValidationError : allFileErrors || dropValidationError;
  return (
    <div className={twClassnames('flex flex-col gap-3 text-sm', containerClassName)}>
      <div className="flex items-center gap-3">
        <div
          role="button"
          className={twClassnames('flex items-center gap-3 hover:text-theme-primary-600', {
            'text-theme-primary-600': showTextOption,
          })}
          onClick={() => {
            setShowTextOption(true);
            setDropValidationError('');
          }}
        >
          <FilePlusIcon />
          Paste here
        </div>
        <div className="h-4 w-0.5 bg-theme-neutral-600" />
        <div
          role="button"
          className={twClassnames('flex items-center gap-3 hover:text-theme-primary-600', {
            'text-theme-primary-600': !showTextOption,
          })}
          onClick={() => {
            setShowTextOption(false);
          }}
        >
          <UploadCloudOutlineIcon size={16} />
          Upload
        </div>
      </div>
      {showTextOption ? (
        <TextArea
          className="h-full resize-none py-0"
          innerContainerClassName="p-1"
          containerClassName={twClassnames('h-44 w-full px-4 py-3 md:w-[360px] lg:w-[480px]', className)}
          value={inputValue}
          placeholder={existingPublicKey || 'Paste your public key here'}
          onChange={handleUserPublicKeyChange}
          endIcon={
            <Button
              variant="neutral"
              className={twClassnames('rounded-full p-2', {
                '!bg-theme-primary-600 !text-white': publicKeyLoading,
              })}
              disabled={!!inputValidationError || publicKeyLoading}
              onClick={() => validateAndSubmit(inputValue)}
            >
              <SaveIcon />
            </Button>
          }
          disabled={publicKeyLoading}
        />
      ) : (
        <div
          {...getRootProps({
            className: twClassnames(
              'flex h-44 w-full cursor-pointer items-center justify-center rounded-xl border-2 border-dashed border-theme-primary-200 bg-white px-4 py-3 text-center text-theme-neutral-400 transition hover:border-theme-primary-600 hover:bg-theme-primary-100 hover:text-theme-primary-600 md:w-[360px] lg:w-[480px]',
              {
                '!border-theme-primary-600 !bg-theme-primary-100': isDragActive,
              },
              {
                '!border-theme-danger-600 !bg-theme-danger-200': !!fileError,
              },
              className,
            ),
          })}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center gap-1">
            <LuFileKey size={30} />
            <div className="font-light">Drop your id_rsa.pub file here</div>
          </div>
        </div>
      )}
      {publicKeyLoading ? (
        <div className="flex items-center gap-2 text-sm text-theme-primary-600">
          Saving public key!
          <LoadingSpinner
            size={16}
            className="fill-theme-primary-600 text-theme-primary-300"
          />
        </div>
      ) : allErrors ? (
        <div
          className={twClassnames('text-sm text-theme-danger-600', {
            'text-theme-neutral-500': inputValidationError && showTextOption,
          })}
        >
          {allErrors}
        </div>
      ) : null}
    </div>
  );
};
export default PublicKeyDropzone;

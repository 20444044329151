import { ReactElement } from 'react';

import twClassnames from '../../utils/classnames';

const ParamBadge = ({ text, icon, onClick }: { text: string | number; icon: ReactElement; onClick?: () => void }) => (
  <div
    role={onClick ? 'button' : 'none'}
    tabIndex={0}
    className={twClassnames(
      'flex items-center rounded border border-theme-neutral-300 px-2 py-[3px] text-xs text-theme-neutral-700 transition',
      {
        'cursor-pointer hover:border-theme-primary-600 hover:text-theme-primary-600': !!onClick,
      },
    )}
    onClick={onClick}
  >
    {text}
    <div className="ml-2">{icon}</div>
  </div>
);

export default ParamBadge;

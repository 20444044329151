import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import gpuNeutral from '../../assets/gpu-neutral-100.png';
import useUser from '../../hooks/useUser';
import { fetchMachines, getMachines } from '../../slices/machines';
import { openModal } from '../../slices/modals';
import { AppDispatch } from '../../store';
import { fullScreenStyles } from '../../utils/modals';
import AuthButton from '../AuthButton';
import Button from '../common/Button';
import HyperLink from '../common/HyperLink';
import { ModalName } from '../modals';

const SupplyWaitlist = () => {
  const { user } = useUser();
  const machines = useSelector(getMachines);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchMachines());
  }, [dispatch]);
  const isSignedUp = user?.email && machines.filter((mac) => mac.email === user?.email).length > 0;
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-8">
      <div className="text-center text-lg font-light text-theme-neutral-700">
        {isSignedUp ? (
          <>
            Thank you for joining the waitlist! We will review your application and get back to you shortly.
            <br />
            While waiting for approval, feel free to explore our{' '}
            <HyperLink
              to="/models"
              className="text-lg"
            >
              AI inference playground
            </HyperLink>
            !
          </>
        ) : (
          <>
            Hi! We are currently testing our GPU marketplace product.
            <br />
            Sign up for the waitlist if you are interested in supplying GPUs for Hyperbolic and we will be in touch
            shortly. Thank you!
          </>
        )}
      </div>
      {isSignedUp ? (
        <div className="rounded-lg border border-theme-primary-200 bg-theme-primary-50 px-5 py-2">
          Application status:&nbsp;
          <span className="font-medium text-theme-primary-600">Pending</span>
        </div>
      ) : user ? (
        <Button
          variant="outline"
          className="flex items-center gap-1 text-base"
          onClick={() =>
            dispatch(
              openModal({
                name: ModalName.GPURequestModal,
                props: { mode: 'supply' },
                styles: isMobile ? fullScreenStyles : { content: { maxWidth: '700px' } },
              }),
            )
          }
        >
          <FiPlus size={20} /> Join Waitlist
        </Button>
      ) : (
        <AuthButton className="text-base" />
      )}
      <img
        src={gpuNeutral}
        style={{ height: 360 }}
        className="object-contain"
      />
    </div>
  );
};

export default SupplyWaitlist;

import { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { GrDocumentUpload } from 'react-icons/gr';

import { DropdownContext, DropdownMenuItem } from '../common/Dropdown';

const UploadDropdownItem = ({ onDrop }: { onDrop: any }) => {
  const { setIsOpen } = useContext(DropdownContext);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      onDrop(files);
      setIsOpen(false);
    },
    maxFiles: 1,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
  });
  const inputProps = getInputProps();
  return (
    <DropdownMenuItem
      className="text-sm"
      childClassName="flex items-center"
      hideCheck
      disableClose
      {...getRootProps()}
    >
      <GrDocumentUpload className="mr-2" />
      Upload an image
      <input {...inputProps} />
    </DropdownMenuItem>
  );
};

export default UploadDropdownItem;

import { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import useLocalStorage from '../../hooks/useLocalStorage';
import twClassnames from '../../utils/classnames';

const Banner = ({
  storageKey,
  children,
  className,
}: {
  storageKey: string;
  children: ReactNode;
  className?: string;
}) => {
  const [bannerDisabled, setBannerDisabled] = useLocalStorage(storageKey);
  if (bannerDisabled) return null;
  const handleClose = () => {
    setBannerDisabled(true);
  };
  return (
    <div
      className={twClassnames(
        'mb-8 mt-4 flex items-center justify-center gap-20 rounded-lg bg-theme-primary-100 px-8 py-3 text-sm',
        className,
      )}
    >
      {children}
      <div>
        <FiX
          size={16}
          className="cursor-pointer"
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default Banner;

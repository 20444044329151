import { useEffect, useRef } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import stackedGPUs from '../assets/stacked-gpus.png';
import HyperLink from '../components/common/HyperLink';
import SearchIcon from '../components/common/icons/SearchIcon';
import LoadingSpinner from '../components/common/LoadingSpinner';
import InstanceFilters from '../components/Compute/InstanceFilters';
import InstanceHistoryCard from '../components/Compute/InstanceHistoryCard';
import RentedInstanceCard from '../components/Compute/RentedInstanceCard';
import useInstanceFilters from '../hooks/useInstanceFilters';
import useInterval from '../hooks/useInterval';
import useUser from '../hooks/useUser';
import {
  fetchRentedInstances,
  getFormattedRentedInstances,
  getRentedInstancesInitialLoading,
  getStatus,
} from '../slices/instances';
import { fetchInstanceHistory, getInstanceHistory, getInstanceHistoryInitialLoading } from '../slices/usage';
import { AppDispatch } from '../store';
import { InstanceRental, ResponseStatus } from '../utils/types';

const MyInstances = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const rentedInstances = useSelector(getFormattedRentedInstances);
  const rentedInstancesInitialLoading = useSelector(getRentedInstancesInitialLoading);
  const instanceHistoryInitialLoading = useSelector(getInstanceHistoryInitialLoading);
  const status = useSelector(getStatus);
  const loading = status == ResponseStatus.Loading;
  const instanceHistory = useSelector(getInstanceHistory);

  const { sortedInstances: sortedRentedInstances, ...rentedFilters } = useInstanceFilters(
    rentedInstances,
    (rental: InstanceRental) => rental.instance,
    {
      sortFn: (rental: InstanceRental) => rental.start,
      sortOrder: 'desc',
      prioritizeOverPrice: false,
    },
  );

  const { user, authToken } = useUser();
  const authTokenRef = useRef('');

  useEffect(() => {
    if (authToken && authToken !== authTokenRef.current) {
      authTokenRef.current = authToken;
      dispatch(fetchRentedInstances());
      dispatch(fetchInstanceHistory());
    }
  }, [authToken, dispatch]);

  useInterval(() => {
    if (authToken) {
      dispatch(fetchRentedInstances());
      dispatch(fetchInstanceHistory());
    }
  }, 5000);

  useEffect(() => {
    if (!authToken) {
      return navigate('/compute');
    }
  }, [authToken, navigate]);

  // todo: switch to toggle to supplied instances as well, or is that on the supply page?

  if (
    !rentedInstancesInitialLoading &&
    rentedInstances.length === 0 &&
    !instanceHistoryInitialLoading &&
    instanceHistory.length === 0
  ) {
    return (
      <div className="flex flex-col items-center justify-center gap-10">
        <div>You don't have any rented instances yet!</div>
        <div className="flex">
          <HyperLink
            to="/compute/rent"
            variant="outline"
            className="flex items-center py-2"
            onClick={() => posthog?.capture('Find GPUs Clicked')}
          >
            <SearchIcon
              size={20}
              className="mr-3"
            />
            Find the GPUs You Need
          </HyperLink>
        </div>
        <img
          src={stackedGPUs}
          style={{ width: 696 }}
        />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center">
        <div className="text-lg font-semibold text-theme-neutral-700">Active</div>
        {loading && (
          <div className="ml-4">
            <LoadingSpinner className="mr-2 h-4 w-4" />
          </div>
        )}
      </div>
      {rentedInstances.length > 0 && (
        <InstanceFilters
          {...rentedFilters}
          theme="rented"
        />
      )}
      {rentedInstances?.length === 0 && !!user && !rentedInstancesInitialLoading && (
        <div className="flex flex-col gap-4">
          <div>You currently have no active instances.</div>
          <div className="flex">
            <HyperLink
              to="/compute/rent"
              variant="outline"
              className="flex items-center py-2"
              onClick={() => posthog?.capture('Find GPUs Clicked')}
            >
              <SearchIcon
                size={20}
                className="mr-3"
              />
              Find GPUs
            </HyperLink>
          </div>
        </div>
      )}
      {rentedInstancesInitialLoading && rentedInstances?.length === 0 && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-[106px] w-full rounded-xl bg-theme-neutral-200" />
              <div className="h-[106px] w-full rounded-xl bg-theme-neutral-200" />
            </div>
          </div>
        </div>
      )}
      {(sortedRentedInstances as InstanceRental[]).map((i) => (
        <RentedInstanceCard
          key={i.id}
          rental={i}
        />
      ))}
      {instanceHistory?.length > 0 && <div className="text-lg font-semibold text-theme-neutral-700">History</div>}
      {/* TODO: add filters */}
      {instanceHistoryInitialLoading && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-[106px] w-full rounded-xl bg-theme-neutral-200" />
              <div className="h-[106px] w-full rounded-xl bg-theme-neutral-200" />
              <div className="h-[106px] w-full rounded-xl bg-theme-neutral-200" />
            </div>
          </div>
        </div>
      )}
      {instanceHistory.map((i) => (
        <InstanceHistoryCard
          key={`${i.instance_name}-${i.started_at}`}
          instanceHistory={i}
        />
      ))}
    </div>
  );
};

export default MyInstances;

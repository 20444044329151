import { FiXCircle } from 'react-icons/fi';

import twClassnames from '../../utils/classnames';
import { Option } from '../../utils/types';
import Dropdown, { DropdownButton, DropdownOverlay } from '../common/Dropdown';
import DropdownMenu from '../common/Dropdown/DropdownMenu';
import DropdownMenuItem from '../common/Dropdown/DropdownMenuItem';

const FilterDropdown = ({
  options,
  filter,
  onChange,
  dropdownClassName = '',
  buttonClassName = '',
  overlayClassName = '',
  placeholder,
  defaultLabel = 'Any',
}: {
  options: Option[];
  filter: string;
  onChange: (value: string) => void;
  dropdownClassName?: string;
  buttonClassName?: string;
  overlayClassName?: string;
  placeholder: string;
  defaultLabel?: string;
}) => {
  const selectedLabel = filter !== 'any' && (options.find((q) => filter === q.value)?.label || filter);
  return (
    <Dropdown className={dropdownClassName}>
      <DropdownButton
        className={twClassnames(
          'border-0 bg-theme-primary-50 px-5 py-3 text-theme-neutral-600 hover:bg-theme-primary-100',
          { 'px-2.5 py-2': !!selectedLabel },
          buttonClassName,
        )}
        textClassName="justify-center"
      >
        {selectedLabel ? (
          <div className="flex cursor-pointer items-center rounded-full bg-theme-primary-100 px-2.5 py-1 text-sm text-theme-primary-600">
            <span className="mr-1 truncate">{selectedLabel}</span>
            <FiXCircle
              size={20}
              className="transition hover:text-theme-primary-700"
              onClick={() => {
                // e.stopPropagation();
                onChange('any');
              }}
            />
          </div>
        ) : (
          placeholder
        )}
      </DropdownButton>
      <DropdownOverlay className={twClassnames('left-0', overlayClassName)}>
        <DropdownMenu className="max-h-80 overflow-y-auto">
          {[{ value: 'any', label: defaultLabel }, ...options].map((q) => (
            <DropdownMenuItem
              key={q.value}
              active={filter === q.value}
              onClick={() => onChange(q.value)}
            >
              {q.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default FilterDropdown;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useUser from '../../hooks/useUser';
import { updateUserInfo } from '../../slices/auth';
import { getUnpricedSupplied } from '../../slices/instances';
import {
  getSupply,
  setDisplayName,
  setDisplayNameError,
  setDisplayNameStatus,
  setNodePrice,
  setNodePriceError,
} from '../../slices/supply';
import { AppDispatch } from '../../store';
import { mapModelToPrice } from '../../utils/instances';
import { formatCurrency } from '../../utils/value';
import HyperLink from '../common/HyperLink';
import SaveIcon from '../common/icons/SaveIcon';
import Input from '../common/Input';
import InputSlider from '../common/InputSlider';
import LoadingSpinner from '../common/LoadingSpinner';

const FinalSetup = () => {
  const { userInfo } = useUser();
  const { nodePrice, nodePriceError, displayName, displayNameStatus, displayNameError } = useSelector(getSupply);
  const displayNameLoading = displayNameStatus === 'loading';
  const dispatch = useDispatch<AppDispatch>();
  const unpricedSupplied = useSelector(getUnpricedSupplied);
  const lastUnpricedSupplied = unpricedSupplied[0];

  const inputValue = displayNameStatus ? displayName : userInfo?.name;

  const saveDisplayName = async () => {
    if (!displayNameError) {
      dispatch(setDisplayNameStatus('loading'));
      await dispatch(
        updateUserInfo({
          userId: userInfo?.id || '',
          update: { name: displayName },
        }),
      );
      dispatch(setDisplayNameStatus(''));
    }
  };

  const maxModelPrice = mapModelToPrice[lastUnpricedSupplied?.gpu?.model] || mapModelToPrice.Default;

  const validate = (value: number) => {
    if (value <= 0) {
      dispatch(setNodePriceError('Node must be priced at more than $0'));
      return false;
    } else if (value > maxModelPrice / 100) {
      dispatch(setNodePriceError(`Nodes of this GPU type must be priced at ${formatCurrency(maxModelPrice / 100)}`));
      return false;
    }
    dispatch(setNodePriceError(''));
    return true;
  };

  const handleInput = (value: number) => {
    validate(value);
    dispatch(setNodePrice(value));
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">What is the name of your company?</div>
        <div className="text-sm">
          Your brand will appear on the detail page of your supplied machines. You can also update it later in the{' '}
          <HyperLink to="/settings">Settings</HyperLink>.
        </div>
        <Input
          id="display-name-input"
          value={inputValue}
          containerClassName="flex-1 w-full max-w-[500px]"
          onChangeText={(text: string) => {
            setDisplayNameStatus('edited');
            if (!text) {
              dispatch(setDisplayName(''));
              dispatch(setDisplayNameError('Display name cannot be empty'));
            } else {
              dispatch(setDisplayNameError(''));
              dispatch(setDisplayName(text));
            }
          }}
          endIcon={
            displayNameLoading ? (
              <LoadingSpinner
                size={20}
                className="fill-theme-primary-600 text-theme-primary-300"
              />
            ) : inputValue !== userInfo?.name ? (
              <SaveIcon
                size={24}
                className="cursor-pointer rounded-full bg-theme-primary-600 p-1 text-white"
                onClick={saveDisplayName}
              />
            ) : undefined
          }
          endIconClassName="mt-2 right-3 flex items-center"
          error={!!displayNameError}
        />
        {displayNameError && <div className="mb-3 text-theme-danger-600">{displayNameError}</div>}
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">Choose a price for your supplied node(s):</div>
        <div className="text-sm">
          We recommend starting from the lower end of the price range to boost actual utilization. You can always update
          pricing dynamically based on demand in the supplier dashboard. For more information on earnings and rewards,
          check the supplier documentation.
        </div>
        <div>
          <div className="mb-4 font-semibold text-black">Your on-demand price:</div>
          <InputSlider
            id="node-price"
            label="Price / GPU / hr"
            name="node-price"
            containerClassName="w-1/2"
            value={nodePrice}
            min={0}
            max={maxModelPrice / 100}
            step={0.01}
            error={nodePriceError}
            onChange={handleInput}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalSetup;

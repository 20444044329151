import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';

import twClassnames from '../../utils/classnames';
import Switch, { SwitchProps } from './Switch';

interface SwitchContainer extends SwitchProps {
  id: string;
  label?: string;
  required?: boolean;
  error?: string;
  infoTooltip?: string;
  labelClassName?: string;
}

const SwitchContainer = ({
  id,
  label,
  required,
  error,
  infoTooltip,
  labelClassName,
  ...otherProps
}: SwitchContainer) => (
  <div
    id={id}
    className="mb-4 flex items-center justify-between"
  >
    {label && (
      <label className={twClassnames('mr-3 flex items-center text-sm text-black', labelClassName)}>
        {label}
        {required && '*'}
        {infoTooltip && (
          <AiOutlineInfoCircle
            data-tooltip-id={`info-${id}`}
            className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
          />
        )}
        <Tooltip
          id={`info-${id}`}
          className="z-10"
          place="top"
        >
          {infoTooltip}
        </Tooltip>
      </label>
    )}
    <Switch
      className={error ? 'bg-theme-danger-600' : ''}
      {...otherProps}
    />
    {error && <div className="mt-2 text-theme-danger-600">error</div>}
  </div>
);

export default SwitchContainer;

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';

import { AppDispatch, RootState } from '../store';

export interface Message {
  id: string;
  text: string;
  image?: string;
  created: number;
  from?: string;
  error?: string;
}

interface ChatSlice {
  inputValue: string;
  audioValue: string;
  imageValue: string;
  messages: Message[];
  aiReader: any;
  aiResponse: string[];
  aiResponseLoading: boolean;
  aiResponseError: string;
}

const initialState: ChatSlice = {
  inputValue: '',
  audioValue: '',
  imageValue: '',
  messages: [],
  aiReader: undefined,
  aiResponse: [],
  aiResponseLoading: false,
  aiResponseError: '',
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const existingIndex = state.messages.findIndex((message) => message.id === action.payload.id);
      if (existingIndex > -1) {
        state.messages[existingIndex] = action.payload;
      } else if (action.payload.reset) {
        state.messages = [action.payload];
      } else {
        state.messages.push(action.payload);
      }
    },
    setChatValue: (state, action) => {
      state.inputValue = action.payload;
    },
    setAudioValue: (state, action) => {
      state.audioValue = action.payload;
    },
    setImageValue: (state, action) => {
      state.imageValue = action.payload;
    },
    resetChat: () => initialState,
    setAIResponseLoading: (state, action) => {
      state.aiResponseLoading = action.payload;
      state.aiResponseError = '';
    },
    setAIResponseError: (state, action) => {
      state.aiResponseLoading = false;
      const { error, messageId } = action.payload;
      if (messageId) {
        const messageIndex = state.messages.findIndex((message) => message.id === messageId);
        if (messageIndex > -1) {
          const message = state.messages[messageIndex];
          state.messages[messageIndex] = { ...message, error };
        }
      }
      state.aiResponseError = error;
    },
    setAIReader: (state, action) => {
      state.aiReader = action.payload;
    },
    addAIResponse: (state, action) => {
      state.aiResponse = state.aiResponse.concat(action.payload);
    },
    resetAIResponse: (state) => {
      state.aiResponse = initialState.aiResponse;
    },
  },
});

export const {
  addMessage,
  resetChat,
  setChatValue,
  setAudioValue,
  setImageValue,
  setAIResponseLoading,
  setAIResponseError,
  setAIReader,
  addAIResponse,
  resetAIResponse,
} = chatSlice.actions;

export const cancelAIResponse = () => (dispatch: AppDispatch, getState: any) => {
  const state = getState();
  state.chat?.aiReader?.cancel();
  dispatch(setAIReader(undefined));
};

export const finalizeAIMessage = () => (dispatch: AppDispatch, getState: any) => {
  const state = getState();
  if (state.chat.aiResponse.length === 0) return;
  const responseMessage = {
    id: uuidV4(),
    created: Date.now(),
    text: state.chat.aiResponse.join(''),
    from: 'ai',
  };
  dispatch(addMessage(responseMessage));
  dispatch(resetAIResponse());
  dispatch(setAIResponseLoading(false));
  return responseMessage;
};

export const getMessages = (state: RootState) => state.chat.messages;
export const getChatValue = (state: RootState) => state.chat.inputValue;
export const getImageValue = (state: RootState) => state.chat.imageValue;
export const getAudioValue = (state: RootState) => state.chat.audioValue;
export const getAIResponse = (state: RootState) => state.chat.aiResponse;
export const getAIResponseLoading = (state: RootState) => state.chat.aiResponseLoading;
export const getAIResponseError = (state: RootState) => state.chat.aiResponseError;

export default chatSlice.reducer;

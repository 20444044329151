import { useEffect } from 'react';
import { format, subDays } from 'date-fns';
import sumBy from 'lodash/sumBy';
import { useDispatch, useSelector } from 'react-redux';

import { fetchModels } from '../../slices/models';
import { getUsage, Usage } from '../../slices/usage';
import { AppDispatch } from '../../store';
import AudioCharts from './AudioCharts';
import ImageCharts from './ImageCharts';
import TextCharts from './TextCharts';
import UsageSumCard from './UsageSumCard';

const UsageComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const usageData = useSelector(getUsage);

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  const allTextUsage = sumBy(usageData, (datum: Usage) => datum.completion_tokens + datum.prompt_tokens);
  const allImageUsage = sumBy(usageData, (datum: Usage) => datum.image_steps);
  const allAudioUsage = sumBy(usageData, (datum: Usage) => datum.audio_tokens);
  const allUsage = allTextUsage + allImageUsage + allAudioUsage;

  if (allUsage === 0) return null;

  const startTime = format(subDays(new Date(), 7), 'MMM dd, yyyy');
  const endTime = format(new Date(), 'MMM dd, yyyy');

  return (
    <div className="mb-10 flex w-full flex-col">
      <h2>Past 7-Day Usage</h2>
      <div className="flex flex-col gap-5">
        <div className="flex gap-4">
          <UsageSumCard
            title="Total Tokens"
            amount={allTextUsage}
            timePeriod={`From ${startTime} to ${endTime}`}
          />
          <UsageSumCard
            title="Total Image Steps"
            amount={allImageUsage}
            timePeriod={`From ${startTime} to ${endTime}`}
          />
          <UsageSumCard
            title="Total Audio Tokens"
            amount={allAudioUsage}
            timePeriod={`From ${startTime} to ${endTime}`}
          />
        </div>
        <TextCharts />
        <ImageCharts />
        <AudioCharts />
      </div>
    </div>
  );
};

export default UsageComponent;

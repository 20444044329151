import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BiCollapseVertical, BiExpandVertical } from 'react-icons/bi';
import Markdown from 'react-markdown';

import twClassnames from '../../../utils/classnames';

const padding = 32;
const lineHeight = 20;

const AudioScript = ({ script }: { script: string }) => {
  const [isExpandable, setIsExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const clientHeight = ref.current.clientHeight;
      if (!isMobile && clientHeight > padding + lineHeight * 3) {
        setIsExpandable(true);
      }
    }
  }, []);
  return (
    <div
      ref={ref}
      className={twClassnames(
        'flex flex-col gap-2 rounded-lg border border-theme-primary-100 bg-white px-5 py-4 text-sm text-black',
      )}
    >
      <div
        className="overflow-y-hidden transition"
        style={{
          height: isExpandable && !isExpanded ? 3 * lineHeight : 'auto',
        }}
      >
        <Markdown>{script}</Markdown>
      </div>
      {isExpandable && (
        <div>
          {!isExpanded && '...'}
          <div className="mt-2 flex justify-end">
            {isExpanded ? (
              <BiCollapseVertical
                onClick={() => setIsExpanded(false)}
                className="cursor-pointer transition hover:text-theme-primary-600"
              />
            ) : (
              <BiExpandVertical
                onClick={() => setIsExpanded(true)}
                className="cursor-pointer transition hover:text-theme-primary-600"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioScript;

import { ButtonHTMLAttributes } from 'react';

import { buttonClasses, ButtonVariant } from '../../utils/button';
import twClassnames from '../../utils/classnames';
import LoadingSpinner from './LoadingSpinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  isLoading?: boolean;
}

const Button = ({ variant = 'primary', className, children, isLoading = false, disabled, ...props }: ButtonProps) => (
  <button
    className={twClassnames(
      'text-sm transition',
      { 'inline-block rounded-lg border px-4 py-2': variant !== 'link' },
      { 'border-none': variant === 'simple' },
      buttonClasses[variant],
      className,
    )}
    disabled={isLoading || disabled}
    {...props}
  >
    {isLoading ? (
      <div className="flex items-center justify-center">
        <LoadingSpinner
          size={16}
          className="mr-2 h-4 w-4"
        />
        Loading...
      </div>
    ) : (
      children
    )}
  </button>
);

export default Button;

const BillingIcon = ({ size = 24, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="bank-note-03">
      <path
        d="M14 9.5H11.5C10.6716 9.5 10 10.1716 10 11C10 11.8284 10.6716 12.5 11.5 12.5H12.5C13.3284 12.5 14 13.1716 14 14C14 14.8284 13.3284 15.5 12.5 15.5H10M12 8.5V9.5M12 15.5V16.5M18 12.5H18.01M6 12.5H6.01M2 8.7L2 16.3C2 17.4201 2 17.9802 2.21799 18.408C2.40973 18.7843 2.71569 19.0903 3.09202 19.282C3.51984 19.5 4.07989 19.5 5.2 19.5L18.8 19.5C19.9201 19.5 20.4802 19.5 20.908 19.282C21.2843 19.0903 21.5903 18.7843 21.782 18.408C22 17.9802 22 17.4201 22 16.3V8.7C22 7.5799 22 7.01984 21.782 6.59202C21.5903 6.2157 21.2843 5.90974 20.908 5.71799C20.4802 5.5 19.9201 5.5 18.8 5.5L5.2 5.5C4.0799 5.5 3.51984 5.5 3.09202 5.71799C2.7157 5.90973 2.40973 6.21569 2.21799 6.59202C2 7.01984 2 7.57989 2 8.7ZM18.5 12.5C18.5 12.7761 18.2761 13 18 13C17.7239 13 17.5 12.7761 17.5 12.5C17.5 12.2239 17.7239 12 18 12C18.2761 12 18.5 12.2239 18.5 12.5ZM6.5 12.5C6.5 12.7761 6.27614 13 6 13C5.72386 13 5.5 12.7761 5.5 12.5C5.5 12.2239 5.72386 12 6 12C6.27614 12 6.5 12.2239 6.5 12.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default BillingIcon;

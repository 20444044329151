import { ReactNode } from 'react';

import Card from '../common/Card';

const UsageChartCard = ({ children }: { children: ReactNode }) => (
  <Card
    noHover
    className="flex w-full flex-col gap-5 border-theme-neutral-200 bg-white p-8 shadow-theme-02"
  >
    {children}
  </Card>
);

export default UsageChartCard;

import { Outlet } from 'react-router-dom';

import Layout from '../components/common/Layout';

const Settings = () => {
  return (
    <Layout>
      <h2>Settings</h2>
      <Outlet />
      {/* <General /> */}
    </Layout>
  );
};

export default Settings;

import { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import toast from 'react-hot-toast';
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { getFormattedRentedInstance, terminateInstance } from '../../slices/instances';
import { AppDispatch, RootState } from '../../store';
import { InstanceStatus } from '../../utils/types';
import Button from '../common/Button';
import { CodeBlock, CodeBlockActions, CodeBlockContent, CodeBlockCopyButton } from '../common/CodeBlock';
import ModalClose from '../common/ModalClose';

const highlighterLang = 'shell';

const showToastDeleteSuccess = () => toast.success('Terminated instance successfully');

const RentedInstanceModal = ({ rentalId, onClose }: { rentalId: string; onClose: () => void }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const rental = useSelector((state: RootState) => getFormattedRentedInstance(state, rentalId));

  const sshCommand = rental?.sshCommand || '';

  const onSuccess = () => {
    setErrorMessage('');
    showToastDeleteSuccess();
    onClose();
  };

  const handleStop = async () => {
    setErrorMessage('');

    try {
      await dispatch(terminateInstance(rental?.id || ''));
      posthog?.capture('Rented Instance Terminated', { rentalId: rental?.id });
      onSuccess();
    } catch (e: any) {
      setErrorMessage(e?.message || 'Something went wrong');
    }
  };
  const isStarting = rental?.instance?.status === InstanceStatus.starting;
  const isLoading = rental?.instance?.status === InstanceStatus.stopping;

  return (
    <div className="p-4">
      <ModalClose onClose={onClose} />
      <h2 className="text-center">My Instance - {rental?.instance?.id}</h2>
      <div className="flex flex-col gap-4">
        {!isStarting && (
          <>
            <div>Connect to your instance using the following command:</div>
            <CodeBlock value={sshCommand}>
              <CodeBlockActions>
                <CodeBlockCopyButton />
              </CodeBlockActions>
              <CodeBlockContent
                language={highlighterLang}
                customStyle={{
                  padding: '30px',
                }}
              />
            </CodeBlock>
          </>
        )}
        <div className="text-xs">
          <div>
            * Please store important data in&nbsp;
            <code
              style={{
                fontFamily: '"Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace;',
              }}
              className="rounded border border-theme-neutral-300 bg-theme-neutral-100 px-1 py-0.5 text-theme-neutral-600"
            >
              /home/ubuntu
            </code>
            . Exceeding 1 GB of ephemeral disk space will cause the pod to reset.
          </div>
          <div>* Ensure your public key is valid, otherwise you might not be able to access the command line.</div>
        </div>
        <div className="flex justify-center">
          <Button
            onClick={handleStop}
            className="flex shrink items-center gap-2"
            variant="danger"
            isLoading={isLoading}
          >
            {isLoading ? (
              'Stopping'
            ) : (
              <>
                <FiTrash2 />
                <span>Terminate Instance</span>
              </>
            )}
          </Button>
          {errorMessage && <div className="mt-4 text-theme-danger-500">{errorMessage}</div>}
        </div>
        {/* <div className="flex justify-end">
          <Button
            onClick={() => onClose()}
            className="flex items-center gap-2 shrink"
          >
            OK
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default RentedInstanceModal;

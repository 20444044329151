import { Message } from '../slices/chat';
import { VLMContent } from './models';

interface MixedContentMessage {
  role: string;
  content: string | VLMContent[];
  image?: string;
}

export const formatMessagesWithHistory = (
  text: string,
  chatHistory: Message[],
  systemPrompt?: string,
  imageContent?: any,
) => {
  const messages: MixedContentMessage[] = [];
  if (systemPrompt) {
    messages.unshift({
      role: 'system',
      content: systemPrompt,
    });
  }
  const formattedHistory: MixedContentMessage[] = chatHistory.map((msg) => ({
    role: msg.from === 'ai' ? 'assistant' : 'user',
    content: msg.text,
  }));
  const messagesWithHistory: MixedContentMessage[] = messages.concat(formattedHistory).concat([
    {
      role: 'user',
      content: imageContent ? [{ type: 'text', text: text }].concat(imageContent) : text,
    },
  ]);
  return messagesWithHistory;
};

import { backendHost } from '../utils/constants';
import hyperFetch from '../utils/fetch';

export const redeemCouponCodeDb = async (couponCode: string) => {
  const result = await hyperFetch(`${backendHost}/settings/redeem_coupon_code`, {
    method: 'POST',
    body: JSON.stringify({ coupon_code: couponCode }),
  });
  return result;
};

export const fetchWalletAddressesDb = async () => {
  const response = await hyperFetch(`${backendHost}/settings/crypto-addresses`);
  return response.map((obj: any) => obj.address);
};

export const disconnectWalletAddressDb = async (address: string) => {
  await hyperFetch(`${backendHost}/settings/crypto-address`, {
    method: 'PUT',
    body: JSON.stringify({ address }),
  });
  return address;
};

export const addWalletAddressDb = async (address: string) => {
  await hyperFetch(`${backendHost}/settings/crypto-address`, {
    method: 'POST',
    body: JSON.stringify({ address }),
  });
  return address;
};

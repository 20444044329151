const RotateRightIcon = ({ color = 'currentColor', ...otherProps }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g
      id="rotate-right-ccw"
      clipPath="url(#clip0_2933_12809)"
    >
      <path
        id="Vector"
        d="M17.25 3.5V8H12.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M15.3675 11.75C14.8812 13.1303 13.9595 14.3152 12.7413 15.1261C11.523 15.937 10.0742 16.33 8.61321 16.2459C7.15219 16.1618 5.75807 15.6052 4.6409 14.6598C3.52374 13.7145 2.74406 12.4318 2.41934 11.0048C2.09461 9.57783 2.24243 8.08399 2.84053 6.74835C3.43863 5.4127 4.45461 4.30762 5.73537 3.5996C7.01614 2.89157 8.49232 2.61897 9.94149 2.82286C11.3907 3.02675 12.7343 3.69609 13.77 4.73002L17.25 8.00002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2933_12809">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="matrix(-1 0 0 1 18 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RotateRightIcon;

import { createBrowserRouter } from 'react-router-dom';

import Base from './components/Base';
import Billing from './components/Settings/Billing/Billing';
import BillingHome from './components/Settings/Billing/BillingHome';
import ComingSoon from './components/Settings/ComingSoon';
import General from './components/Settings/General';
import SupplyInstructions from './components/Supply/SupplyInstructions';
import SupplyList from './components/Supply/SupplyList';
import { FAQs } from './components/Support';
import FAQSection from './components/Support/FAQSection';
import ComputeWrapper from './pages/ComputeWrapper';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/ErrorPage';
import MarketplaceInstances from './pages/MarketplaceInstances';
import ModelDetails from './pages/ModelDetails';
import Models from './pages/Models';
import MyInstances from './pages/MyInstances';
import NewMachineRequest from './pages/NewMachineRequest';
import NewMachineSupply from './pages/NewMachineSupply';
import Settings from './pages/Settings';
import SupplyInstances from './pages/SupplyInstances';
import SupplyOnboarding from './pages/SupplyOnboarding';
import Support from './pages/Support';
import Team from './pages/Team';
import VerifyEmail from './pages/VerifyEmail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Base />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/models', element: <Models /> },
      {
        path: '/compute',
        element: <ComputeWrapper />,
        children: [
          { index: true, element: <MarketplaceInstances /> },
          {
            path: '/compute/rent',
            element: <MarketplaceInstances />,
          },
          {
            path: '/compute/instances',
            element: <MyInstances />,
          },
        ],
      },
      {
        path: '/supply',
        element: <SupplyInstances />,
        children: [
          {
            index: true,
            element: <SupplyList />,
          },
          { path: '/supply/list', element: <SupplyList /> },
          { path: '/supply/instructions', element: <SupplyInstructions /> },
        ],
      },
      { path: '/supply/onboarding', element: <SupplyOnboarding /> },
      {
        path: '/models/:modelId/:mode?/:lang?',
        element: <ModelDetails />,
        handle: { goBack: '/models' },
      },
      {
        path: '/compute/new',
        element: <NewMachineSupply />,
        handle: { goBack: '/compute' },
      },
      {
        path: '/compute/new/request',
        element: <NewMachineRequest />,
        handle: { goBack: '/compute' },
      },
      {
        path: '/settings',
        element: <Settings />,
        children: [{ index: true, element: <General /> }],
      },
      {
        path: '/support',
        element: <Support />,
        children: [
          { index: true, element: <FAQs /> },
          { path: '/support/:sectionId', element: <FAQSection /> },
        ],
      },
      {
        path: '/team',
        element: <Team />,
      },
      {
        path: '/billing',
        element: <Billing />,
        children: [
          {
            index: true,
            element: <BillingHome />,
          },
          {
            path: '/billing/newcard',
            element: <ComingSoon />,
          },
        ],
      },
      {
        path: '/verify-email',
        element: <VerifyEmail />,
      },
    ],
  },
]);

export default router;

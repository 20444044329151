import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import exampleVLM from '../assets/example-vlm.png';
import useAIResponse from '../hooks/useAIResponse';
import { getIsAuthenticated } from '../slices/auth';
import {
  addMessage,
  cancelAIResponse,
  getAIResponse,
  getChatValue,
  getImageValue,
  getMessages,
  setChatValue,
  setImageValue,
} from '../slices/chat';
import { getModel } from '../slices/models';
import { AppDispatch, RootState } from '../store';
import twClassnames from '../utils/classnames';
import { defaultTextPrompt, defaultVisionPrompt } from '../utils/constants';
import AuthButton from './AuthButton';
import Button from './common/Button';
import { Chat, Message, MessageList } from './common/Chat';
import MessageBar from './common/Chat/MessageBar';
import WeirdCircleIcon from './common/icons/WeirdCircleIcon';
import ModelCompletion from './Models/ModelCompletion';

const ModelChat = ({ className, isVisionLanguage = false }: { className?: string; isVisionLanguage?: boolean }) => {
  const [defaultImage, setDefaultImage] = useState('');
  const { modelId } = useParams();
  const [searchParams] = useSearchParams();
  const posthog = usePostHog();
  const dispatch = useDispatch<AppDispatch>();
  const value = useSelector(getChatValue);
  const imageValue = useSelector(getImageValue);
  const chatMessages = useSelector(getMessages);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const aiResponse = useSelector(getAIResponse);
  const modelVariants = model?.variants;
  const currentVariant = searchParams.get('variant') || modelVariants?.[0];
  const modelName = currentVariant || model?.model;
  const isBase = model?.subType === 'base';
  const { handleAIResponse, isLoading, error } = useAIResponse({
    model,
    chatMessages,
    modelName,
  });

  const handleChatValue = (value: string) => dispatch(setChatValue(value));
  const handleImageChange = (image: string) => {
    if (!image && defaultImage) setDefaultImage('');
    dispatch(setImageValue(image));
  };

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(exampleVLM);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result;
        const image = String(dataURL).replace(/data:image\/(jpg|png|jpeg);base64,/, '');
        setDefaultImage(image);
      };
      reader.readAsDataURL(blob);
    };
    fetchImage();
  }, []);

  if (model?.subType === 'base') {
    return (
      <ModelCompletion
        isVisionLanguage={isVisionLanguage}
        imageURL={imageValue}
        onImageChange={handleImageChange}
        defaultImage={defaultImage}
      />
    );
  }

  return (
    <Chat className={twClassnames('-mx-10 flex-1 lg:overflow-y-hidden', className)}>
      {chatMessages?.length === 0 && aiResponse.length === 0 ? (
        <div className="flex flex-1 flex-col items-center justify-center">
          <WeirdCircleIcon
            width={200}
            height={182}
            className="mb-6"
          />
          <div className="mb-4 text-lg text-theme-neutral-600">Ask me anything :)</div>
        </div>
      ) : (
        <MessageList className="px-10">
          {chatMessages.map((message) => (
            <Message
              key={message.id}
              id={message.id}
              text={message.text}
              image={message.image}
              fromOther={message.from === 'ai'}
              apiPath={`/models/${modelId}/api/python`}
              error={message.error}
            />
          ))}
          {aiResponse.length > 0 && (
            <Message
              id="ai-response"
              key="ai-response"
              text={aiResponse.join('')}
              fromOther
            />
          )}
        </MessageList>
      )}
      {error && <div className="mb-2 self-center text-sm font-thin text-theme-danger-600">{error}</div>}
      <MessageBar
        className="px-10"
        value={value}
        onChange={handleChatValue}
        onSubmit={(defaultText?: string) => {
          if (!isAuthenticated) return;
          const text = value || defaultText;
          const image = imageValue || defaultImage;
          const message = {
            text,
            created: Date.now(),
            id: uuidV4(),
            ...(isBase && { reset: true }),
            ...(isVisionLanguage && { image }),
          };
          posthog?.capture('Generate Text Clicked', {
            model: modelId,
          });
          dispatch(addMessage(message));
          if (isVisionLanguage) {
            handleAIResponse(text || '', image, message);
          } else {
            handleAIResponse(text || '', undefined, message);
          }
          handleChatValue('');
          handleImageChange('');
          setDefaultImage('');
        }}
        imageURL={imageValue}
        onImageChange={handleImageChange}
        disabled={isLoading || aiResponse.length > 0}
        overrideButton={
          !isAuthenticated ? (
            <AuthButton className="mr-2 py-1.5 text-sm" />
          ) : isLoading ? (
            <Button
              variant="link"
              type="button"
              className="duration-400 group mr-4 flex items-center justify-center rounded-full bg-theme-neutral-200 p-3 hover:bg-theme-primary-600 hover:text-white"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(cancelAIResponse());
              }}
            >
              <div className="h-3 w-3 rounded-sm bg-theme-primary-600 group-hover:bg-white" />
            </Button>
          ) : undefined
        }
        allowImageUpload={isVisionLanguage}
        defaultText={isVisionLanguage ? defaultVisionPrompt : defaultTextPrompt}
        defaultImage={defaultImage}
      />
    </Chat>
  );
};

export default ModelChat;

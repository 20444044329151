import merge from 'lodash/merge';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, getModals } from '../slices/modals';
import { defaultStyles } from '../utils/modals';
import modals from './modals';

ReactModal.setAppElement('#root');

const ModalPortal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModals);
  const Modal = modalData.name && modals[modalData.name];
  const handleClose = () => dispatch(closeModal());
  return (
    <ReactModal
      id="modal-portal"
      isOpen={!!Modal}
      onRequestClose={modalData.onRequestClose || modalData.onClose || handleClose}
      style={merge({}, ReactModal.defaultStyles, defaultStyles, modalData.styles)}
    >
      {Modal && (
        <Modal
          {...modalData.props}
          onClose={modalData.onClose || handleClose}
        />
      )}
    </ReactModal>
  );
};

export default ModalPortal;

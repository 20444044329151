import { forwardRef, InputHTMLAttributes, ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';

import twClassnames from '../../utils/classnames';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeText?: (text: string) => void;
  containerClassName?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  endIconClassName?: string;
  className?: string;
  error?: boolean;
  tooltipText?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      value,
      onChange,
      onChangeText,
      containerClassName,
      className,
      startIcon,
      endIcon,
      endIconClassName,
      error,
      tooltipText,
      ...otherProps
    },
    ref,
  ) => (
    <div
      id={id}
      className={twClassnames('relative flex', containerClassName)}
      {...(tooltipText && { 'data-tooltip-id': `${id}-tooltip` })}
    >
      {startIcon && <div className="absolute left-4 mt-3.5 text-theme-neutral-600">{startIcon}</div>}
      <input
        ref={ref}
        value={value}
        onChange={(e) => {
          onChange?.(e);
          onChangeText?.(e.target.value);
        }}
        className={twClassnames(
          'w-full rounded-lg border border-theme-primary-200 bg-white px-4 py-2 text-sm text-black outline-none placeholder:text-theme-neutral-500 focus:border-theme-primary-600 disabled:border-theme-neutral-200 disabled:bg-theme-neutral-100 disabled:text-theme-neutral-500',
          {
            'pl-12': !!startIcon,
            'pr-12': !!endIcon,
            '!border-theme-danger-600': !!error,
          },
          className,
        )}
        {...otherProps}
      />
      {endIcon && (
        <div className={twClassnames('absolute right-3 mt-3.5 text-theme-neutral-600', endIconClassName)}>
          {endIcon}
        </div>
      )}
      {tooltipText && (
        <Tooltip
          id={`${id}-tooltip`}
          className="z-10"
          place="top"
        >
          {tooltipText}
        </Tooltip>
      )}
    </div>
  ),
);

export default Input;

import { useCallback, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import useUser, { UserStatus } from '../../../hooks/useUser';
import { fetchStripeCheckoutSessionStatus } from '../../../services/stripe';
import { fetchBalance } from '../../../slices/usage';
import { AppDispatch } from '../../../store';
import hyperFetch from '../../../utils/fetch';

const showToastSuccess = () => toast.success('Payment successful!');
const showToastError = () => toast.error('Payment was unsuccessful');
const URL_PARAM_CHECKOUT_SESSION_ID = 'checkout_session_id';

const CheckoutStatus = () => {
  const [status, setStatus] = useState(null);
  const { status: userStatus } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();

  const removeUrlParam = useCallback(() => {
    searchParams.delete(URL_PARAM_CHECKOUT_SESSION_ID);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const sessionId = searchParams.get(URL_PARAM_CHECKOUT_SESSION_ID);
    if (userStatus == UserStatus.LoggedIn && sessionId && !status) {
      fetchStripeCheckoutSessionStatus(sessionId).then((sessionStatus) => {
        setStatus(sessionStatus);
        removeUrlParam();
      });
    }
  }, [searchParams, userStatus, status, removeUrlParam]);

  useEffect(() => {
    if (status === 'complete') {
      showToastSuccess(); // todo: fires twice, one top one bottom for some reason
      dispatch(fetchBalance());
    } else if (status) {
      showToastError();
    }
  }, [status, dispatch]);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  return null;
};

export default CheckoutStatus;

export enum ResponseStatus {
  Unfetched,
  Loading,
  Failure,
  Success,
}

export interface Machine {
  id: string;
  name: string;
  email: string;
  phone: string;
  gpuModel: string;
  gpuCount: string;
  cpuCores: string;
  storageCapacity: string;
  networkBandwidth: string;
  operatingSystem: string;
  location: string;
  price: number;
  owner: string;
  additionalDetails?: string;
  clusterInterface: string;
  ram: string;
  interconnectNetwork: string;
  startDate: Date;
  endDate: Date;
  created: number;
  minDuration: number;
  mode?: string;
}

export interface Option {
  value: string;
  label: string;
}

export interface InferencePrice {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  meta: any;

  model_id: number;
  model_type?: string;
  amount: number;
  llm_tokens?: number;
  image_resolution?: string;
  image_steps?: number;
  tts_characters?: number;
}

export interface Model {
  slug: string;
  name: string;
  url: string;
  type: string;
  description: string;
  pricePerToken: number;
  model: string;
  hfLink?: string;
  isPopular?: boolean;
  isNew?: boolean;
  maxTokens?: number;
  badgeText?: string;
  hideSystem?: boolean;
  variants?: string[];
  hidden?: boolean;
  hiddenInputs?: string[];
  subType?: string;
  defaultMaxTokens?: number;
  numberFormat?: string;
  image?: any;
  defaultSystemPrompt?: string;
  price: InferencePrice;
  defaultTemperature?: number;
  defaultTopP?: number;
  contextLength?: number;
}

export interface TextInputParameters<T> {
  systemPrompt: string;
  maxTokens: T;
  temperature: T;
  topP: T;
}

export interface ImageInputParameters<N, B> {
  prompt: string;
  negativePrompt?: string;
  steps: N;
  cfgScale: N;
  enableRefiner: B;
  height: N;
  width: N;
  modelName?: string;
  image?: string;
  strength?: N;
  enableReference?: B;
}

export interface AudioInputParameters<N> {
  speed: N;
}

export interface BillingItemAIInference {
  id: number;
  name: string;
  totalCost: number;
  requestsMade: number;
  tokensGenerated: number;
}

export interface BillingItemGPUMarketplace {
  id: number;
  name: string;
  totalCost: number;
  totalGPUHours: number;
}

// begin Instances
export enum InstanceHardwareType {
  Cpu = 'cpu',
  Gpu = 'gpu',
  Ram = 'ram',
  Storage = 'storage',
}

export enum InstanceHardwareGPUInterface {
  PCIeX8 = 'PCIe x8',
  PCIeX16 = 'PCIe x16',
  SXM = 'SXM',
  SXM2 = 'SXM2',
  SXM3 = 'SXM3',
  SXM4 = 'SXM4',
  SXM5 = 'SXM5',
}

export type InstanceHardwareGPU = {
  hardware_type: InstanceHardwareType.Gpu;
  model: string;
  clock_speed: number | undefined; // in hertz
  compute_power: number | undefined; // in teraflops
  ram: number | undefined; // in megabytes
  interface: InstanceHardwareGPUInterface | undefined;
  nvidia_driver_version: string | undefined;
};

export type InstanceHardwareCPU = {
  hardware_type: InstanceHardwareType.Cpu;
  model: string;
  cache: string | undefined; // in megabytes
  cores: number | undefined;
  virtual_cores: number | undefined;
};

export enum InstanceHardwareRAMType {
  DDR3 = 'DDR3',
  DDR4 = 'DDR4',
  DDR5 = 'DDR5',
}

export type InstanceHardwareRAM = {
  hardware_type: InstanceHardwareType.Ram;
  type: InstanceHardwareRAMType | undefined;
  capacity: number; // in megabytes
  // cas latency, bandwidth?
};

export enum InstanceHardwareStorageInterface {
  SATA = 'SATA',
  NVME = 'NVMe',
  PCIE = 'PCIe',
}

export enum InstanceHardwareStorageType {
  HDD = 'HDD',
  SSD = 'SSD',
  // PCIE = 'PCIe' // are these really different?
}

export type InstanceHardwareStorage = {
  hardware_type: InstanceHardwareType.Storage;
  type: InstanceHardwareStorageType | undefined;
  model: string | undefined;
  interface: InstanceHardwareStorageInterface | undefined;
  capacity: number; // in megabytes
  bandwidth: number | undefined; // in megabytes per second
};

export type InstanceNetwork = {
  upload: number | undefined; // in megabits per second
  download: number | undefined; // in megabits per second
  latency: number | undefined; // in ms (ping)
  measured_at: Date | undefined; // when the speeds were last checked
  // # of open tcp/ip ports?
  // type of connection?
};

export type InstanceHardware =
  | InstanceHardwareCPU
  | InstanceHardwareGPU
  | InstanceHardwareRAM
  | InstanceHardwareStorage;

export enum InstanceStatus {
  unknown = 'unknown',
  online = 'online',
  offline = 'offline',
  starting = 'starting',
  node_ready = 'node_ready',
  stopping = 'stopping',
  busy = 'busy',
}

export enum ClusterStatus {
  Unknown = 'clusterstatus_unknown',
  Offline = 'cluster_offline',
  Online = 'cluster_online',
  Ready = 'cluster_ready',
}

export const mapClusterStatusToString: Record<ClusterStatus, string> = {
  [ClusterStatus.Unknown]: 'unknown',
  [ClusterStatus.Offline]: 'offline',
  [ClusterStatus.Online]: 'online',
  [ClusterStatus.Ready]: 'ready',
};

export enum NodeStatus {
  Unknown = 'nodestatus_unknown',
  Ready = 'node_ready',
  DiskPressure = 'node_diskpressure',
  MemoryPressure = 'node_memorypressure',
  PidPressure = 'node_pidpressure',
  NetworkUnavailable = 'node_networkunavailable',
}

export const mapNodeStatusToString: Record<NodeStatus, string> = {
  [NodeStatus.Unknown]: 'unknown',
  [NodeStatus.Ready]: 'ready',
  [NodeStatus.DiskPressure]: 'disk pressure',
  [NodeStatus.MemoryPressure]: 'memory pressure',
  [NodeStatus.PidPressure]: 'pid pressure',
  [NodeStatus.NetworkUnavailable]: 'offline',
};

export type InstanceLocation = {
  region: string;
  country: string | undefined;
  city: string | undefined;
  latitude: string | undefined;
  longitude: string | undefined;
};

export type InstanceAvailability = {
  available: boolean;
  startDate: Date;
  endDate: Date;
};

export enum InstanceBillingPeriod {
  hourly = 'hourly',
  daily = 'daily',
  monthly = 'monthly',
}

export enum InstanceBillingAgent {
  supplier = 'supplier',
  platform = 'platform',
}

export type InstancePricingPrice = {
  amount: number; // in cents
  period: InstanceBillingPeriod;
  agent?: InstanceBillingAgent;
};

export type InstancePricing = {
  price: InstancePricingPrice;
  // anything else here?
};

export interface ReservedInstance {
  id: string;
  hardware: { gpu: InstanceHardwareGPU }[];
}

export interface InstanceResponse {
  id: string;
  nodeName: string;
  owner: string; // user id of the owner of the instance
  status: InstanceStatus | NodeStatus;
  location: InstanceLocation;
  host_os: string; // the operating system
  hardware: {
    cpus: InstanceHardwareCPU[];
    gpus: InstanceHardwareGPU[];
    ram: InstanceHardwareRAM[];
    storage: InstanceHardwareStorage[];
  };
  network: InstanceNetwork;
  availability: InstanceAvailability | undefined;
  pricing: InstancePricing;
  metadata: any; // tags and anything else
  reserved: boolean;
  cluster_name: string;
  supplier_id: string;
  instances: ReservedInstance[];
  gpus_total: number;
  gpus_reserved: number;
  gpu_count?: number;
  has_persistent_storage?: boolean;
  cluster_status?: ClusterStatus;
}

// an instance may have access to only a
// subset of the available hardware on the
// host machine
export interface Instance extends InstanceResponse {
  hardwareArr: InstanceHardware[];
  gpu: InstanceHardwareGPU;
  cpu: InstanceHardwareCPU;
  storage: InstanceHardwareStorage;
  ram: InstanceHardwareRAM;
  gpuCount: number;
  gpuRamGB: number;
  totalPrice: number;
  storageCapacity: string;
  ramCapacity: string;
  reserved: boolean;
  clusterName: string;
  hasPersistentStorage?: boolean;
}

// end Instances

export type InstanceRental = {
  id: string;
  start: string;
  end: string | null;
  instance: Instance;
  sshCommand: string;
};

export type InstanceArt = {
  instanceName: string;
  imageURL: string;
  userId: string;
  created: number;
};

import { collection, doc, getDoc, getDocs, query, setDoc, where } from '@firebase/firestore/lite';

import hyperFetch from '../utils/fetch';
// import { backendHost } from '../utils/constants';
import { db } from '../utils/firebase';

export const setInstanceArtDb = async (userId: string, instanceName: string, imageURL: string) => {
  const collRef = collection(db, 'instanceArt');
  const newInstanceArtRef = doc(collRef, instanceName);
  const newInstanceArt = {
    userId,
    instanceName,
    imageURL,
    created: Date.now(),
  };
  await setDoc(newInstanceArtRef, newInstanceArt);
  return newInstanceArt;
};

export const generateInstanceArtDb = async ({ userId, instanceName }: { userId: string; instanceName: string }) => {
  const url = 'https://api.hyperbolic.xyz/v1/image/generation';
  // const url = `${backendHost}/v1/image/generation`;
  const response = await hyperFetch(url, {
    method: 'POST',
    body: JSON.stringify({
      model_name: 'FLUX.1-dev',
      prompt: `${instanceName.replace(/-/g, ' ')}`,
      steps: 30,
      cfg_scale: 5,
      enable_refiner: false,
      height: 256,
      width: 256,
      backend: 'auto',
    }),
  });
  const output = response.images[0].image;

  if (output) {
    try {
      // do this async-like
      setInstanceArtDb(userId, instanceName, output);
    } catch (error) {
      console.error('error saving instance art:', error);
    }
  }

  return { instanceName, imageURL: output };
};

export const fetchInstanceArtDb = async ({ userId, instanceName }: { userId: string; instanceName: string }) => {
  const docRef = doc(db, 'instanceArt', instanceName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return await generateInstanceArtDb({ userId, instanceName });
  }
};

export const fetchAllInstanceArtDb = async ({ userId }: { userId: string }) => {
  try {
    const q = query(collection(db, 'instanceArt'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
    return docs;
  } catch (e) {
    console.error('error fetching all instance art: ', e);
  }
};

import { ReactNode } from 'react';
import Slider from 'rc-slider';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';

import twClassnames from '../../utils/classnames';
import Input from './Input';

import 'rc-slider/assets/index.css';

interface InputSliderProps {
  id: string;
  label: string;
  name: string;
  onChange: (text: any) => void;
  value?: number;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  error?: string;
  infoTooltip?: ReactNode;
  containerClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  required?: boolean;
  inputLabel?: string;
  inputClassName?: string;
  hideSlider?: boolean;
}

const InputSlider = ({
  id,
  label,
  value,
  onChange,
  min,
  max,
  step,
  error,
  infoTooltip,
  containerClassName,
  labelClassName,
  disabled,
  required,
  inputLabel,
  inputClassName,
  hideSlider = false,
}: InputSliderProps) => {
  return (
    <div className={twClassnames('mb-4 flex flex-col', containerClassName)}>
      <div className="flex items-center justify-between">
        <div className={twClassnames('mr-3 flex items-center text-sm text-black', labelClassName)}>
          {label}
          {required && '*'}
          {infoTooltip && (
            <AiOutlineInfoCircle
              data-tooltip-id={`info-${id}`}
              className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
            />
          )}
          <Tooltip
            id={`info-${id}`}
            className="z-10"
            place="top"
          >
            {infoTooltip}
          </Tooltip>
        </div>
        <Input
          className={twClassnames(
            'w-20 rounded-[14px] border border-theme-primary-200 px-4 py-1.5 text-center text-black hover:bg-theme-primary-50',
            { 'border-theme-danger-600 outline-theme-danger-600': !!error },
            inputClassName,
          )}
          min={min}
          max={max}
          step={step}
          value={value}
          onChangeText={onChange}
          error={!!error}
          disabled={disabled}
          {...(inputLabel && {
            endIcon: inputLabel ? <div className="text-sm">{inputLabel}</div> : undefined,
            endIconClassName: 'mt-[7px] right-2.5',
          })}
        />
      </div>
      {!hideSlider && (
        <div className="mt-4 w-full">
          <Slider
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            classNames={{
              rail: '!bg-theme-neutral-300 !h-[6px]',
              track: twClassnames('!h-[6px]', {
                '!bg-theme-danger-600': !!error,
                '!bg-theme-primary-600': !error,
                '!bg-theme-primary-300': disabled,
              }),
              handle: twClassnames('z-10 !-mt-[7px] !h-5 !w-5 !border-theme-primary-600 !opacity-100 !shadow-none', {
                '!border-theme-danger-600': !!error,
                '!border-theme-primary-300': disabled,
              }),
            }}
          />
        </div>
      )}
      {error && <div className="mt-2 text-theme-danger-600">{error}</div>}
    </div>
  );
};

export default InputSlider;

import twClassnames from '../../utils/classnames';

export interface SwitchProps {
  className?: string;
  selected: boolean;
  onSelect: (value: boolean) => void;
}

const Switch = ({ className, selected, onSelect }: SwitchProps) => {
  return (
    <div
      role="button"
      className={twClassnames(
        'flex h-5 w-9 cursor-pointer items-center rounded-full bg-theme-neutral-400 p-0.5 transition',
        { 'bg-theme-primary-600': selected },
        className,
      )}
      onClick={() => onSelect(!selected)}
    >
      <input
        type="checkbox"
        value={String(selected)}
        onChange={() => onSelect(!selected)}
        className="hidden"
      />
      <div
        className={twClassnames('h-4 w-4 rounded-full bg-white transition', {
          'translate-x-4': selected,
        })}
      />
    </div>
  );
};

export default Switch;

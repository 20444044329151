import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { getUserInfo } from '../../slices/auth';
import { getAIResponse, getAudioValue, getChatValue, getMessages } from '../../slices/chat';
import { defaultTextPrompt, defaultVisionPrompt, vlmDefaultImageURL } from '../../utils/constants';
import {
  formatAudioCurlRequest,
  formatAudioPythonRequest,
  formatAudioTsRequest,
  formatBaseCurlRequest,
  formatBasePythonRequest,
  formatBaseTsRequest,
  formatCurlRequest,
  FormatFn,
  formatGradioRequest,
  formatImageCurlRequest,
  formatImagePythonRequest,
  formatImageTsRequest,
  formatPythonRequest,
  formatTsRequest,
  formatVisionPythonRequest,
  formatVisionTsRequest,
} from '../../utils/models';
import { AudioInputParameters, ImageInputParameters, Model, TextInputParameters } from '../../utils/types';
import { CodeBlock, CodeBlockActions, CodeBlockContent, CodeBlockCopyButton } from '../common/CodeBlock';
import HyperLink from '../common/HyperLink';
import ShareIcon from '../common/icons/ShareIcon';

const mapLangToVisionFormat: { [key: string]: FormatFn } = {
  python: formatVisionPythonRequest,
  ts: formatVisionTsRequest,
  curl: formatCurlRequest, // curl is hidden by default for this type of model
};

const mapBaseToFormat: { [key: string]: any } = {
  python: formatBasePythonRequest,
  ts: formatBaseTsRequest,
  curl: formatBaseCurlRequest,
};
const mapLangToFormat: { [key: string]: FormatFn } = {
  python: formatPythonRequest,
  ts: formatTsRequest,
  curl: formatCurlRequest,
};
const mapLangToImageFormat: { [key: string]: any } = {
  python: formatImagePythonRequest,
  ts: formatImageTsRequest,
  curl: formatImageCurlRequest,
};
const mapLangToAudioFormat: { [key: string]: any } = {
  python: formatAudioPythonRequest,
  ts: formatAudioTsRequest,
  curl: formatAudioCurlRequest,
};
const mapLangToFormatFn: { [key: string]: any } = {
  vlm: mapLangToVisionFormat,
  llm: mapLangToFormat,
  image: mapLangToImageFormat,
  audio: mapLangToAudioFormat,
};
const defaultApiKey = '$HYPERBOLIC_API_KEY';
const defaultMessages = [{ role: 'user', content: defaultTextPrompt }];

const defaultImageMessages = [
  {
    role: 'user',
    content: defaultVisionPrompt,
    image: vlmDefaultImageURL,
  },
];
const defaultAudioText =
  'Los Angeles! The City of Angels is a treasure trove of exciting experiences, iconic landmarks, and endless entertainment options.';

const createSpacesUrl = (code: string) => {
  const base_URL = 'https://huggingface.co/new-space';
  const params = new URLSearchParams({
    name: 'new-space',
    sdk: 'gradio',
  });
  const encoded_content = code.trim();
  params.append('files[0][path]', 'app.py');
  params.append('files[0][content]', encoded_content);
  params.append('files[1][path]', 'requirements.txt');
  params.append('files[1][content]', 'hyperbolic-gradio');
  return `${base_URL}?${params.toString()}`;
};

const ModelCodeSnippet = ({
  id,
  model,
  textInputParameters,
  imageInputParameters,
  audioInputParameters,
  language,
  type = 'llm',
  liveUpdates = false,
  modelName = '',
  isGradio = false,
}: {
  id: string;
  model: Model | undefined;
  textInputParameters: TextInputParameters<number>;
  imageInputParameters: ImageInputParameters<number, boolean>;
  audioInputParameters: AudioInputParameters<number>;
  language: string;
  type?: string;
  liveUpdates?: boolean;
  modelName?: string;
  isGradio?: boolean;
}) => {
  const userInfo = useSelector(getUserInfo);
  const chatMessages = useSelector(getMessages);
  const chatValue = useSelector(getChatValue);
  const audioValue = useSelector(getAudioValue);
  const isVLM = type === 'vlm';
  const isBase = model?.subType === 'base';
  let formatFn = mapLangToFormatFn[type][language] || mapLangToFormatFn['llm'][language];
  if (isBase) {
    formatFn = mapBaseToFormat[language];
  } else if (isGradio) {
    formatFn = formatGradioRequest;
  }
  const apiKey = isMobile ? defaultApiKey : userInfo?.api_key || defaultApiKey;
  const highlighterLang = language === 'curl' ? 'shell' : language;
  const aiResponse = useSelector(getAIResponse);
  const messages = [];
  if (textInputParameters.systemPrompt) {
    messages.unshift({
      role: 'system',
      content: textInputParameters.systemPrompt,
    });
  }
  const formattedHistory = chatMessages.map((msg) => ({
    role: msg.from === 'ai' ? 'assistant' : 'user',
    content: msg.text,
    ...((msg.image || isVLM) && {
      image: 'image',
      image_process_mode: 'default',
    }),
  }));
  const inputPrompt = chatValue || (isVLM ? defaultVisionPrompt : defaultTextPrompt || '');
  const messagesWithHistory: {
    role: string;
    content: string;
    image?: string;
  }[] = messages
    .concat(formattedHistory)
    .concat(
      formattedHistory.length === 0
        ? [
            {
              role: 'user',
              content: inputPrompt,
            },
          ]
        : [],
    )
    .concat(aiResponse.length > 0 ? [{ role: 'assistant', content: aiResponse.join('') }] : []);
  const audioText = liveUpdates ? audioValue || defaultAudioText : defaultAudioText;
  const messagesArr = isVLM // liveUpdates not supported for VLMs yet
    ? defaultImageMessages
    : liveUpdates
      ? messagesWithHistory
      : defaultMessages;

  let codeString = formatFn?.(modelName, model?.url || '', textInputParameters, apiKey, messagesArr);
  if (type === 'image') {
    codeString = formatFn?.(modelName, model?.url || '', imageInputParameters, apiKey);
  } else if (type === 'audio') {
    codeString = formatFn?.(modelName, model?.url || '', audioInputParameters, apiKey, audioText);
  } else if (isBase) {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      textInputParameters,
      apiKey,
      inputPrompt,
      isVLM ? '[image]' : undefined,
    );
  }

  const spacesUrl = createSpacesUrl(codeString);

  return (
    <CodeBlock
      value={codeString}
      className="mt-2 md:mt-6"
    >
      <CodeBlockActions>
        {isGradio && (
          <HyperLink
            variant="ghost"
            href={spacesUrl}
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-2"
          >
            <ShareIcon size={20} />
            Deploy on HF Spaces
          </HyperLink>
        )}
        <CodeBlockCopyButton />
      </CodeBlockActions>
      <CodeBlockContent
        language={highlighterLang}
        customStyle={{
          padding: '30px',
          overflow: 'unset',
          ...(isGradio && { paddingTop: 50 }),
        }}
      />
    </CodeBlock>
  );
};

export default ModelCodeSnippet;

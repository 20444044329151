import { differenceInCalendarDays, intervalToDuration } from 'date-fns';
import flatMap from 'lodash/flatMap';
import { useDispatch, useSelector } from 'react-redux';

import useUser from '../../hooks/useUser';
import { fetchInstanceArt, getInstanceArt, getInstanceArtLoading } from '../../slices/instances';
import { InstanceHistory } from '../../slices/usage';
import { AppDispatch } from '../../store';
import {
  calculateGPURam,
  calculateRamCapacity,
  calculateStorageCapacity,
  computePrice,
  displayDuration,
  displayModelName,
  displayPricePeriod,
  findGpuPrice,
} from '../../utils/instances';
import {
  InstanceHardware,
  InstanceHardwareCPU,
  InstanceHardwareGPU,
  InstanceHardwareRAM,
  InstanceHardwareStorage,
  InstanceStatus,
} from '../../utils/types';
import HyperLink from '../common/HyperLink';
import InstanceCard from './InstanceCard';

// TODO: move to utils or combine with formatInstance
const formatInstanceHistory = (instanceHistory: InstanceHistory) => {
  const hardwareArr: InstanceHardware[] = flatMap(instanceHistory?.hardware || [], (item) => Object.values(item));
  const gpu: InstanceHardwareGPU = hardwareArr.find((item) => item.hardware_type === 'gpu') as InstanceHardwareGPU;
  const cpu: InstanceHardwareCPU = hardwareArr.find((item) => item.hardware_type === 'cpu') as InstanceHardwareCPU;
  const ram: InstanceHardwareRAM = hardwareArr.find((item) => item.hardware_type === 'ram') as InstanceHardwareRAM;
  const storage: InstanceHardwareStorage = hardwareArr.find(
    (item) => item.hardware_type === 'storage',
  ) as InstanceHardwareStorage;
  const gpuCount = instanceHistory.gpu_count;
  const gpuRamGB = calculateGPURam(gpu?.ram);
  const totalPrice = computePrice(gpu?.model, gpuCount, {
    price: instanceHistory.price,
  });
  const totalGpus = hardwareArr.filter((item) => item.hardware_type === 'gpu').length;
  const storageCapacity = calculateStorageCapacity(((storage?.capacity || 0) / totalGpus) * gpuCount);
  const ramCapacity = calculateRamCapacity(ram);
  return {
    ...instanceHistory,
    gpu,
    cpu,
    ram,
    storage,
    gpuCount,
    gpuRamGB,
    totalPrice,
    storageCapacity,
    ramCapacity,
  };
};

const InstanceHistoryCard = ({ instanceHistory }: { instanceHistory: InstanceHistory }) => {
  const dispatch = useDispatch<AppDispatch>();
  const allInstanceArt = useSelector(getInstanceArt);
  const allInstanceArtLoading = useSelector(getInstanceArtLoading);
  const { user } = useUser();
  const userId = user?.uid;

  const formattedInstanceHistory = formatInstanceHistory(instanceHistory);
  const instanceName = instanceHistory.instance_name;
  const { gpuCount, gpuRamGB, gpu, storageCapacity, cpu, ramCapacity } = formattedInstanceHistory;

  const city = 'San Francisco';
  const region = 'North America';
  const country = 'US';

  // const { upload, download } = instanceHistory?.network || {};

  const endOfYear = new Date('12/31/2024');
  const max = differenceInCalendarDays(endOfYear, Date.now());
  const pricePeriod = instanceHistory?.price?.period;

  const startDate = new Date(instanceHistory.started_at);
  const endDate = new Date(instanceHistory.terminated_at);
  const diff = intervalToDuration({ end: endDate, start: startDate });

  const imageURL = allInstanceArt.find((i) => i.instanceName == instanceName)?.imageURL;
  const imageLoading = !!allInstanceArtLoading.find((i) => i == instanceName);

  const handleQuantityClicked = async () => {
    try {
      if (!userId) {
        return;
      }
      await dispatch(fetchInstanceArt({ userId, instanceName }));
    } catch (e: any) {
      // nothing
    }
  };

  return (
    <InstanceCard
      id={`${instanceName}-${instanceHistory.started_at}`}
      status={InstanceStatus.offline}
      gpuCount={gpuCount}
      gpuModelName={displayModelName(gpu?.model)}
      gpuRamGB={gpuRamGB}
      storageCapacity={storageCapacity}
      cpuCores={cpu?.cores}
      ramGB={ramCapacity}
      location={`${country || city}${region || country ? `, ${region || country}` : ''}`}
      maxDays={max}
      endOfYear={endOfYear}
      gpuPrice={findGpuPrice(gpu?.model, { price: instanceHistory.price })}
      pricePeriod={displayPricePeriod(pricePeriod)}
      actionButton={
        <div className="flex flex-col items-start gap-2">
          <div className="text-xs text-theme-neutral-600">{instanceName}</div>
          <div className="text-xs text-theme-neutral-600">Age: {displayDuration(diff)}</div>
          <HyperLink
            to={`/compute?gpuFilter=${displayModelName(gpu?.model)}&quantityFilter=${gpuCount}`}
            variant="neutral-outline"
            className="w-full max-w-32 bg-transparent py-1.5 text-center"
          >
            Find Similar
          </HyperLink>
        </div>
      }
      handleQuantityClicked={handleQuantityClicked}
      instanceImageURL={imageURL}
      loading={imageLoading}
    />
  );
};

export default InstanceHistoryCard;

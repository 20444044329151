import { useEffect } from 'react';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { openModal } from '../../../slices/modals';
import { fetchWalletAddresses, getWalletAddresses } from '../../../slices/settings';
import { fetchBalance } from '../../../slices/usage';
import { AppDispatch } from '../../../store';
import { formatWalletAddress, hyperbolicAddress } from '../../../utils/crypto';
import { AddFunds } from '../../common/AddFunds';
import Badge from '../../common/Badge';
import Button from '../../common/Button';
import Card from '../../common/Card';
import CopyButton from '../../common/CopyButton';
import HorizontalLine from '../../common/HorizontalLine';
import HyperLink from '../../common/HyperLink';
import { ModalName } from '../../modals';
import BillingItemsGPUMarketplace from './BillingItems/BillingItemsGPUMarketplace';
import BillingItemsPurchaseHistory from './BillingItems/BillingItemsPurchaseHistory';
import CheckoutStatus from './CheckoutStatus';

// import BillingItemsDailyActivity from './BillingItems/BillingItemsRecentActivity';
// import BillingItemsAIInference from './BillingItems/BillingItemsAIInference';

const BillingHome = () => {
  const posthog = usePostHog();
  const cryptoPaymentsEnabled = useFeatureFlagEnabled('crypto-payments');
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const searchAmount = searchParams.get('amount');
  const defaultAmount = searchAmount ? parseInt(searchAmount) : undefined;
  const walletAddresses = useSelector(getWalletAddresses);

  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(fetchWalletAddresses());
  }, [dispatch]);

  return (
    <div className="flex w-full flex-col px-6 lg:px-16">
      <h2 className="mb-8 font-semibold text-theme-neutral-700">Billing</h2>
      <div className="mb-8 flex flex-col items-center gap-8">
        <HorizontalLine />
        <Card
          noHover
          className="flex w-full self-start border-none bg-theme-neutral-50"
        >
          <AddFunds
            defaultAmount={defaultAmount}
            showLowBalanceWarnings={true}
          />
        </Card>
        {cryptoPaymentsEnabled && (
          <>
            <HorizontalLine />
            <div className="flex w-full flex-col justify-start gap-4">
              <div className="flex gap-3 text-left text-lg font-semibold text-black">
                Crypto Payment
                <Badge text="BETA" />
              </div>
              <div className="text-sm">
                You can now pay with crypto to purchase platform credits! Simply link your crypto wallet address to your
                account to get started.&nbsp;For more information on crypto payments, visit our&nbsp;
                <HyperLink
                  to={`/support/getting-started`}
                  state={{ initialQuestion: '4' }}
                >
                  FAQ
                </HyperLink>
                .
              </div>
              {walletAddresses.length > 0 && (
                <div className="flex flex-col gap-5 rounded-lg border border-theme-warning-600 bg-theme-warning-50 px-5 py-4 text-sm text-black">
                  <ul className="ml-4 list-outside list-decimal space-y-1">
                    <li>
                      <div className="flex items-center">
                        Make sure to&nbsp;
                        <strong>only send USDC, USDT, or DAI</strong>
                        &nbsp; on&nbsp;
                        <strong>Base network</strong>&nbsp;to&nbsp;Hyperbolic address:
                        <code className="ml-2 flex items-center gap-1 rounded border border-theme-neutral-300 bg-theme-neutral-50 px-1 py-0.5">
                          <span className="text-xs text-black">{hyperbolicAddress}</span>
                          <CopyButton
                            id="hyperbolic-address-block"
                            size={16}
                            copyText={hyperbolicAddress}
                            className="text-theme-neutral-600 hover:text-theme-primary-600"
                          />
                        </code>
                        .
                      </div>
                    </li>
                    <li>
                      Make sure to&nbsp;
                      <strong>only send crypto from your connected address(es) below</strong>.
                    </li>
                  </ul>
                  <div>
                    Any other transfers will&nbsp;
                    <strong>not be received as payment for credits</strong>.
                  </div>
                </div>
              )}
              {walletAddresses.map((address, i) => (
                <div
                  key={`wallet-list-${address}`}
                  className="flex gap-8 text-sm"
                >
                  <div className="font-medium text-theme-neutral-700">Wallet Address #{i + 1}</div>
                  <div>{formatWalletAddress(address)}</div>
                  <div className="flex items-center gap-4">
                    <CopyButton
                      id="api-key"
                      size={16}
                      copyText={address}
                      className="text-theme-neutral-600 hover:text-theme-primary-600"
                    />
                    <FiTrash2
                      className="cursor-pointer text-theme-neutral-600 hover:text-theme-primary-600"
                      onClick={() => {
                        posthog?.capture('Disconnect Wallet Modal Opened');
                        dispatch(
                          openModal({
                            name: ModalName.ConfirmDisconnectWalletAddress,
                            props: { address },
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
              <div>
                <Button
                  variant="outline"
                  className="flex items-center gap-2"
                  onClick={() => {
                    posthog?.capture('Add Wallet Modal Opened');
                    dispatch(openModal({ name: ModalName.AddWalletAddress }));
                  }}
                >
                  <FiPlus size={18} />
                  Wallet Address
                </Button>
              </div>
            </div>
          </>
        )}
        <HorizontalLine />
        <div className="flex w-full flex-col justify-between gap-8 md:flex-row">
          <BillingItemsPurchaseHistory />
          {/* <BillingItemsDailyActivity /> */}
        </div>
        <HorizontalLine />
        <div className="flex w-full flex-col justify-between gap-8 md:flex-row">
          {/* <BillingItemsAIInference /> */}
          <BillingItemsGPUMarketplace />
        </div>
      </div>
      <CheckoutStatus />
    </div>
  );
};

export default BillingHome;

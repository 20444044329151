const LegendIcon = ({
  color,
  width = 13,
  height = 6,
  ...otherProps
}: {
  color: string;
  width?: number;
  height?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 6"
    fill="none"
    {...otherProps}
  >
    <line
      y1="3"
      x2="4"
      y2="3"
      stroke={color}
    />
    <circle
      cx="6.5"
      cy="3"
      r="2"
      stroke={color}
    />
    <line
      x1="9"
      y1="3"
      x2="13"
      y2="3"
      stroke={color}
    />
  </svg>
);

export default LegendIcon;

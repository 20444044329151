import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { getFAQSections } from '../../slices/support';
import HyperLink from '../common/HyperLink';
import ExpandableQuestion from './ExpandableQuestion';

const FAQSection = () => {
  const { sectionId } = useParams();
  const faqSections = useSelector(getFAQSections);
  const section = faqSections.find((sec) => sec.slug === sectionId);
  const { state } = useLocation();
  const questions = section?.questions.filter((q) => !q.hideAlpha);
  return (
    <>
      <div className="-mt-10 flex items-center gap-1 text-sm">
        <HyperLink
          to="/support"
          className="text-theme-neutral-600 underline hover:text-theme-neutral-700"
        >
          Help Center
        </HyperLink>
        &gt;
        <span className="text-theme-primary-600">{section?.title}</span>
      </div>
      <div className="h-[1px] w-full bg-theme-neutral-200" />
      <div className="flex flex-col gap-8 rounded-lg bg-theme-neutral-50 p-9">
        <div>
          <div className="mb-2 text-lg font-semibold text-black">{section?.title} FAQs</div>
          <div className="text-sm">{section?.description}</div>
        </div>
        <div className="flex flex-col gap-5">
          {questions?.map((q) => (
            <ExpandableQuestion
              key={q.question}
              question={q.question}
              answer={q.answer}
              defaultExpanded={state?.initialQuestion === q.index}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQSection;

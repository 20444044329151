import { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

import twClassnames from '../../utils/classnames';

const Badge = ({
  id,
  logo,
  text,
  className,
  tooltipText,
  logoClassName,
}: {
  id?: string;
  logo?: ReactNode;
  text: string;
  className?: string;
  tooltipText?: string;
  logoClassName?: string;
}) => (
  <div
    id={id}
    data-tooltip-id={`tooltip-${id}`}
    className={twClassnames(
      'flex items-center rounded-lg border border-theme-primary-400 bg-theme-primary-100 px-3 py-1 text-sm text-theme-primary-600',
      className,
    )}
  >
    {logo && <span className={twClassnames('mr-2', logoClassName)}>{logo}</span>}
    {text}
    {tooltipText && (
      <Tooltip
        id={`tooltip-${id}`}
        className="z-10 !text-xs"
        place="top"
      >
        {tooltipText}
      </Tooltip>
    )}
  </div>
);

export default Badge;

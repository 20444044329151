import { usePostHog } from 'posthog-js/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { contactSupplier } from '../../services/machines';
import { createMessageDb } from '../../services/messages';
import { getUser } from '../../slices/auth';
import { getMachine } from '../../slices/machines';
import { RootState } from '../../store';
import { gpuTypes } from '../../utils/instances';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import FormInput from '../FormInput';
import FormTextArea from '../FormTextArea';

interface Inputs {
  name: string;
  email: string;
  message: string;
}

const ContactSupplierModal = ({
  supplier,
  machineId,
  onClose,
}: {
  supplier: any;
  machineId: string;
  onClose: () => void;
}) => {
  const posthog = usePostHog();
  const user = useSelector(getUser);
  const machine = useSelector((state: RootState) => getMachine(state, machineId));
  const { control, handleSubmit } = useForm<Inputs>();
  const gpuModel = gpuTypes.find((types) => types.value === machine?.gpuModel)?.label;
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    createMessageDb({ ...data, machineId });
    posthog?.capture('Contact Supplier Sent', {
      supplierId: supplier.owner,
      machineId,
    });
    toast.promise(
      contactSupplier({
        message: data.message,
        supplierId: supplier.owner,
        email: supplier.email,
        gpuDetails: `${machine?.gpuCount}x ${gpuModel}`,
      }),
      {
        loading: 'Sending...',
        success: 'Message was sent to supplier!',
        error: 'Message failed to send.',
      },
    );
    onClose();
  };
  return (
    <div className="px-2 py-2 lg:px-4">
      <div className="mb-5 flex items-center justify-center">
        <ModalClose
          size={20}
          onClose={onClose}
        />
        <div className="text-lg font-semibold text-theme-neutral-700">Contact {supplier.name || 'the Supplier'}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          control={control}
          defaultValue={user?.displayName || ''}
          label="Name"
          name="name"
          rules={{ required: true }}
          disabled={!!user?.displayName}
        />
        <FormInput
          control={control}
          defaultValue={user?.email || ''}
          type="email"
          label="Email"
          name="email"
          rules={{ required: true }}
          disabled={!!user?.email}
        />
        <FormTextArea
          label="Message"
          name="message"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          className="min-h-28"
          labelClassName="font-semibold text-base text-theme-neutral-600"
        />
        <Button
          type="submit"
          className="px-12"
        >
          Send
        </Button>
      </form>
    </div>
  );
};

export default ContactSupplierModal;

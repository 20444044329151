import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIsAuthenticated } from '../../../slices/auth';
import { getAudioValue, setAudioValue } from '../../../slices/chat';
import { generateAudio, getAudioInputParameters, getAudioStatus, resetGeneratedAudio } from '../../../slices/models';
import { AppDispatch } from '../../../store';
import { ResponseStatus } from '../../../utils/types';
import AuthButton from '../../AuthButton';
import MessageBar from '../../common/Chat/MessageBar';
import AudioHistory from './AudioHistory';

const defaultText =
  'Los Angeles! The City of Angels is a treasure trove of exciting experiences, iconic landmarks, and endless entertainment options.';

const ModelAudio = () => {
  const { modelId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const audioInputParams = useSelector(getAudioInputParameters);
  const audioStatus = useSelector(getAudioStatus);
  const isLoading = audioStatus === ResponseStatus.Loading;
  const posthog = usePostHog();
  const audioValue = useSelector(getAudioValue);
  const handleAudioValue = (text: string) => dispatch(setAudioValue(text));

  useEffect(() => {
    return () => {
      dispatch(resetGeneratedAudio());
    };
  }, [dispatch]);

  return (
    <div className="-mx-10 flex flex-1 flex-col lg:overflow-y-hidden">
      <AudioHistory />
      <MessageBar
        defaultText={defaultText}
        className="px-10"
        value={audioValue}
        onChange={handleAudioValue}
        onSubmit={() => {
          posthog?.capture('Generate Audio Clicked', {
            model: modelId,
            script: audioValue,
          });
          dispatch(
            generateAudio({
              text: audioValue || defaultText,
              ...audioInputParams.values,
            }),
          );
          handleAudioValue('');
        }}
        disabled={isLoading}
        overrideButton={!isAuthenticated ? <AuthButton className="mr-2 py-1.5 text-sm" /> : undefined}
      />
    </div>
  );
};

export default ModelAudio;

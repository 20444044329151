import './usage-chart.css';

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getChartColor } from '../../utils/chart';
import twClassnames from '../../utils/classnames';

const UsageChart = ({
  type,
  chartData,
  hiddenModels,
  modelsWithUsage,
  mapModelToName,
}: {
  type: string;
  chartData: any;
  hiddenModels: string[];
  modelsWithUsage: string[];
  mapModelToName: (name: string) => string;
}) => {
  return (
    <div className="h-80">
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <BarChart
          width={500}
          height={300}
          data={chartData}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip
            wrapperClassName="shadow-theme-02 rounded-lg"
            labelFormatter={(label) => {
              const currentYear = new Date().getFullYear();
              return `${label}, ${currentYear}`;
            }}
            formatter={(value, name) => [value, mapModelToName(name as string)]}
          />
          {modelsWithUsage.map((model, i) => (
            <Bar
              key={`bar-${model}`}
              dataKey={model}
              stackId="a"
              fill={getChartColor(type, i)}
              className={twClassnames({
                'opacity-30': hiddenModels.includes(model),
              })}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UsageChart;

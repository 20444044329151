import { useEffect, useRef } from 'react';
import { FiX } from 'react-icons/fi';

import useUser, { UserStatus } from '../../hooks/useUser';
import AuthButton from '../AuthButton';
import Button from '../common/Button';
import SendIcon from '../common/icons/Send';
import TextArea from '../common/TextArea';
import UploadDropdown from './UploadDropdown';

const llmDefaultText = 'Hello, how is your ';
const vlmDefaultText = 'In this image, I see ';

const CompletionInputBar = ({
  value,
  onChange,
  onSubmit,
  disabled = false,
  imageURL,
  onImageChange,
  allowImageUpload,
  defaultImage,
}: {
  value: string;
  onChange: (text: string) => void;
  onSubmit: (defaultText?: string) => void;
  disabled?: boolean;
  imageURL?: string;
  onImageChange?: any;
  allowImageUpload: boolean;
  defaultImage: string;
}) => {
  const { status } = useUser();
  const isAuthenticated = status !== UserStatus.LoggedOut;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const newLines = value.split('\n').length;
  const newHeight = 16 + newLines * 20;
  const defaultText = allowImageUpload ? vlmDefaultText : llmDefaultText;
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (value) {
      onSubmit();
    } else {
      onSubmit(defaultText);
    }
  };
  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const dataURL = reader.result;
      const image = String(dataURL).replace(/data:image\/(jpg|png|jpeg);base64,/, '');
      onImageChange?.(image);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <form
      className="flex items-center px-10"
      onSubmit={handleSubmit}
    >
      <TextArea
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter text here and AI will help complete the sentence"
        onKeyDown={onEnterPress}
        className="resize-none bg-transparent"
        containerClassName="w-full bg-transparent border-0"
        innerContainerClassName="px-0"
        style={{ height: newHeight }}
        disabled={disabled}
        additionalContainer={
          allowImageUpload && (
            <div className="flex items-center gap-2">
              <UploadDropdown
                onDrop={onDrop}
                position="bottom"
              />
              {(imageURL || defaultImage) && (
                <div className="group relative h-12 w-12 transition">
                  <img
                    src={`data:image/png;base64,${imageURL || defaultImage}`}
                    className="h-12 w-12 rounded"
                  />
                  <div
                    className="absolute right-0 top-0 -mr-1.5 -mt-1.5 hidden cursor-pointer rounded-full border border-theme-neutral-400 bg-white p-1 text-black transition group-hover:flex"
                    role="button"
                    onClick={() => onImageChange?.('')}
                  >
                    <FiX size={12} />
                  </div>
                </div>
              )}
            </div>
          )
        }
        endIcon={
          value.length > 0 &&
          (!isAuthenticated ? (
            <AuthButton className="mr-2 py-1.5 text-sm" />
          ) : (
            <Button
              variant="link"
              type="submit"
              className="duration-400 rounded-full bg-theme-neutral-200 p-2 hover:bg-theme-primary-600 hover:text-white disabled:bg-theme-neutral-200 disabled:text-theme-neutral-500"
              disabled={disabled}
            >
              <SendIcon size={20} />
            </Button>
          ))
        }
      />
    </form>
  );
};

export default CompletionInputBar;

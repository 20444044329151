import { usePostHog } from 'posthog-js/react';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { mapNumberFormatToTooltip } from '../../utils/models';
import Badge from '../common/Badge';
import Card from '../common/Card';
import AnnotationDotsIcon from '../common/icons/AnnotationDotsIcon';
import AudioWaveSymmetricIcon from '../common/icons/AudioWaveSymmetricIcon';
import ImageMountainIcon from '../common/icons/ImageMountainIcon';
import StarIcon from '../common/icons/StarIcon';

interface ModelCardProps {
  slug: string;
  name: string;
  type: string;
  description: string;
  pricePerToken: number;
  badgeText?: string;
  numberFormat?: string;
  image?: any;
}

const mapTypeToIcon: { [key: string]: any } = {
  llm: AnnotationDotsIcon,
  image: ImageMountainIcon,
  audio: AudioWaveSymmetricIcon,
};

const ModelCard = ({
  name,
  description,
  type,
  // pricePerToken,
  badgeText,
  numberFormat,
  image,
  slug,
}: ModelCardProps) => {
  const posthog = usePostHog();
  const Icon = mapTypeToIcon[type] || mapTypeToIcon.llm;

  return (
    <Link
      onClick={() => {
        posthog?.capture('Model Card Clicked', {
          slug,
          name,
          type,
        });
      }}
      to={`/models/${slug}`}
      className="w-full"
    >
      <Card className="group flex h-full w-full text-theme-neutral-700 transition hover:text-theme-primary-600">
        <div className="relative mr-4 flex">
          <img
            src={image?.filename}
            className="w-full rounded-lg object-cover"
            style={{ minWidth: 100, width: 100, maxWidth: 100, height: 100 }}
          />
          <div className="invisible absolute flex h-[100px] w-[100px] items-center justify-center rounded-lg bg-black bg-opacity-30 transition group-hover:visible">
            <Icon className="text-white" />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex items-center">
            <div className="flex-1 font-semibold xl:text-sm 2xl:text-base">{name}</div>
            <div className="flex items-center gap-2">
              {numberFormat && (
                <Badge
                  id={`${slug}-number-format`}
                  text={numberFormat.toUpperCase()}
                  className="rounded border-theme-neutral-400 bg-transparent px-1.5 py-0 text-xs text-theme-neutral-600"
                  tooltipText={mapNumberFormatToTooltip[numberFormat.toLowerCase()]}
                />
              )}
              {badgeText && (
                <Badge
                  logo={<StarIcon size={12} />}
                  logoClassName="mr-1"
                  text={badgeText}
                  className="rounded bg-transparent px-1.5 py-0 text-xs"
                />
              )}
            </div>
          </div>
          <div className="flex-1 text-sm font-light text-theme-neutral-700 xl:text-xs 2xl:text-sm [&_strong]:font-semibold">
            <Markdown>{description}</Markdown>
          </div>
          {/* <div className="text-theme-neutral-600 text-sm font-light">
          ${pricePerToken} / 1M tokens
        </div> */}
        </div>
      </Card>
    </Link>
  );
};

export default ModelCard;

import classNames from 'classnames';
import { FiXCircle } from 'react-icons/fi';

const ModalClose = ({ size = 20, className, onClose }: { size?: number; className?: string; onClose: () => void }) => (
  <FiXCircle
    className={classNames(
      'absolute right-3 top-3 cursor-pointer text-theme-neutral-500 transition hover:text-theme-neutral-600',
      className,
    )}
    size={size}
    onClick={onClose}
  />
);

export default ModalClose;

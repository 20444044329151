import { useContext } from 'react';
import { DateBefore, DayPicker } from 'react-day-picker';

import '../Compute/DurationDropdown/DurationOverlay.css';

import { DropdownContext } from '../common/Dropdown';

const DatePickerContent = ({
  date,
  onChangeDate,
}: {
  date: Date | undefined;
  onChangeDate: (date: Date | undefined) => void;
}) => {
  // const [selectedDate, setSelectedDate] = useState<Date | undefined>(date);
  const intervalMatcher: DateBefore = {
    before: new Date(),
  };
  const { setIsOpen } = useContext(DropdownContext);
  return (
    <DayPicker
      mode="single"
      selected={date}
      onSelect={(date) => {
        onChangeDate(date);
        setIsOpen(false);
      }}
      disabled={intervalMatcher}
      modifiersClassNames={{
        selected: '!bg-theme-primary-600 !text-white',
      }}
    />
  );
};

export default DatePickerContent;

import { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Tooltip } from 'react-tooltip';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import twClassnames from '../../utils/classnames';
import Button, { ButtonProps } from './Button';
import CopyIcon from './icons/CopyIcon';

export type CopyButtonProps = {
  id: string;
  copyText: string;
  size?: number;
  tooltipText?: string;
  text?: string;
  textClassName?: string;
  copyClassName?: string;
} & Omit<ButtonProps, 'onClick' | 'data-tooltip-id'>;

const CopyButton = ({
  id,
  copyText,
  size = 20,
  className = '',
  tooltipText = 'Copied!',
  text,
  textClassName = '',
  copyClassName = '',
  ...props
}: CopyButtonProps) => {
  const posthog = usePostHog();
  const copy = useCopyToClipboard();
  const [copied, setCopied] = useState(false);
  return (
    <Button
      variant="link"
      className={twClassnames('flex items-center', className)}
      data-tooltip-id={`${id}-tooltip`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        posthog?.capture(`Copy Button Clicked`, {
          id,
        });
        copy(copyText);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 500);
      }}
      {...props}
    >
      {text && <div className={twClassnames('mr-2 text-xs', textClassName)}>{text}</div>}
      <CopyIcon
        size={size}
        className={copyClassName}
      />
      <Tooltip
        id={`${id}-tooltip`}
        isOpen={copied}
      >
        {tooltipText}
      </Tooltip>
    </Button>
  );
};

export default CopyButton;

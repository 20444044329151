import { format } from 'date-fns';

const BillingItemHeaderPeriodSubtitle = ({
  prefix = 'Current period',
  startDate,
  endDate,
}: {
  prefix?: string | undefined;
  startDate?: Date;
  endDate?: Date;
}) => {
  return (
    <div className="mt-2 flex items-center justify-between text-sm font-light text-theme-neutral-600">
      <div>
        {prefix}{' '}
        {(startDate || endDate) && (
          <>
            {startDate ? format(startDate, 'MMM dd') : ''} {endDate ? ' - ' : ''}
            {endDate ? format(endDate, 'MMM dd') : ''}
          </>
        )}
      </div>
    </div>
  );
};

export default BillingItemHeaderPeriodSubtitle;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGeneratedImagesStatus, getSortedGeneratedImages, resetGeneratedImages } from '../../slices/models';
import { ResponseStatus } from '../../utils/types';
import WeirdCircleIcon from '../common/icons/WeirdCircleIcon';
import GeneratedImageSet from './GeneratedImageSet';

const ModelImagesList = () => {
  const dispatch = useDispatch();
  const images = useSelector(getSortedGeneratedImages);
  const imageStatus = useSelector(getGeneratedImagesStatus);
  const isLoading = imageStatus === ResponseStatus.Loading;

  useEffect(() => {
    return () => {
      dispatch(resetGeneratedImages());
    };
  }, [dispatch]);

  if (images.length === 0 && !isLoading) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <WeirdCircleIcon className="mb-6" />
        <div className="mb-4 text-lg font-medium text-theme-neutral-600">No images generated yet</div>
        <div className="w-[350px] text-center text-sm text-theme-neutral-500">
          Start by describing the images you want to generate in the prompt box on the sidebar 👉
        </div>
      </div>
    );
  }
  return (
    <div className="-mx-10 px-10 lg:overflow-y-auto">
      {isLoading && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-6 w-full rounded bg-theme-neutral-200 lg:w-2/3" />
              <div className="h-6 w-full rounded bg-theme-neutral-200 lg:w-1/2" />
              <div className="h-80 w-80 rounded-lg bg-theme-neutral-200" />
              <div className="h-5 w-1/2 rounded bg-theme-neutral-200 lg:w-1/4" />
            </div>
          </div>
        </div>
      )}
      {images.map((img) => (
        <GeneratedImageSet
          key={`image-${img.id}`}
          imageSet={img}
        />
      ))}
    </div>
  );
};

export default ModelImagesList;

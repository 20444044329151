import { backendHost } from '../utils/constants';
import hyperFetch from '../utils/fetch';

export const fetchUserInfoDb = async () => {
  const response = await hyperFetch(
    `${import.meta.env.VITE_USE_FIREBASE_DEV === 'true' ? 'https://api.dev-hyperbolic.xyz' : backendHost}/users/me`,
  );
  return response;
};

export const updateUserInfoDb = async ({ userId, update }: { userId: string; update: any }) =>
  hyperFetch(`${backendHost}/users/${userId}`, {
    method: 'PUT',
    body: JSON.stringify(update),
  });

export const updatePublicKeyDb = async (publicKey: string) => {
  await hyperFetch(`${backendHost}/v1/marketplace/update_public_key`, {
    method: 'POST',
    body: JSON.stringify({ public_key: publicKey }),
  });
  return publicKey;
};

export const resetApiKeyDb = async ({ userId }: { userId: string }) =>
  hyperFetch(`${backendHost}/users/${userId}/api-key`, {
    method: 'PUT',
  });

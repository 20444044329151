import { useEffect, useState } from 'react';
import sumBy from 'lodash/sumBy';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import gpuImage from '../assets/gpu.png';
import AuthForm, { AuthMode } from '../components/AuthForm';
import Badge from '../components/common/Badge';
import Layout from '../components/common/Layout';
import PillLink from '../components/Dashboard/PillLink';
import LoadingPage from '../components/LoadingPage';
import Usage from '../components/Usage';
import useUser, { UserStatus } from '../hooks/useUser';
import { fetchUsage, getUsage, getUsageLoading, ModelUsage } from '../slices/usage';
import { AppDispatch } from '../store';
import { discordLink, supportEmail, twitterLink } from '../utils/constants';

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [mode, setMode] = useState<AuthMode>('signIn');
  const { user, status } = useUser();
  const userLoading = status === UserStatus.LoggingIn;
  const usage = useSelector(getUsage);
  const usageLoading = useSelector(getUsageLoading);
  const isLoading = userLoading || usageLoading;
  const totalUsage = sumBy(
    usage,
    (usageDatum: ModelUsage) =>
      usageDatum.completion_tokens + usageDatum.prompt_tokens + usageDatum.image_steps + usageDatum.audio_tokens,
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchUsage());
    }
  }, [user, dispatch]);

  return (
    // TODO: change padding to be more similar to how it is on other pages
    <Layout className="px-8 lg:px-8">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="-mx-8 flex flex-1 flex-col items-center justify-center">
          {user ? (
            <div className="flex w-full flex-1 flex-col gap-6 px-6 lg:px-16">
              {totalUsage > 0 ? (
                <Usage />
              ) : (
                <div className="flex items-center gap-32">
                  <div className="flex-1 text-theme-neutral-700">
                    <h1>Hi, {user?.displayName || 'there'}!</h1>
                    <div className="mb-12 flex flex-col gap-4">
                      <div className="flex flex-wrap items-center">
                        Welcome to the&nbsp;
                        <Badge
                          text="beta"
                          className="mx-1"
                        />
                        &nbsp;version of Hyperbolic AI dashboard!
                      </div>
                      <div>
                        We're thrilled to have you on board and eager to have our dashboard help you make something
                        truly special.
                      </div>
                      <div>
                        Your insights are invaluable to us as we evolve our product. Please don't hesitate to share your
                        thoughts and feedback!
                      </div>
                      <div>Join us along the journey!</div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <PillLink
                        href={`mailto:${supportEmail}`}
                        text={supportEmail}
                        icon={
                          <MdOutlineAlternateEmail
                            size={24}
                            className="text-black"
                          />
                        }
                      />
                      <PillLink
                        href={twitterLink}
                        text="hyperbolic_labs"
                        icon={
                          <FaXTwitter
                            size={24}
                            className="text-black"
                          />
                        }
                      />
                      <PillLink
                        href={discordLink}
                        text="hyperbolic-labs"
                        icon={
                          <FaDiscord
                            size={20}
                            className="text-white"
                          />
                        }
                        iconContainerClassName="p-1.5 rounded-full bg-black"
                      />
                    </div>
                  </div>
                  <div
                    className="hidden h-[560px] w-[560px] rounded-xl bg-theme-neutral-100 bg-contain bg-no-repeat lg:flex"
                    style={{ backgroundImage: `url(${gpuImage})` }}
                  />
                </div>
              )}
            </div>
          ) : (
            <AuthForm
              className="mx-4 max-w-[512px] rounded-xl border border-theme-primary-200 bg-white p-6 lg:mx-10 lg:p-8"
              mode={mode}
              onChangeMode={setMode}
            />
          )}
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;

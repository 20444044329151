import { useState } from 'react';
import { isAddress } from 'ethers';
import { usePostHog } from 'posthog-js/react';
import { useDispatch } from 'react-redux';

import { addWalletAddress } from '../../slices/settings';
import { AppDispatch } from '../../store';
import Button from '../common/Button';
import HyperLink from '../common/HyperLink';
import Input from '../common/Input';
import ModalClose from '../common/ModalClose';

const walletSections = [
  {
    title: 'Link your wallet address',
    description:
      'Ensure your wallet address is linked to your account, as only registered addresses are recognized for payments.',
  },
  {
    title: 'Send Stablecoins',
    description: 'Once linked, send stablecoins (USDC, USDT, and DAI) on Base to the Hyperbolic address.',
  },
];

const AddWalletAddressModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const [input, setInput] = useState('');
  const [inputError, setInputError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState('');

  const handleWalletAddressChange = (text: string) => {
    if (!isAddress(text)) {
      setInputError('Wallet address is not a valid ethereum address');
      setInput(text);
    } else {
      setInputError('');
      setNetworkError('');
      setInput(text);
    }
  };

  const handleSubmit = async () => {
    if (isAddress(input)) {
      try {
        posthog?.capture('Add Wallet Address Attempted');
        setIsLoading(true);
        setNetworkError('');
        await dispatch(addWalletAddress(input)).unwrap();
        setIsLoading(false);
        onClose();
        posthog?.capture('Add Wallet Address Succeeded');
      } catch (e: any) {
        posthog?.capture('Add Wallet Address Failed');
        setIsLoading(false);
        setNetworkError(e.message);
      }
    }
  };

  const error = inputError || networkError;

  return (
    <div className="flex flex-col gap-8 px-2 py-2 lg:px-4">
      <ModalClose
        className="right-6 top-6"
        onClose={onClose}
      />
      <div className="text-center text-lg font-semibold text-theme-neutral-700">Add Wallet Address</div>
      <ul className="ml-4 list-outside list-decimal space-y-6 text-black">
        {walletSections.map((sec) => (
          <li>
            <div className="mb-2 font-semibold text-black">{sec.title}</div>
            <div className="text-sm text-theme-neutral-600">
              {sec.description}
              {sec.title.includes('Stablecoins') && (
                <span className="ml-1">
                  For more information on crypto payments, visit our&nbsp;
                  <HyperLink
                    to={`/support/getting-started`}
                    state={{ initialQuestion: '4' }}
                    onClick={onClose}
                  >
                    FAQ
                  </HyperLink>
                  .
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-col gap-3">
        <div className="font-semibold text-black">Your Wallet Address</div>
        <Input
          type="text"
          value={input}
          placeholder="Paste your wallet address here"
          onChangeText={handleWalletAddressChange}
          error={!!error}
        />
        {!!error && <div className="text-theme-danger-600">{error}</div>}
      </div>
      <div className="flex gap-4">
        <Button
          variant="outline"
          onClick={onClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          className="flex-1"
          onClick={handleSubmit}
          disabled={!!inputError}
          isLoading={isLoading}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddWalletAddressModal;

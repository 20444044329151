import { hexToRgb, rgbWithOpacity } from './colors';

export const chartColors = [
  '#6A5FEB',
  '#FFD52D',
  '#68E8E4',
  '#6A9FFF',
  '#F7C4C3',
  '#FFA14D',
  '#4DF8C0',
  '#FF8BD9',
  '#8081F2',
  '#62C8C5',
  '#407EEF',
  '#FFE84D',
  '#A469F8',
  '#68E89E',
  '#FB703A',
  '#90CDFF',
  '#B2B3EB',
  '#FFAEE5',
  '#0B62FF',
  '#A4F84D',
  '#B4B4B4',
];

export const colorIncrementer: { [key: string]: number } = {
  llm: 0,
  sd: 9,
  audio: 14,
};

export const hexWithOpacity = (color: string, alpha: number) => rgbWithOpacity(hexToRgb(color), alpha);

export const getChartColor = (type: string, index: number) => {
  const color = chartColors[(index + colorIncrementer[type]) % chartColors.length];
  return color;
};

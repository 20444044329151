import { useCallback, useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import noop from 'lodash/noop';
import { usePostHog } from 'posthog-js/react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { FaGithub } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAuthError, getAuthStatus, signIn, SignInProvider } from '../slices/auth';
import { openModal } from '../slices/modals';
import { AppDispatch } from '../store';
import { formatAuthError } from '../utils/auth';
import twClassnames from '../utils/classnames';
import { mobileStyles } from '../utils/modals';
import { ResponseStatus } from '../utils/types';
import Button from './common/Button';
import HyperLink from './common/HyperLink';
import ArrowNarrowUpRight from './common/icons/ArrowNarrowUpRight';
import AuthDividerLine from './common/icons/AuthDividerLine';
import FormInput from './FormInput';
import { ModalName } from './modals';

type UserSignInInputs = {
  email: string;
  password: string;
};

const url = 'https://auth.hyperbolic.xyz/challenge';

export type AuthMode = 'signIn' | 'register' | 'password';

const AuthForm = ({
  mode = 'register',
  className = '',
  onChangeMode,
  from = '',
  onComplete = noop,
}: {
  mode: AuthMode;
  className?: string;
  onChangeMode: (mode: AuthMode) => void;
  from?: string;
  onComplete?: () => void;
}) => {
  const posthog = usePostHog();
  const authError = useSelector(getAuthError);
  const authStatus = useSelector(getAuthStatus);
  const [success, setSuccess] = useState(false);
  const isLoading = authStatus === ResponseStatus.Loading;
  const dispatch = useDispatch<AppDispatch>();
  const { control, handleSubmit } = useForm<UserSignInInputs>();
  const location = useLocation();
  const navigate = useNavigate();
  const signInCallback = useCallback(() => {
    const fromPath = location.state?.from || from;
    if (fromPath) {
      navigate(location.state?.from || from);
    }
    onComplete();
  }, [location.state?.from, from, navigate, onComplete]);
  // TODO: consolidate three functions into a single function
  const handleGoogleLogin = async () => {
    if (!success) return;
    const providerName = SignInProvider.Google;
    posthog?.capture('Login Attempted', {
      type: mode,
      provider: providerName,
    });
    try {
      await dispatch(signIn({ providerName, mode })).unwrap();
      posthog?.capture('Login Failed', {
        type: mode,
        provider: providerName,
      });
      signInCallback();
    } catch (e: any) {
      console.error('log in failure', e);
      posthog?.capture('Login Failed', {
        type: mode,
        provider: providerName,
        message: e.message,
      });
    }
  };

  const handleGithubLogin = async () => {
    if (!success) return;
    const providerName = SignInProvider.GitHub;
    posthog?.capture('Login Attempted', {
      type: mode,
      provider: providerName,
    });
    try {
      await dispatch(signIn({ providerName, mode })).unwrap();
      posthog?.capture('Login Completed', {
        type: mode,
        provider: providerName,
      });
      signInCallback();
    } catch (e: any) {
      console.error('log in failure', e);
      posthog?.capture('Login Failed', {
        type: mode,
        provider: providerName,
        message: e.message,
      });
    }
  };

  const onSubmit = async (data: UserSignInInputs) => {
    if (!success) return;
    const providerName = SignInProvider.Password;
    posthog?.capture('Login Attempted', {
      type: mode,
      provider: providerName,
    });
    try {
      const response = await dispatch(signIn({ providerName, mode, ...data })).unwrap();
      posthog?.capture('Login Failed', {
        type: mode,
        provider: providerName,
      });
      if (response.providerName === SignInProvider.Password && !response.user.emailVerified) {
        navigate('/verify-email');
      }
      signInCallback();
    } catch (e: any) {
      console.error('log in failure', e);
      posthog?.capture('Login Failure', {
        type: mode,
        provider: providerName,
        message: e.message,
      });
    }
  };

  const turnstileWidget = (
    <div className="flex justify-center">
      <Turnstile
        siteKey="0x4AAAAAAAzPOAlnv-DplCfs"
        options={{
          theme: 'light',
        }}
        onSuccess={async (token) => {
          try {
            const response = await fetch(url, {
              method: 'POST',
              body: JSON.stringify({ token }),
            });
            const text = await response.json();
            setSuccess(text.success);
          } catch (e: any) {
            posthog?.capture('Turnstile Request Failure', {
              message: e.message,
            });
          }
        }}
        onError={(error) => {
          posthog?.capture('Turnstile Failure', { message: error });
        }}
      />
    </div>
  );

  return (
    <div className={twClassnames('mb-6', className)}>
      <div className="mb-8 font-['AcidGrotesk-Light'] text-2xl text-black">Hyperbolic</div>
      <AuthDividerLine
        width="100%"
        className="mb-12"
      />
      {authError && <div className="mb-6 text-red-500">{formatAuthError(authError)}</div>}
      {mode === 'register' ? (
        <>
          {/* <div className="text-lg mb-20">
            Get <span className="text-theme-primary-600">$10</span> free credits
            when you sign up now!
          </div> */}
          <form
            className="mb-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              type="email"
              name="email"
              label="Email"
              labelClassName="text-theme-neutral-700 font-medium"
              placeholder="E.g. johndoe@gmail.com"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              hideAsterick
              defaultValue=""
              control={control}
              rules={{ required: true }}
            />
            <FormInput
              type="password"
              name="password"
              label="Password"
              labelClassName="text-theme-neutral-700 font-medium"
              placeholder="Enter your password"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              hideAsterick
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must be 8 characters or more.',
                },
              }}
            />
            {turnstileWidget}
            <Button
              type="submit"
              className="mt-4 w-full"
              isLoading={isLoading}
              disabled={!success}
            >
              Sign Up
            </Button>
          </form>
          <div className="flex items-center justify-center">
            Already a user?
            <Button
              variant="link"
              onClick={() => onChangeMode('signIn')}
              className="ml-1 flex items-center text-base"
              disabled={isLoading}
            >
              Log In
              <ArrowNarrowUpRight className="ml-1" />
            </Button>
          </div>
        </>
      ) : (
        <>
          <Button
            variant="outline"
            onClick={handleGoogleLogin}
            className="mb-5 flex w-full items-center justify-center border-theme-neutral-400 text-theme-neutral-700 hover:border-theme-primary-600"
            disabled={isLoading || !success}
          >
            <FcGoogle
              size={22}
              className="mr-4"
            />
            <span>Continue with Google</span>
          </Button>
          <Button
            variant="outline"
            onClick={handleGithubLogin}
            className="mb-5 flex w-full items-center justify-center border-theme-neutral-400 text-theme-neutral-700 hover:border-theme-primary-600"
            disabled={isLoading || !success}
          >
            <FaGithub
              color="#171515"
              size={22}
              className="mr-4"
            />
            <span>Continue with GitHub</span>
          </Button>
          {turnstileWidget}
          <div className="my-8 flex items-center gap-8">
            <div className="h-[1px] flex-1 bg-theme-neutral-400" />
            <div className="text-theme-neutral-400">or Log in with Email</div>
            <div className="h-[1px] flex-1 bg-theme-neutral-400" />
          </div>
          <form
            className="mb-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              type="email"
              name="email"
              label="Email"
              labelClassName="text-theme-neutral-700 font-medium"
              defaultValue=""
              placeholder="E.g. johndoe@gmail.com"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              control={control}
              rules={{ required: true }}
              hideAsterick
            />
            <FormInput
              type="password"
              name="password"
              label="Password"
              labelClassName="text-theme-neutral-700 font-medium"
              labelRight={
                <Button
                  type="button"
                  variant="link"
                  className="font-normal"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      openModal({
                        name: ModalName.ForgotPasswordModal,
                        ...(isMobile && { styles: mobileStyles }),
                      }),
                    );
                  }}
                >
                  Forgot password?
                </Button>
              }
              defaultValue=""
              placeholder="Enter your password"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-6"
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must be 8 characters or more.',
                },
              }}
              hideAsterick
            />
            <Button
              type="submit"
              className="w-full"
              isLoading={isLoading}
              disabled={!success}
            >
              Log In
            </Button>
          </form>
          <div className="flex flex-wrap items-center justify-center gap-1 text-theme-neutral-700">
            <div>Not registered yet?</div>
            <Button
              variant="link"
              onClick={() => onChangeMode('register')}
              className="flex items-center text-base"
            >
              Create an account
              <ArrowNarrowUpRight className="ml-1" />
            </Button>
          </div>
        </>
      )}
      <div className="mt-4 flex flex-wrap items-center justify-center text-sm">
        By signing up, you agree to Hyperbolic's&nbsp;
        <HyperLink
          href="https://hyperbolic.xyz/terms"
          className="text-theme-neutral-600 underline hover:text-theme-neutral-700"
          rel="noreferrer noopener"
          target="_blank"
        >
          privacy policy
        </HyperLink>
        &nbsp;and&nbsp;
        <HyperLink
          href="https://hyperbolic.xyz/privacy"
          className="text-theme-neutral-600 underline hover:text-theme-neutral-700"
          rel="noreferrer noopener"
          target="_blank"
        >
          terms of use
        </HyperLink>
      </div>
    </div>
  );
};

export default AuthForm;
